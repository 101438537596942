import React from "react";
import { Button } from "react-bootstrap";
import * as XLSX from "xlsx";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";

const DonationExcelReport = ({
  totalCount,
  currentMonth,
  commonAPIRequest,
}) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const header = [
    "S.No",
    "Name",
    "Amount",
    "Donated On",
    "Mode",
    "Purpose",
    "Received By",
    "Transaction Id",
    "Remark",
  ];

  const getAllDonationsApi = () => {
    let serviceParams = {
      api: `${
        API.DONATION_SUMMARY
      }?pgNum=1&pgSize=${totalCount}&month=${currentMonth}&year=${new Date().getFullYear()}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    // setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        // setLoading(false);
        try {
          const data = result.payload?.donationDetailList.map((donation, i) => {
            return {
              sno: i + 1,
              name: donation?.donatedBy,
              amount: donation?.amount,
              donatedOn: new Date(donation?.donationDate).toLocaleDateString(
                "en-IN"
              ),
              mode: donation?.paymentMode,
              purpose: donation?.category,
              receivedBy: donation?.createdBy,
              txnId: donation?.sysTransactionId,
              remark: donation?.description,
            };
          });

          const wsData = [
            header,
            ...data.map((row) => [
              row.sno,
              row.name,
              row.amount,
              row.donatedOn,
              row.mode,
              row.purpose,
              row.receivedBy,
              row.txnId,
              row.remark,
            ]),
          ];

          const ws = XLSX.utils.aoa_to_sheet(wsData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Contributions");
          XLSX.writeFile(
            wb,
            `Contribution for ${
              months[currentMonth - 1]
            } ${new Date().getFullYear()}.xlsx`
          );
        } catch (error) {
          console.log("error", error);
        }
      } else {
        // setLoading(false);
      }
    });
  };

  function handleDownloadExcel() {
    getAllDonationsApi();
  }

  return (
    <div>
      <Button className="ms-3" onClick={handleDownloadExcel}>
        <i className="ri-download-2-fill"></i>
      </Button>
    </div>
  );
};

export default withAPIRequest(DonationExcelReport);
