import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import TextInput from "../../components/form/TextInput";
import TextArea from "../../components/form/TextArea";
import DateInput from "../../components/form/DateInput";
import SelectInput from "../../components/form/SelectInput";

const AddFamilyFormStep2 = ({ formik }) => {
  const [baptismNameDifferent, setBaptismNameDifferent] = useState(false);

  return (
    <Row className="g-3">
      <Col sm={4}>
        <TextInput
          label={"First Name"}
          placeholder={"e.g. Nikhil"}
          setFieldValue={formik.setFieldValue}
          keyword={"firstName"}
          value={formik.values.firstName}
          error={formik.errors.firstName}
          touched={formik.touched.firstName}
        />
      </Col>
      <Col sm={4}>
        <TextInput
          label={"Middle Name"}
          placeholder={"e.g. Nikhil"}
          setFieldValue={formik.setFieldValue}
          keyword={"middleName"}
          value={formik.values.middleName}
        />
      </Col>
      <Col sm={4}>
        <TextInput
          label={"Last Name"}
          placeholder={"e.g. Nikhil"}
          setFieldValue={formik.setFieldValue}
          keyword={"lastName"}
          value={formik.values.lastName}
          error={formik.errors.lastName}
          touched={formik.touched.lastName}
        />
      </Col>

      <Col sm={12}>
        <Form.Check
          className="fw-bold"
          type="switch"
          label="Baptism name different from the name entered above?"
          onChange={(e) => {
            setBaptismNameDifferent(e.target.checked);
          }}
        />
      </Col>

      {baptismNameDifferent && (
        <Col sm={12}>
          <TextInput
            label={"Baptism Name"}
            placeholder={"e.g. John"}
            setFieldValue={formik.setFieldValue}
            keyword={"baptismName"}
            value={formik.values.baptismName}
            error={formik.errors.baptismName}
            touched={formik.touched.baptismName}
          />
        </Col>
      )}

      <Col sm={6}>
        <TextInput
          label={"Father's Name"}
          placeholder={"e.g. Nikhil"}
          setFieldValue={formik.setFieldValue}
          keyword={"fatherName"}
          value={formik.values.fatherName}
          error={formik.errors.fatherName}
          touched={formik.touched.fatherName}
        />
      </Col>
      <Col sm={6}>
        <TextInput
          label={"Mother's Name"}
          placeholder={"e.g. Nikhil"}
          setFieldValue={formik.setFieldValue}
          keyword={"motherName"}
          value={formik.values.motherName}
          error={formik.errors.motherName}
          touched={formik.touched.motherName}
        />
      </Col>

      <Col sm={6}>
        <DateInput
          label={"Date Of Birth"}
          setFieldValue={formik.setFieldValue}
          keyword={"dob"}
          value={formik.values.dob}
          error={formik.errors.dob}
          touched={formik.touched.dob}
          placeholder={"D.O.B"}
        />
      </Col>
      <Col sm={6}>
        <SelectInput
          selectOptions={[
            { label: "Male", value: "Male" },
            { label: "Female", value: "Female" },
          ]}
          label={"Gender"}
          placeholder={"Dropdown"}
          setFieldValue={formik.setFieldValue}
          keyword={"gender"}
          value={formik.values.gender}
          error={formik.errors.gender}
          touched={formik.touched.gender}
        />
      </Col>

      <Col sm={12}>
        <SelectInput
          selectOptions={[
            { label: "A+", value: "A+" },
            { label: "A-", value: "A-" },
            { label: "B+", value: "B+" },
            { label: "B-", value: "B-" },
            { label: "AB+", value: "AB+" },
            { label: "AB-", value: "AB-" },
            { label: "O+", value: "O+" },
            { label: "O-", value: "O-" },
            { label: "N/A", value: "N/A" },
          ]}
          label={"Blood Group"}
          setFieldValue={formik.setFieldValue}
          keyword={"bloodGroup"}
          value={formik.values.bloodGroup}
          error={formik.errors.bloodGroup}
          touched={formik.touched.bloodGroup}
        />
      </Col>

      <Col sm={12}>
        <Form.Check
          type="checkbox"
          label="Willing to Donate Blood"
          onChange={(e) => {
            formik.setFieldValue("willingToDonate", e.target.checked);
          }}
          checked={formik.values.willingToDonate}
        />
      </Col>

      <Col sm={6}>
        <TextInput
          label={"Occupation"}
          placeholder={"e.g. Service"}
          setFieldValue={formik.setFieldValue}
          keyword={"occupation"}
          value={formik.values.occupation}
          error={formik.errors.occupation}
          touched={formik.touched.occupation}
        />
      </Col>

      <Col sm={6}>
        <SelectInput
          selectOptions={[
            { label: "SINGLE", value: "SINGLE" },
            { label: "MARRIED", value: "MARRIED" },
            { label: "DIVORCED", value: "DIVORCED" },
            { label: "WIDOWED", value: "WIDOWED" },
          ]}
          label={"Marital Status"}
          placeholder={"Dropdown"}
          setFieldValue={formik.setFieldValue}
          keyword={"marriageStatus"}
          value={formik.values.marriageStatus}
          error={formik.errors.marriageStatus}
          touched={formik.touched.marriageStatus}
        />
      </Col>

      <Col sm={12}>
        <TextArea
          label={"Remark"}
          placeholder={"Add any remark..."}
          id={"remark"}
          setFieldValue={formik.setFieldValue}
          keyword={"remark"}
          value={formik.values.remark}
          error={formik.errors.remark}
          touched={formik.touched.remark}
        />
      </Col>
    </Row>
  );
};

export default AddFamilyFormStep2;
