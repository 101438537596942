import { useFormik } from "formik";
import React, { useState } from "react";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Lottie from "lottie-react";
import TextInput from "../../components/form/TextInput";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import { withAPIRequest } from "../../HOC/withAPIRequest";

const AddNonPriestUserModal = ({
  show,
  setShow,
  commonAPIRequest,
  churchId,
  callGetAllParishUsers,
}) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phoneNum: "",
      priestDetailId: "",
      password: "",
      userRole: "CHR_ADMIN",
      churchId: "",
      loginId: "",
    },

    onSubmit: (values) => {
      callAddParishUserInResourceApi();
    },
  });

  const callAddParishUserInResourceApi = () => {
    let serviceParams = {
      api: API.CREATE_USER_RESOURCE,
      method: AXIOS_METHOD_TYPES.POST,
      data: {
        churchId: churchId,
        firstName: formik.values?.firstName,
        lastName: formik.values?.lastName,
        loginId: formik.values?.loginId,
        password: formik.values?.password,
        userRole: formik.values?.userRole,
        phoneNum: formik.values?.phoneNum,
        priestDetailId: formik.values?.priestDetailId,
      },
    };
    setShow(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setShow(false);
          callGetAllParishUsers();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">Add New User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <div>
            <Row>
              <Col sm={6}>
                <TextInput
                  label={"First Name"}
                  placeholder={"e.g. joseph"}
                  id={"firstName"}
                  setFieldValue={formik.setFieldValue}
                  keyword="firstName"
                  value={formik.values.firstName}
                  error={formik.errors.firstName}
                  touched={formik.touched.firstName}
                />
              </Col>
              <Col sm={6}>
                <TextInput
                  label={"Last Name"}
                  placeholder={"e.g. Toppo"}
                  id={"lastName"}
                  setFieldValue={formik.setFieldValue}
                  keyword="lastName"
                  value={formik.values.lastName}
                  error={formik.errors.lastName}
                  touched={formik.touched.lastName}
                />
              </Col>

              <Col sm={6}>
                <TextInput
                  label={"User Email"}
                  placeholder={"e.g. joseph@gmail.com"}
                  id={"loginId"}
                  setFieldValue={formik.setFieldValue}
                  keyword="loginId"
                  value={formik.values.loginId}
                  error={formik.errors.loginId}
                  touched={formik.touched.loginId}
                />
              </Col>
              <Col sm={6}>
                <TextInput
                  label={"Phone Number"}
                  placeholder={"e.g. 9898989898"}
                  id={"phoneNum"}
                  setFieldValue={formik.setFieldValue}
                  keyword="phoneNum"
                  value={formik.values.phoneNum}
                  error={formik.errors.phoneNum}
                  touched={formik.touched.phoneNum}
                />
              </Col>

              <Col sm="12">
                <TextInput
                  label={"Password"}
                  placeholder={"Enter a Password"}
                  id={"password"}
                  setFieldValue={formik.setFieldValue}
                  keyword="password"
                  value={formik.values.password}
                  error={formik.errors.password}
                  touched={formik.touched.password}
                />
              </Col>

              <Col sm={12}>
                <div className="my-3 alert alert-danger text-left" role="alert">
                  Your password must contain
                  <br />- 2 Special character{" "}
                  <span className="text-muted">e.g. (@, #, $, etc)</span>,{" "}
                  <br />- 2 Uppercase character{" "}
                  <span className="text-muted">e.g. (S, A, P, etc)</span>,{" "}
                  <br />- 2 Digit{" "}
                  <span className="text-muted">e.g. (1, 6, 4, etc)</span>,
                  <br />- 2 Lowercase character{" "}
                  <span className="text-muted">e.g. (d, e, u, etc)</span>,
                  <br />- No Repeating character more than 2{" "}
                  <span className="text-muted">e.g. (aaa, bbb, ccc, etc)</span>
                </div>
              </Col>

              <Col sm="12">
                <TextInput
                  label={"Role"}
                  placeholder={"e.g. ADMIN"}
                  id={"userRole"}
                  setFieldValue={formik.setFieldValue}
                  keyword="userRole"
                  value={formik.values.userRole}
                  error={formik.errors.userRole}
                  disabled
                  touched={formik.touched.userRole}
                />
              </Col>
            </Row>
          </div>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button onClick={formik.handleSubmit} variant="primary">
            Save
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(AddNonPriestUserModal);
