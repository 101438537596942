import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Modal, Row, Form } from "react-bootstrap";
import DateInput from "../../components/form/DateInput";
import TextInput from "../../components/form/TextInput";
import { useFormik } from "formik";
import { ExtBaptismValidationSchema } from "../../validations/baptism";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import Swal from "sweetalert2";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import { useSelector } from "react-redux";
import { AuthContext } from "react-oauth2-code-pkce";
import NotAuthenticated from "../../pages/NotAuthenticated";

function capitalizeText(text) {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

const AddExternalBaptismDetailsModal = ({
  show,
  setShow,
  commonAPIRequest,
  basicDetails,
  memberId,
  getFamilyMemberDetails,
}) => {
  const [loading, setLoading] = useState(false);
  const { churchDetails } = useSelector((state) => state.church);
  const { tokenData } = useContext(AuthContext);
  const addBaptismPermission =
    tokenData?.permissions?.includes("WRITE_BAPTISM");

  const formik = useFormik({
    initialValues: {
      dob: "",
      baptismDate: "",
      baptismChurchName: "",
      baptismChurchCity: "",
      dobForValidation: "",
      baptismDateNotSure: false,
    },
    validationSchema: ExtBaptismValidationSchema,
    onSubmit: (values) => {
      Swal.fire({
        title: "<strong>Are you sure?</strong>",
        icon: "warning",
        html: `
        <dl>
          <dt>Date Of Birth</dt>
          <dd>${new Date(values?.dob).toLocaleDateString("en-GB", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })}</dd>
          <dt>Date Of Baptism </dt>
          <dd>${new Date(values?.baptismDate).toLocaleDateString("en-GB", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })}</dd>
          <dt>Baptism Church Name</dt>
          <dd>${
            values?.baptismChurchName ? values?.baptismChurchName : "Not Sure"
          }</dd>
          <dt>Baptism Church City</dt>
          <dd>${
            values?.baptismChurchCity ? values?.baptismChurchCity : "Not Sure"
          }</dd>
        </dl>`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: `Yes, Sure!`,
        confirmButtonAriaLabel: "Yes, Sure!",
        cancelButtonText: `No`,
        cancelButtonAriaLabel: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          callAddBaptismDetailsApi(values);
        }
      });
    },
  });

  // function to call API for Add New Baptism start

  const callAddBaptismDetailsApi = (value) => {
    let serviceParams = {
      api: API.EXTERNAL_BAPTISM_API,
      method: AXIOS_METHOD_TYPES.POST,
      data: {
        baptismDetail: {
          firstName: capitalizeText(basicDetails?.firstName.trim()),
          middleName: capitalizeText(basicDetails?.middleName.trim()),
          lastName: capitalizeText(basicDetails?.lastName.trim()),
          baptismDate: formik.values.baptismDate,
          extBaptism: true,
          extBaptismChurch: capitalizeText(
            formik.values.baptismChurchName.trim()
          ),
          extBaptismCity: capitalizeText(
            formik.values.baptismChurchCity.trim()
          ),
          baptismDateAccurate: !formik.values.baptismDateNotSure,
        },
        memberId: memberId,
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire("Success", `Baptism Created Successfully.`, "success");
          getFamilyMemberDetails();
          setShow(false);
          formik.resetForm();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Add New Baptism end

  useEffect(() => {
    if (show) {
      formik.setFieldValue("dob", new Date(basicDetails?.dateOfBirth));
      formik.setFieldValue(
        "dobForValidation",
        new Date(
          new Date(basicDetails?.dateOfBirth).setDate(
            new Date(basicDetails?.dateOfBirth).getDate() - 1
          )
        )
      );
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Add Baptism Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : !addBaptismPermission ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <NotAuthenticated />
          </div>
        ) : (
          <Row>
            <p className="text-muted">
              As{" "}
              <span className="fw-bold text-primary">
                {" "}
                {basicDetails?.firstName} {basicDetails?.middleName}{" "}
                {basicDetails?.lastName}
              </span>{" "}
              baptism was done{" "}
              <span className="fw-bold text-primary text-decoration-underline">
                outside of {churchDetails?.churchName}.
              </span>{" "}
              <br /> Please Enter these basic baptism details for the records.
            </p>
            <Col sm={12}>
              <DateInput
                label={"Date Of Birth"}
                setFieldValue={formik.setFieldValue}
                keyword={"dob"}
                value={formik.values.dob}
                error={formik.errors.dob}
                touched={formik.touched.dob}
                disabled={basicDetails?.dateOfBirth}
              />
            </Col>
            <Col sm={12}>
              <DateInput
                label={"Baptism Date"}
                setFieldValue={formik.setFieldValue}
                keyword={"baptismDate"}
                value={formik.values.baptismDate}
                error={formik.errors.baptismDate}
                touched={formik.touched.baptismDate}
              />
            </Col>

            <Col className="mt-2" sm={12}>
              <Form.Check
                type="checkbox"
                label="Not sure if baptism date is correct"
                onChange={(e) => {
                  formik.setFieldValue("baptismDateNotSure", e.target.checked);
                }}
                checked={formik.values.baptismDateNotSure}
              />
            </Col>

            <Col sm={6}>
              <TextInput
                label={"Baptism Church Name"}
                placeholder={"e.g. St. Mary's Church"}
                setFieldValue={formik.setFieldValue}
                keyword={"baptismChurchName"}
                value={formik.values.baptismChurchName}
                error={formik.errors.baptismChurchName}
                touched={formik.touched.baptismChurchName}
              />
            </Col>
            <Col sm={6}>
              <TextInput
                label={"Baptism Church City"}
                placeholder={"e.g. Noida"}
                setFieldValue={formik.setFieldValue}
                keyword={"baptismChurchCity"}
                value={formik.values.baptismChurchCity}
                error={formik.errors.baptismChurchCity}
                touched={formik.touched.baptismChurchCity}
              />
            </Col>
          </Row>
        )}
      </Modal.Body>
      {addBaptismPermission && !loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={formik.handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(AddExternalBaptismDetailsModal);
