import React, { useContext, useEffect, useState } from "react";
import HeaderMobile from "../layouts/HeaderMobile";
import { Card, Col, Nav, Row, Button, Badge } from "react-bootstrap";
import maleImg from "../assets/img/man_delhi_diocese_theme.png";
import femaleImg from "../assets/img/women_delhi_diocese_theme.png";
import baptism from "../assets/img/baptism.png";
import holyconfirmation from "../assets/img/holy-chalice.png";
import holycomunion from "../assets/img/holy-confirmation.png";
import wedding from "../assets/img/wedding.png";
import priest from "../assets/img/priest.png";
import divorce from "../assets/img/divorce.png";

import BasicInformation from "./member-details/BasicInformation";
import BaptismDetails from "./member-details/BaptismDetails";
import HolyCommunionDetails from "./member-details/HolyCommunionDetails";
import ConfirmationDetails from "./member-details/ConfirmationDetails";
import HolyOrderDetails from "./member-details/HolyOrderDetails";
import MarriageDetails from "./member-details/MarriageDetails";
import DemiseDetails from "./member-details/DemiseDetails";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { API, AXIOS_METHOD_TYPES } from "../constants/api";
import { withAPIRequest } from "../HOC/withAPIRequest";
import { useDispatch } from "react-redux";
import { LoadingAction } from "../store/slices/LoadingSlice";
import AnnulmentDetails from "./member-details/AnnulmentDetails";
import Avatar from "../components/Avatar";
import img10 from "../assets/img/img10.jpg";
import { AuthContext } from "react-oauth2-code-pkce";
import NotAuthenticated from "../pages/NotAuthenticated";

const MemberDetails = ({ commonAPIRequest }) => {
  const [currentSection, setCurrentSection] = useState(1);
  const location = useLocation();
  const [state, setState] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [basicDetails, setBasicDetails] = useState();
  const [contactDetails, setContactDetails] = useState();
  const [familyDetails, setFamilyDetails] = useState();

  // cert details
  const [baptismDetails, setBaptismDetails] = useState();
  const [holyCommunionDetails, setHolyCommunionDetails] = useState();
  const [holyConfirmationDetails, setHolyConfirmationDetails] = useState();
  const [holyOrderDetails, setHolyOrderDetails] = useState();
  const [deathDetails, setDeathDetail] = useState();
  const [marriageDetails, setMarriageDetails] = useState();
  const [annulmentDetails, setAnnulmentDetails] = useState();

  const { tokenData } = useContext(AuthContext);
  const readMemberPermission = tokenData?.permissions?.includes("READ_MEMBER");

  const getFamilyMemberDetails = () => {
    let serviceParams = {
      api: `${API.GET_MEMBER_DETAIL}?memberId=${state?.memberId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    dispatch(LoadingAction.startTranslucentLoader());
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        dispatch(LoadingAction.endTranslucentLoader());
        setLoading(false);
        try {
          setBasicDetails(result?.payload?.basicMemberDetail);
          setContactDetails(result?.payload?.contactDetail);
          setFamilyDetails(result?.payload?.churchFamilyDetail);
          setBaptismDetails(result?.payload?.basicBaptismDetail);
          setHolyCommunionDetails(result?.payload?.basicHolyCommunionDetail);
          setHolyConfirmationDetails(result?.payload?.basicConfirmationDetail);
          setHolyOrderDetails(result?.payload?.holyOrderDetail);
          setDeathDetail(result.payload.deathDetail);
          setMarriageDetails(result?.payload?.basicMarriageDetail);
          setAnnulmentDetails(result?.payload?.basicAnnulmentDetail);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        dispatch(LoadingAction.endTranslucentLoader());
      }
    });
  };

  useEffect(() => {
    if (location.state) {
      setState(location.state);
      console.log("state", location.state);
    }
  }, [location.state]);

  useEffect(() => {
    if (state?.memberId && readMemberPermission) {
      getFamilyMemberDetails();
    }
  }, [state?.memberId]);

  return (
    <React.Fragment>
      <HeaderMobile />
      <div className="main p-4 p-lg-5">
        {!readMemberPermission ? (
          <div
            // style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <NotAuthenticated />
          </div>
        ) : (
          <>
            <div className="mb-5  bg-primary ">
              <h5 id="section3" className="main-subtitle text-white">
                {/* <Link to={"/admin/family-details"} state={{ back: true }}> */}
                <Button
                  onClick={() => {
                    if (!location?.state?.back) {
                      navigate("/admin/family-details", {
                        state: { back: true },
                      });
                    } else {
                      navigate(-1);
                    }
                  }}
                  className="me-2"
                >
                  <i className="ri-arrow-left-line text-white fw-bold"></i>
                </Button>
                {/* </Link> */}
                Parishioner Personal Details
              </h5>
            </div>

            <Row className="g-5">
              <Col xl>
                <div className="media-profile mb-5 d-flex align-items-center">
                  <div className="media-img mb-3 mb-sm-0">
                    {loading ? (
                      <img src={img10} className="img-fluid" alt="..." />
                    ) : (
                      <img
                        src={
                          basicDetails?.gender === "Male" ? maleImg : femaleImg
                        }
                        className="img-fluid"
                        alt="..."
                      />
                    )}
                  </div>
                  <div className="media-body">
                    <h5 className="media-name">
                      {basicDetails?.firstName} {basicDetails?.middleName}{" "}
                      {basicDetails?.lastName}
                    </h5>

                    <p className="d-flex gap-2 mb-4 fs-14">
                      <i className="ri-home-2-fill"></i>
                      {familyDetails?.address?.addressLine1}
                    </p>
                    {basicDetails?.active ? (
                      <Badge className=" fs-16 fw-bold" bg="success" pill>
                        Active
                      </Badge>
                    ) : (
                      <Badge className=" fw-bold" bg="danger" pill>
                        Inactive
                      </Badge>
                    )}
                    {deathDetails && (
                      <Badge className="mx-2 fs-16 fw-bold" bg="secondary" pill>
                        Deceased
                      </Badge>
                    )}

                    <p className="mb-0 mt-2">{basicDetails?.remark}</p>
                  </div>
                </div>

                <Row className="row-cols-sm-auto g-4 g-md-5 g-xl-4 g-xxl-5">
                  {[
                    {
                      icon: baptism,
                      text: "Baptism",
                      label: baptismDetails ? "Done" : "Not Found",
                    },
                    {
                      icon: holyconfirmation,
                      text: "Holy Communion",
                      label: holyCommunionDetails ? "Done" : "Not Found",
                    },
                    {
                      icon: holycomunion,
                      text: "Holy Confirmation",
                      label: holyConfirmationDetails ? "Done" : "Not Found",
                    },
                    {
                      icon: priest,
                      text: "Holy Order",
                      label: holyOrderDetails ? "Done" : "Not Found",
                    },
                    {
                      icon: wedding,
                      text: "Marriage",
                      label: marriageDetails ? "Done" : "Not Found",
                    },
                    {
                      icon: divorce,
                      text: "Annulment",
                      label: annulmentDetails ? "Done" : "Not Found",
                    },
                  ].map((profileItem, index) => (
                    <Col key={index}>
                      <div className="profile-item">
                        <i className={profileItem.icon}></i>
                        <img
                          style={{ width: 40, height: 40 }}
                          className="me-2"
                          src={profileItem?.icon}
                          alt="baptism"
                        />
                        <div className="profile-item-body">
                          <p>{profileItem.text}</p>
                          <span className="text-primary fw-bold">
                            {profileItem.label}
                          </span>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>

                <BasicInformation
                  basicDetails={basicDetails}
                  contactDetails={contactDetails}
                  memberId={state?.memberId}
                  getFamilyMemberDetails={getFamilyMemberDetails}
                  deathDetails={deathDetails}
                />

                <Card className="my-4">
                  <Card.Header>
                    <Nav variant="tabs" defaultActiveKey="#first">
                      <Nav.Item onClick={() => setCurrentSection(1)}>
                        <Nav.Link active={currentSection === 1} href="#">
                          Baptism
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item onClick={() => setCurrentSection(2)}>
                        <Nav.Link active={currentSection === 2} href="#">
                          Holy Communion
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item onClick={() => setCurrentSection(3)}>
                        <Nav.Link active={currentSection === 3} href="#">
                          Holy Confirmation
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item onClick={() => setCurrentSection(4)}>
                        <Nav.Link active={currentSection === 4} href="#">
                          Holy Order
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item onClick={() => setCurrentSection(5)}>
                        <Nav.Link active={currentSection === 5} href="#">
                          Marriage
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item onClick={() => setCurrentSection(6)}>
                        <Nav.Link active={currentSection === 6} href="#">
                          Annulment
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item onClick={() => setCurrentSection(7)}>
                        <Nav.Link active={currentSection === 7} href="#">
                          Death
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Card.Header>
                  {currentSection === 1 && (
                    <BaptismDetails
                      baptismDetails={baptismDetails}
                      basicDetails={basicDetails}
                      memberId={state?.memberId}
                      getFamilyMemberDetails={getFamilyMemberDetails}
                      deathDetails={deathDetails}
                    />
                  )}

                  {currentSection === 2 && (
                    <HolyCommunionDetails
                      holyCommunionDetails={holyCommunionDetails}
                      basicDetails={basicDetails}
                      baptismDetails={baptismDetails}
                      memberId={state?.memberId}
                      getFamilyMemberDetails={getFamilyMemberDetails}
                      contactDetails={contactDetails}
                      familyDetails={familyDetails}
                      deathDetails={deathDetails}
                    />
                  )}

                  {currentSection === 3 && (
                    <div>
                      <ConfirmationDetails
                        holyCommunionDetails={holyCommunionDetails}
                        basicDetails={basicDetails}
                        baptismDetails={baptismDetails}
                        memberId={state?.memberId}
                        getFamilyMemberDetails={getFamilyMemberDetails}
                        contactDetails={contactDetails}
                        familyDetails={familyDetails}
                        holyConfirmationDetails={holyConfirmationDetails}
                        deathDetails={deathDetails}
                      />
                    </div>
                  )}

                  {currentSection === 4 && (
                    <HolyOrderDetails
                      basicDetails={basicDetails}
                      memberId={state?.memberId}
                      getFamilyMemberDetails={getFamilyMemberDetails}
                      holyConfirmationDetails={holyConfirmationDetails}
                      holyOrderDetails={holyOrderDetails}
                      deathDetails={deathDetails}
                      marriageDetails={marriageDetails}
                    />
                  )}

                  {currentSection === 5 && (
                    <MarriageDetails
                      marriageDetails={marriageDetails}
                      basicDetails={basicDetails}
                      memberId={state?.memberId}
                      getFamilyMemberDetails={getFamilyMemberDetails}
                      holyConfirmationDetails={holyConfirmationDetails}
                      holyOrderDetails={holyOrderDetails}
                      deathDetails={deathDetails}
                      familyDetails={familyDetails}
                      baptismDetails={baptismDetails}
                    />
                  )}

                  {currentSection === 6 && (
                    <AnnulmentDetails
                      basicDetails={basicDetails}
                      marriageDetails={marriageDetails}
                      annulmentDetails={annulmentDetails}
                      getFamilyMemberDetails={getFamilyMemberDetails}
                      familyDetails={familyDetails}
                    />
                  )}

                  {currentSection === 7 && (
                    <DemiseDetails
                      basicDetails={basicDetails}
                      memberId={state?.memberId}
                      getFamilyMemberDetails={getFamilyMemberDetails}
                      deathDetails={deathDetails}
                    />
                  )}
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default withAPIRequest(MemberDetails);
