import ReactDOMServer from "react-dom/server";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Offcanvas, ProgressBar, Row } from "react-bootstrap";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { ThreeCircles } from "react-loader-spinner";
import EditBaptismDetailsModal from "../../modals/baptism/EditBaptismDetailsModal";
import BaptismCertificatePdf from "../../components/sacrament-certificates/BaptismCertificatePdf";
import { useSelector } from "react-redux";
import { BlobProvider } from "@react-pdf/renderer";
import EditExternalBaptismDetailsModal from "../../modals/baptism/EditExternalBaptismDetailsModal";
import MakeExtToIntBaptismModal from "../../modals/baptism/MakeExtToIntBaptismModal";
import Swal from "sweetalert2";
import { AuthContext } from "react-oauth2-code-pkce";
import NotAuthenticated from "../../pages/NotAuthenticated";

const BaptismInfoOffset = ({
  show,
  setShow,
  baptismId,
  commonAPIRequest,
  basicDetails,
  memberId,
  getFamilyMemberDetails,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentBaptism, setCurrentBaptism] = useState();
  const [editBaptismModal, setEditBaptismModal] = useState(false);
  const [editExtBaptismModal, setEditExtBaptismModal] = useState(false);
  const { churchDetails } = useSelector((state) => state?.church);
  const [makeExtToIntBaptismModal, setMakeExtToIntBaptismModal] =
    useState(false);
  const { tokenData } = useContext(AuthContext);

  const baptismPermission = tokenData?.permissions?.includes("READ_BAPTISM");

  const callGetBaptismDetails = () => {
    let serviceParams = {
      api: `${API.BAPTISM_DETAILS}?baptismId=${baptismId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          // console.log("result", result.payload);
          setCurrentBaptism(result?.payload);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (show && baptismPermission) {
      callGetBaptismDetails();
    }
  }, [show]);

  return (
    <Offcanvas show={show} onHide={() => setShow(false)} placement="end">
      {!baptismPermission ? (
        <>
          <Offcanvas.Header className="pb-0" closeButton>
            <Offcanvas.Title>
              Baptism Details
              <br />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <NotAuthenticated />
        </>
      ) : (
        <>
          <Offcanvas.Header className="pb-0" closeButton>
            <Offcanvas.Title>
              Baptism Details
              <br />
              <p className="fw-bold m-0 text-primary">
                {currentBaptism?.referenceNum}
              </p>
            </Offcanvas.Title>
          </Offcanvas.Header>

          <hr className="hr m-0" />
          <Offcanvas.Body>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ThreeCircles
                  height="40"
                  width="40"
                  color="#303033"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="three-circles-rotating"
                  outerCircleColor=""
                  innerCircleColor=""
                  middleCircleColor=""
                />
              </div>
            ) : (
              <Row>
                <Col className="mb-1 d-flex justify-content-end" sm={12}>
                  {!currentBaptism?.extBaptism && (
                    <BlobProvider
                      document={
                        <BaptismCertificatePdf
                          details={currentBaptism}
                          churchDetails={churchDetails}
                        />
                      }
                    >
                      {({ blob, url, loading, error }) => {
                        // Handle loading state
                        if (loading) {
                          return (
                            <Button className="mx-3" disabled>
                              Loading...
                            </Button>
                          );
                        }
                        // Handle error state
                        if (error) {
                          return (
                            <Button className="mx-3" variant="danger" disabled>
                              Error generating PDF
                            </Button>
                          );
                        }
                        // Function to open PDF in a new tab
                        const openPdf = () => {
                          const pdfWindow = window.open();
                          pdfWindow.location.href = url;
                        };
                        return (
                          <Button className="mx-3" onClick={openPdf}>
                            Generate PDF{" "}
                          </Button>
                        );
                      }}
                    </BlobProvider>
                  )}

                  <Button
                    onClick={() =>
                      currentBaptism?.extBaptism
                        ? setEditExtBaptismModal(true)
                        : setEditBaptismModal(true)
                    }
                  >
                    EDIT
                  </Button>
                </Col>
                <hr />
                {currentBaptism?.extBaptism ? (
                  <Row>
                    <Col sm={6}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>First Name</strong>
                        </p>
                        <p
                          style={{ fontSize: 18, fontWeight: "lighter" }}
                          className="mb-0"
                        >
                          {currentBaptism?.firstName}
                        </p>
                      </div>
                    </Col>

                    {currentBaptism?.middleName && (
                      <Col sm={6}>
                        <div className="my-2">
                          <p className="mb-0">
                            <strong>Middle Name</strong>
                          </p>
                          <p
                            style={{ fontSize: 18, fontWeight: "lighter" }}
                            className="mb-0"
                          >
                            {currentBaptism?.middleName}
                          </p>
                        </div>
                      </Col>
                    )}

                    <Col sm={6}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>Last Name</strong>
                        </p>
                        <p
                          style={{ fontSize: 18, fontWeight: "lighter" }}
                          className="mb-0"
                        >
                          {currentBaptism?.lastName
                            ? currentBaptism?.lastName
                            : "-"}
                        </p>
                      </div>
                    </Col>

                    <Col sm={6}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>External Baptism Church Name</strong>
                        </p>
                        <p
                          style={{ fontSize: 18, fontWeight: "lighter" }}
                          className="mb-0"
                        >
                          {currentBaptism?.extBaptismChurch
                            ? currentBaptism?.extBaptismChurch
                            : "-"}
                        </p>
                      </div>
                    </Col>

                    <Col sm={6}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>External Baptism Church City</strong>
                        </p>
                        <p
                          style={{ fontSize: 18, fontWeight: "lighter" }}
                          className="mb-0"
                        >
                          {currentBaptism?.extBaptismCity
                            ? currentBaptism?.extBaptismCity
                            : "-"}
                        </p>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col sm={6}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>First Name</strong>
                        </p>
                        <p
                          style={{ fontSize: 18, fontWeight: "lighter" }}
                          className="mb-0"
                        >
                          {currentBaptism?.firstName}
                        </p>
                      </div>
                    </Col>
                    {currentBaptism?.middleName && (
                      <Col sm={6}>
                        <div className="my-2">
                          <p className="mb-0">
                            <strong>Middle Name</strong>
                          </p>
                          <p
                            style={{ fontSize: 18, fontWeight: "lighter" }}
                            className="mb-0"
                          >
                            {currentBaptism?.middleName}
                          </p>
                        </div>
                      </Col>
                    )}
                    <Col sm={6}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>Last Name</strong>
                        </p>
                        <p
                          style={{ fontSize: 18, fontWeight: "lighter" }}
                          className="mb-0"
                        >
                          {currentBaptism?.lastName
                            ? currentBaptism?.lastName
                            : "-"}
                        </p>
                      </div>
                    </Col>

                    <ProgressBar now="100" className="h-1 my-2" />

                    <Col sm={6}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>Baptism Date</strong>
                        </p>
                        <p
                          style={{ fontSize: 18, fontWeight: "lighter" }}
                          className="mb-0"
                        >
                          {new Date(
                            currentBaptism?.baptismDate
                          ).toLocaleDateString("en-GB", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                        </p>
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>Date Of Birth</strong>
                        </p>
                        <p
                          style={{ fontSize: 18, fontWeight: "lighter" }}
                          className="mb-0"
                        >
                          {new Date(currentBaptism?.dob).toLocaleDateString(
                            "en-GB",
                            {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            }
                          )}
                        </p>
                      </div>
                    </Col>

                    <ProgressBar now="100" className="h-1 my-2" />

                    <Col sm={6}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>Father's Name</strong>
                        </p>
                        <p
                          style={{ fontSize: 18, fontWeight: "lighter" }}
                          className="mb-0"
                        >
                          {currentBaptism?.fatherName
                            ? currentBaptism?.fatherName
                            : "-"}
                        </p>
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>Mothers's Name</strong>
                        </p>
                        <p
                          style={{ fontSize: 18, fontWeight: "lighter" }}
                          className="mb-0"
                        >
                          {currentBaptism?.motherName
                            ? currentBaptism?.motherName
                            : "-"}
                        </p>
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>Father's Nationality</strong>
                        </p>
                        <p
                          style={{ fontSize: 18, fontWeight: "lighter" }}
                          className="mb-0"
                        >
                          {currentBaptism?.fatherNationality
                            ? currentBaptism?.fatherNationality
                            : "-"}
                        </p>
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>Father's Domicile</strong>
                        </p>
                        <p
                          style={{ fontSize: 18, fontWeight: "lighter" }}
                          className="mb-0"
                        >
                          {currentBaptism?.fatherDomicile
                            ? currentBaptism?.fatherDomicile
                            : "-"}
                        </p>
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>Father's Profession</strong>
                        </p>
                        <p
                          style={{ fontSize: 18, fontWeight: "lighter" }}
                          className="mb-0"
                        >
                          {currentBaptism?.fatherProfession
                            ? currentBaptism?.fatherProfession
                            : "-"}
                        </p>
                      </div>
                    </Col>

                    <ProgressBar now="100" className="h-1 my-2" />

                    <Col sm={6}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>God Father's First Name</strong>
                        </p>
                        <p
                          style={{ fontSize: 18, fontWeight: "lighter" }}
                          className="mb-0"
                        >
                          {currentBaptism?.godFatherName
                            ? currentBaptism?.godFatherName
                            : "-"}
                        </p>
                      </div>
                    </Col>

                    <Col sm={6}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>God Father's Last Name</strong>
                        </p>
                        <p
                          style={{ fontSize: 18, fontWeight: "lighter" }}
                          className="mb-0"
                        >
                          {currentBaptism?.godFatherSurName
                            ? currentBaptism?.godFatherSurName
                            : "-"}
                        </p>
                      </div>
                    </Col>

                    <Col sm={6}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>God Mother's First Name</strong>
                        </p>
                        <p
                          style={{ fontSize: 18, fontWeight: "lighter" }}
                          className="mb-0"
                        >
                          {currentBaptism?.godMotherName
                            ? currentBaptism?.godMotherName
                            : "-"}
                        </p>
                      </div>
                    </Col>

                    <Col sm={6}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>God Mother's Last Name</strong>
                        </p>
                        <p
                          style={{ fontSize: 18, fontWeight: "lighter" }}
                          className="mb-0"
                        >
                          {currentBaptism?.godMotherSurName
                            ? currentBaptism?.godMotherSurName
                            : "-"}
                        </p>
                      </div>
                    </Col>

                    <Col sm={6}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>God Father's Domicile</strong>
                        </p>
                        <p
                          style={{ fontSize: 18, fontWeight: "lighter" }}
                          className="mb-0"
                        >
                          {currentBaptism?.godFatherDomicile
                            ? currentBaptism?.godFatherDomicile
                            : "-"}
                        </p>
                      </div>
                    </Col>

                    <Col sm={6}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>God Mother's Domicile</strong>
                        </p>
                        <p
                          style={{ fontSize: 18, fontWeight: "lighter" }}
                          className="mb-0"
                        >
                          {currentBaptism?.godMotherDomicile
                            ? currentBaptism?.godMotherDomicile
                            : "-"}
                        </p>
                      </div>
                    </Col>

                    <ProgressBar now="100" className="h-1 my-2" />

                    <Col sm={6}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>Minister's Name</strong>
                        </p>
                        <p
                          style={{ fontSize: 18, fontWeight: "lighter" }}
                          className="mb-0"
                        >
                          {currentBaptism?.ministerName
                            ? currentBaptism?.ministerName
                            : "-"}
                        </p>
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>Place Of Baptism</strong>
                        </p>
                        <p
                          style={{ fontSize: 18, fontWeight: "lighter" }}
                          className="mb-0"
                        >
                          {currentBaptism?.placeOfBaptism
                            ? currentBaptism?.placeOfBaptism
                            : "-"}
                        </p>
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>Communion Date</strong>
                        </p>
                        <p
                          style={{ fontSize: 18, fontWeight: "lighter" }}
                          className="mb-0"
                        >
                          {currentBaptism?.holyCommunionDate
                            ? new Date(
                                currentBaptism?.holyCommunionDate
                              ).toLocaleDateString("en-GB", {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              })
                            : "-"}
                        </p>
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>Confirmation Date</strong>
                        </p>
                        <p
                          style={{ fontSize: 18, fontWeight: "lighter" }}
                          className="mb-0"
                        >
                          {currentBaptism?.confirmationDate
                            ? new Date(
                                currentBaptism?.confirmationDate
                              ).toLocaleDateString("en-GB", {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              })
                            : "-"}
                        </p>
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>Marriage Date</strong>
                        </p>
                        <p
                          style={{ fontSize: 18, fontWeight: "lighter" }}
                          className="mb-0"
                        >
                          {currentBaptism?.marriageDate
                            ? new Date(
                                currentBaptism?.marriageDate
                              ).toLocaleDateString("en-GB", {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              })
                            : "-"}
                        </p>
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>Remark</strong>
                        </p>
                        <p
                          style={{ fontSize: 18, fontWeight: "lighter" }}
                          className="mb-0"
                        >
                          {currentBaptism?.remarks
                            ? currentBaptism?.remarks
                            : "-"}
                        </p>
                      </div>
                    </Col>
                  </Row>
                )}

                {currentBaptism?.extBaptism && (
                  <Col
                    sm={12}
                    style={{ position: "absolute", bottom: 10, right: 10 }}
                  >
                    <Button
                      variant="outline-primary"
                      className="mx-1"
                      onClick={() => {
                        if (1) {
                          Swal.fire({
                            title: "Are you sure?",
                            text: "You won't be able to revert this action!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#7e7e7e",
                            confirmButtonText: "Yes, create it!",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              setMakeExtToIntBaptismModal(true);
                            }
                          });
                        }
                      }}
                    >
                      Make this an internal certificate
                    </Button>
                  </Col>
                )}
              </Row>
            )}
          </Offcanvas.Body>
        </>
      )}
      <EditBaptismDetailsModal
        basicDetails={basicDetails}
        show={editBaptismModal}
        setShow={setEditBaptismModal}
        currentBaptism={currentBaptism}
        memberId={memberId}
        callGetBaptismDetails={callGetBaptismDetails}
        getFamilyMemberDetails={getFamilyMemberDetails}
        closeOffset={() => setShow(false)}
      />
      <EditExternalBaptismDetailsModal
        basicDetails={basicDetails}
        show={editExtBaptismModal}
        setShow={setEditExtBaptismModal}
        currentBaptism={currentBaptism}
        memberId={memberId}
        callGetBaptismDetails={callGetBaptismDetails}
        getFamilyMemberDetails={getFamilyMemberDetails}
        closeOffset={() => setShow(false)}
      />

      <MakeExtToIntBaptismModal
        show={makeExtToIntBaptismModal}
        setShow={setMakeExtToIntBaptismModal}
        basicDetails={basicDetails}
        memberId={memberId}
        currentBaptism={currentBaptism}
        getFamilyMemberDetails={getFamilyMemberDetails}
        closeOffset={() => setShow(false)}
      />
    </Offcanvas>
  );
};

export default withAPIRequest(BaptismInfoOffset);
