import Lottie from "lottie-react";
import React, { useContext, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import TextInput from "../../components/form/TextInput";
import SelectInput from "../../components/form/SelectInput";
import DateInput from "../../components/form/DateInput";
import TextArea from "../../components/form/TextArea";
import { useFormik } from "formik";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import { DonationValidationSchema } from "../../validations/financials";
import Swal from "sweetalert2";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { AuthContext } from "react-oauth2-code-pkce";
import NotAuthenticated from "../../pages/NotAuthenticated";

const paymentModeArray = [
  { label: "UPI", value: "UPI" },
  { label: "CASH", value: "CASH" },
  { label: "CHEQUE", value: "CHEQUE" },
];

const AddDonationModal = ({
  show,
  setShow,
  commonAPIRequest,
  getAllDonationsApi,
  getAllDonationsGraphDataApi,
  donations,
}) => {
  const [loading, setLoading] = useState(false);
  const { tokenData } = useContext(AuthContext);
  const addDonationPermission =
    tokenData?.permissions?.includes("WRITE_DONATION");

  const formik = useFormik({
    initialValues: {
      donatedBy: "",
      totalDonationAmount: "",
      paymentMode: "",
      paidOn: "",
      purpose: "",
      remark: "",
      address: "",
    },
    validationSchema: DonationValidationSchema,
    onSubmit: (values) => {
      Swal.fire({
        title: "<strong>Are you sure?</strong>",
        icon: "info",
        html: `
          Donated By: <b>${values?.donatedBy}</b> <br />
          Amount Donated: <b>₹ ${values?.totalDonationAmount}</b> <br />
          Donation Deposited On: <b>${new Date(
            values?.paidOn
          ).toLocaleDateString("en-GB", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })}</b> <br />
          Mode Of Payment: <b>${values?.paymentMode}</b> <br />
          Purpose: <b>${values?.purpose}</b> <br />`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: `Yes, Sure!`,
        confirmButtonAriaLabel: "Yes, Sure!",
        cancelButtonText: `No`,
        cancelButtonAriaLabel: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          addDonationApi(values);
        }
      });
    },
  });

  // ADD DONATION API FUNCTION START

  const addDonationApi = (values) => {
    let serviceParams = {
      api: API.ADD_DONATION,
      method: AXIOS_METHOD_TYPES.POST,
      data: {
        donationDate: values?.paidOn,
        description: values?.remark,
        amount: +values?.totalDonationAmount,
        paymentMode: values.paymentMode,
        donatedBy: values?.donatedBy,
        category: values?.purpose,
        addressLine1: values?.address,
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          getAllDonationsApi();
          getAllDonationsGraphDataApi();
          setShow(false);
          formik.resetForm();
          Swal.fire("Success", `Donation Added Successfully.`, "success");
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // ADD DONATION API FUNCTION END

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Add Contribution's Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : !addDonationPermission ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <NotAuthenticated />
          </div>
        ) : (
          <Row>
            <Col className="mt-2" sm={12} lg={6}>
              <TextInput
                label={"Donated By"}
                placeholder={"e.g. Kumar"}
                setFieldValue={formik.setFieldValue}
                keyword={"donatedBy"}
                value={formik.values.donatedBy}
                error={formik.errors.donatedBy}
                touched={formik.touched.donatedBy}
              />
            </Col>

            <Col className="mt-2" sm={12} lg={6}>
              <TextInput
                label={"Amount"}
                placeholder={"e.g. 5000"}
                setFieldValue={formik.setFieldValue}
                keyword={"totalDonationAmount"}
                value={formik.values.totalDonationAmount}
                error={formik.errors.totalDonationAmount}
                touched={formik.touched.totalDonationAmount}
              />
            </Col>
            <Col className="mt-2" sm={12} lg={6}>
              <SelectInput
                label={"Mode Of Payment"}
                selectOptions={paymentModeArray}
                setFieldValue={formik.setFieldValue}
                keyword={"paymentMode"}
                value={formik.values.paymentMode}
                error={formik.errors.paymentMode}
                touched={formik.touched.paymentMode}
              />
            </Col>
            <Col className="mt-2" sm={12} lg={6}>
              <DateInput
                label={"Donation Made On"}
                setFieldValue={formik.setFieldValue}
                keyword={"paidOn"}
                value={formik.values.paidOn}
                error={formik.errors.paidOn}
                touched={formik.touched.paidOn}
              />
            </Col>

            <Col sm={12}>
              <SelectInput
                selectOptions={donations ? donations : []}
                label={"Purpose Of Donation"}
                setFieldValue={formik.setFieldValue}
                keyword={"purpose"}
                value={formik.values.purpose}
                error={formik.errors.purpose}
                touched={formik.touched.purpose}
              />
            </Col>

            <Col className="mt-2" sm={12} lg={12}>
              <TextInput
                label={"Donor's Address"}
                placeholder={"e.g. Type Address..."}
                setFieldValue={formik.setFieldValue}
                keyword={"address"}
                value={formik.values.address}
                error={formik.errors.address}
                touched={formik.touched.address}
              />
            </Col>

            <Col className="mt-2" sm={12}>
              <TextArea
                label={"Remark"}
                setFieldValue={formik.setFieldValue}
                keyword={"remark"}
                value={formik.values.remark}
                error={formik.errors.remark}
                touched={formik.touched.remark}
                placeholder={"Enter Remark..."}
              />
            </Col>
          </Row>
        )}
      </Modal.Body>
      {addDonationPermission && !loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(AddDonationModal);
