import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "react-oauth2-code-pkce";
import { Col, Row } from "react-bootstrap";
import { ThreeCircles } from "react-loader-spinner";
import jwtDecode from "jwt-decode";

const TokenPage = () => {
  let navigate = useNavigate();
  const { token, tokenData } = useContext(AuthContext);
  console.log("token", token);
  console.log("token-data", tokenData);

  useEffect(() => {
    setTimeout(() => {
      if (token) {
        const decoded = jwtDecode(token);
        console.log(decoded.role);
        if (decoded?.role === "CHR_ADMIN") {
          navigate("/admin/dashboard");
        } else if (decoded?.role === "DIO_ADMIN") {
          navigate("/diocese/dashboard");
        } else {
          return;
        }
      }
    }, 2000);
  }, [token]);

  return (
    <div className="page-sign d-block py-0">
      <Row className="g-0">
        <Col className="d-block">
          <div
            style={{
              position: "relative",
              height: "100vh",
              width: "100vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 100,
            }}
          >
            <ThreeCircles
              height="80"
              width="80"
              color="#fff"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TokenPage;
