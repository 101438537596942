import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { useFormik } from "formik";
import {
  BasicContactValidationSchema,
  FamilyHeadBasciDetailsValidation,
  FamilyMemberBasicDetailsValidation,
} from "../../validations/families";
import AddFamilyFormStep2 from "../../forms/families/AddFamilyFormStep2";
import AddFamilyFormStep3 from "../../forms/families/AddFamilyFormStep3";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import Swal from "sweetalert2";
import AddFamilyMemberFormStep1 from "../../forms/members/AddFamilyMemberFormStep1";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";

function capitalizeText(text) {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

const AddFamilyMemberModal = ({
  show,
  setShow,
  commonAPIRequest,
  familyId,
  getAllFamilyMembersDetailsApi,
}) => {
  const [currentState, setCurrentState] = useState(1);
  const [loading, setLoading] = useState(false);

  const formikStep1 = useFormik({
    initialValues: {
      headOfFamily: false,
      firstName: "",
      lastName: "",
      middleName: "",
      baptismName: "",
      dob: "",
      gender: "",
      fatherName: "",
      motherName: "",
      occupation: "",
      bloodGroup: "",
      willingToDonate: false,
      marriageStatus: "",
      relationToHead: "",
      remark: "",
    },
    validationSchema: FamilyMemberBasicDetailsValidation,
    onSubmit: (values) => {
      if (!(currentState >= 2)) {
        setCurrentState((prevValue) => prevValue + 1);
      }
    },
  });

  const formikStep2 = useFormik({
    initialValues: {
      phone: "",
      email: "",
      whatsappNumber: "",
    },
    validationSchema: BasicContactValidationSchema,
    onSubmit: (values) => {
      callAddFamilyMember();
    },
  });

  // function to call API for Add New Family start

  const callAddFamilyMember = (value) => {
    let serviceParams = {
      api: API.CREATE_MEMBER,
      method: AXIOS_METHOD_TYPES.POST,
      data: {
        churchMemberDetails: [
          {
            basicDetail: {
              firstName: capitalizeText(formikStep1.values.firstName.trim()),
              middleName: capitalizeText(formikStep1.values.middleName.trim()),
              lastName: capitalizeText(formikStep1.values.lastName.trim()),
              baptismName: capitalizeText(
                formikStep1.values.baptismName.trim()
              ),
              dateOfBirth: new Date(formikStep1.values.dob),
              fatherName: capitalizeText(formikStep1.values.fatherName.trim()),
              motherName: capitalizeText(formikStep1.values.motherName.trim()),
              bloodGroup: formikStep1.values.bloodGroup,
              donateBlood: formikStep1.values.willingToDonate,
              occupation: capitalizeText(formikStep1.values.occupation.trim()),
              familyHead: false,
              gender: formikStep1.values.gender,
              relationToHead: formikStep1.values.relationToHead,
              marriageStatus: formikStep1.values?.marriageStatus,
              remark: formikStep1.values.remark,
            },
            churchFamilyDetail: {
              familyId: familyId,
            },
            contactDetail: {
              phNumber: formikStep2.values.phone.trim(),
              emailAddress: formikStep2.values.email.trim(),
              whatsAppNumber: formikStep2.values.whatsappNumber.trim(),
            },
          },
        ],
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire("Success", `Member Added Successfully.`, "success");
          getAllFamilyMembersDetailsApi();
          setShow(false);
          formikStep1.resetForm();
          formikStep2.resetForm();
          setCurrentState(1);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Add New Family end

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
        setCurrentState(1);
        formikStep1.resetForm();
        formikStep2.resetForm();
      }}
      size="xl"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Add New Member
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <div
            id="wizard1"
            className="wizard-demo wizard clearfix"
            role="application"
          >
            <div className="steps clearfix">
              <ul role="tablist">
                <li
                  role="tab"
                  className={
                    currentState === 1
                      ? "current first"
                      : currentState > 1
                      ? "done first"
                      : "first"
                  }
                  aria-disabled="false"
                  aria-selected="true"
                >
                  <a id="wizard1-t-0" href="#" aria-controls="wizard1-p-0">
                    <span className="number">1</span>
                    <span className="title">Step 1</span>
                  </a>
                </li>
                <li
                  role="tab"
                  className={
                    currentState === 2
                      ? "current second"
                      : currentState > 2
                      ? "done second"
                      : "second"
                  }
                  aria-disabled="true"
                  aria-selected="false"
                >
                  <a id="wizard1-t-1" href="#" aria-controls="wizard1-p-1">
                    <span className="number">2</span>{" "}
                    <span className="title">Step 2</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="content clearfix">
              {currentState === 1 && (
                <div>
                  <h3 id="wizard1-h-0" tabIndex="-1" className="title current">
                    Family Member Personal Information
                  </h3>

                  <p className="mb-0">
                    Enter the details of family member you want to add.
                  </p>
                  <hr className="hr" />
                  <AddFamilyMemberFormStep1 formik={formikStep1} />
                </div>
              )}

              {currentState === 2 && (
                <div>
                  <h3 id="wizard1-h-0" tabIndex="-1" className="title current">
                    Contact Information
                  </h3>

                  <p className="mb-0">
                    Enter the contact details of family member.
                  </p>
                  <hr className="hr" />
                  <AddFamilyFormStep3 formik={formikStep2} />
                </div>
              )}
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            if (!(currentState <= 1)) {
              setCurrentState((prevValue) => prevValue - 1);
            }
          }}
        >
          Previous
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            if (currentState === 1) {
              formikStep1.handleSubmit();
            } else if (currentState === 2) {
              formikStep2.handleSubmit();
            }
          }}
        >
          {currentState === 2 ? "Save" : "Next"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default withAPIRequest(AddFamilyMemberModal);
