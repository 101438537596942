import React from "react";
import { Button } from "react-bootstrap";
import * as XLSX from "xlsx";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";

const CsfExcelReport = ({ totalCount, currentMonth, commonAPIRequest }) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const header = [
    "S.No",
    "Family ID",
    "Name",
    "Amount",
    "Paid On",
    "Mode",
    "Transaction Id",
  ];

  const getAllCsfsApi = () => {
    let serviceParams = {
      api: `${
        API.GET_CSF_SUMMARY
      }?pgNum=1&pgSize=${totalCount}&month=${currentMonth}&year=${new Date().getFullYear()}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    // setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        // setLoading(false);
        try {
          const data = result.payload?.consolidatedSupportFundDetailList.map(
            (csf, i) => ({
              sno: i + 1,
              familyId: csf.churchFamilyId,
              name: `${csf.firstName} ${csf.lastName}`,
              amount: csf.fundAmount,
              donatedOn: new Date(csf.collectionDate).toLocaleDateString(
                "en-IN"
              ),
              mode: csf.paymentMode,
              txn: csf.sysTransactionId,
            })
          );

          const wsData = [header, ...data.map((row) => Object.values(row))];

          const ws = XLSX.utils.aoa_to_sheet(wsData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "CSF");
          XLSX.writeFile(
            wb,
            `CSF_${months[currentMonth - 1]}_${new Date().getFullYear()}.xlsx`
          );
        } catch (error) {
          console.log("error", error);
        }
      } else {
        // setLoading(false);
      }
    });
  };

  function handleDownloadExcel() {
    getAllCsfsApi();
  }

  return (
    <div>
      <Button className="ms-3" onClick={handleDownloadExcel}>
        <i className="ri-download-2-fill"></i>
      </Button>
    </div>
  );
};

export default withAPIRequest(CsfExcelReport);
