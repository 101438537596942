import React, { useEffect, useState } from "react";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import Lottie from "lottie-react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import TextInput from "../../components/form/TextInput";
import { useFormik } from "formik";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import SelectInput from "../../components/form/SelectInput";

const AddParishUsersModal = ({
  show,
  setShow,
  commonAPIRequest,
  churchId,
  callGetAllParishUsers,
}) => {
  const [loading, setLoading] = useState(false);
  const [priestDetails, setPriestDetails] = useState();
  const [priests, setPriests] = useState([]);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phoneNum: "",
      priestDetailId: "",
      password: "",
      userRole: "CHR_ADMIN",
      churchId: "",
      loginId: "",
    },

    onSubmit: (values) => {
      callAddParishUserInResourceApi();
    },
  });

  const callGetAllPriests = () => {
    let serviceParams = {
      api: `${API.GET_PRIEST_SUMMARY}?pgNum=1&pgSize=100`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setPriests(
            result?.payload?.basicPriestDetailList?.map((priest) => {
              return {
                value: priest?.priestDetailId,
                label: `${priest?.firstName} ${priest?.lastName}`,
              };
            })
          );
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  const callGetPriestDetails = (id) => {
    let serviceParams = {
      api: `${API.GET_PRIEST_DETAILS}?priestDetailId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setPriestDetails(result?.payload);
          formik.setFieldValue("firstName", result?.payload?.firstName);
          formik.setFieldValue("lastName", result?.payload?.lastName);
          formik.setFieldValue("loginId", result?.payload?.email);
          formik.setFieldValue("phoneNum", result?.payload?.phNumber);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (show) {
      callGetAllPriests();
    }
  }, [show]);

  useEffect(() => {
    if (formik.values.priestDetailId) {
      callGetPriestDetails(formik.values.priestDetailId);
    }
  }, [formik.values.priestDetailId]);

  const callAddParishUserInResourceApi = () => {
    let serviceParams = {
      api: API.CREATE_USER_RESOURCE,
      method: AXIOS_METHOD_TYPES.POST,
      data: {
        churchId: churchId,
        firstName: formik.values?.firstName,
        lastName: formik.values?.lastName,
        loginId: formik.values?.loginId,
        password: formik.values?.password,
        userRole: formik.values?.userRole,
        phoneNum: formik.values?.phoneNum,
        priestDetailId: formik.values?.priestDetailId,
      },
    };
    setShow(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setShow(false);
          callGetAllParishUsers();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">Add New User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <div>
            {priests.length !== 0 && (
              <Row>
                <Col>
                  <SelectInput
                    selectOptions={priests}
                    label={"Priests"}
                    placeholder={"e.g. joseph"}
                    id={"priestDetailId"}
                    setFieldValue={formik.setFieldValue}
                    keyword="priestDetailId"
                    value={formik.values.priestDetailId}
                    error={formik.errors.priestDetailId}
                    touched={formik.touched.priestDetailId}
                  />
                </Col>
              </Row>
            )}
            {priestDetails && (
              <Row>
                <Col sm={6}>
                  <TextInput
                    label={"First Name"}
                    placeholder={"e.g. joseph"}
                    id={"firstName"}
                    setFieldValue={formik.setFieldValue}
                    keyword="firstName"
                    value={formik.values.firstName}
                    error={formik.errors.firstName}
                    touched={formik.touched.firstName}
                    disabled={true}
                  />
                </Col>
                <Col sm={6}>
                  <TextInput
                    label={"Last Name"}
                    placeholder={"e.g. Toppo"}
                    id={"lastName"}
                    setFieldValue={formik.setFieldValue}
                    keyword="lastName"
                    value={formik.values.lastName}
                    error={formik.errors.lastName}
                    touched={formik.touched.lastName}
                    disabled={true}
                  />
                </Col>

                <Col sm={6}>
                  <TextInput
                    label={"User Email"}
                    placeholder={"e.g. joseph@gmail.com"}
                    id={"loginId"}
                    setFieldValue={formik.setFieldValue}
                    keyword="loginId"
                    value={formik.values.loginId}
                    error={formik.errors.loginId}
                    touched={formik.touched.loginId}
                    disabled={true}
                  />
                </Col>
                <Col sm={6}>
                  <TextInput
                    label={"Phone Number"}
                    placeholder={"e.g. 9898989898"}
                    id={"phoneNum"}
                    setFieldValue={formik.setFieldValue}
                    keyword="phoneNum"
                    value={formik.values.phoneNum}
                    error={formik.errors.phoneNum}
                    touched={formik.touched.phoneNum}
                    disabled={true}
                  />
                </Col>

                <Col sm="12">
                  <TextInput
                    label={"Password"}
                    placeholder={"Enter a Password"}
                    id={"password"}
                    setFieldValue={formik.setFieldValue}
                    keyword="password"
                    value={formik.values.password}
                    error={formik.errors.password}
                    touched={formik.touched.password}
                  />
                </Col>

                <Col sm={12}>
                  <div
                    className="my-3 alert alert-danger text-left"
                    role="alert"
                  >
                    Your password must contain
                    <br />- 2 Special character{" "}
                    <span className="text-muted">
                      e.g. (@, #, $, etc)
                    </span>, <br />- 2 Uppercase character{" "}
                    <span className="text-muted">e.g. (S, A, P, etc)</span>,{" "}
                    <br />- 2 Digit{" "}
                    <span className="text-muted">e.g. (1, 6, 4, etc)</span>,
                    <br />- 2 Lowercase character{" "}
                    <span className="text-muted">e.g. (d, e, u, etc)</span>,
                    <br />- No Repeating character more than 2{" "}
                    <span className="text-muted">
                      e.g. (aaa, bbb, ccc, etc)
                    </span>
                  </div>
                </Col>

                <Col sm="12">
                  <TextInput
                    label={"Role"}
                    placeholder={"e.g. ADMIN"}
                    id={"userRole"}
                    setFieldValue={formik.setFieldValue}
                    keyword="userRole"
                    value={formik.values.userRole}
                    error={formik.errors.userRole}
                    disabled
                    touched={formik.touched.userRole}
                  />
                </Col>
              </Row>
            )}
          </div>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button onClick={formik.handleSubmit} variant="primary">
            Save
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(AddParishUsersModal);
