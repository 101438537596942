import React from "react";
import { Container, Row } from "react-bootstrap";
import noauth from "../assets/img/no-auth-delhi.png";

const NotAuthenticated = ({ noMinHeight }) => {
  return (
    <div
      className={
        noMinHeight
          ? "content d-flex align-items-center justify-content-center"
          : "content d-flex align-items-center justify-content-center min-vh-100"
      }
    >
      <Container>
        <Row className="d-flex justify-content-center align-items-center text-center">
          <div xs="8" lg="6" className="mb-4">
            <img
              style={{ width: 300, height: 300 }}
              src={noauth}
              alt="no-auth"
            />
          </div>
          <div className="pb-3" lg="6">
            <h2 className="error-title text-primary fw-bold">
              Not Authenticated
            </h2>
            <p className="error-text d-none d-lg-inline text-primary">
              Oops. You don’t have permission to access this resource. <br />
              Please contact your administrator to request access.
            </p>

            <p className="d-lg-none">
              Oops. You don’t have permission to access this resource. Please
              contact your administrator to request access.
            </p>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default NotAuthenticated;
