import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import {
  BasicContactValidationSchema,
  FamilyHeadBasciDetailsValidation,
  FamilyMemberBasicDetailsValidation,
} from "../../validations/families";
import Swal from "sweetalert2";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { Button, Form, Modal } from "react-bootstrap";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
// import AddFamilyMemberFormStep1 from "../../forms/members/AddFamilyMemberFormStep1";
import AddFamilyFormStep3 from "../../forms/families/AddFamilyFormStep3";
// import AddFamilyFormStep2 from "../../forms/families/AddFamilyFormStep2";
import EditFamilyMemberFormStep1 from "../../forms/members/EditFamilyMemberFormStep1";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { AuthContext } from "react-oauth2-code-pkce";
import NotAuthenticated from "../../pages/NotAuthenticated";

const EditFamilyMemberModal = ({
  show,
  setShow,
  commonAPIRequest,
  memberId,
  getFamilyMemberDetails,
  basicDetails,
  contactDetails,
}) => {
  const [currentState, setCurrentState] = useState(1);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const { tokenData } = useContext(AuthContext);
  const updateMemberPermission =
    tokenData?.permissions?.includes("UPDATE_MEMBER");

  const formikStep1 = useFormik({
    initialValues: {
      headOfFamily: false,
      firstName: "",
      lastName: "",
      middleName: "",
      baptismName: "",
      dob: "",
      gender: "",
      fatherName: "",
      motherName: "",
      occupation: "",
      marriageStatus: "",
      bloodGroup: "",
      willingToDonate: false,
      relationToHead: "",
      remark: "",
    },
    validationSchema: basicDetails?.familyHead
      ? FamilyHeadBasciDetailsValidation
      : FamilyMemberBasicDetailsValidation,
    onSubmit: (values) => {
      if (!(currentState >= 2)) {
        setCurrentState((prevValue) => prevValue + 1);
      }
    },
  });

  const formikStep2 = useFormik({
    initialValues: {
      phone: "",
      email: "",
      whatsappNumber: "",
    },
    validationSchema: BasicContactValidationSchema,
    onSubmit: (values) => {
      callEditFamilyMember();
    },
  });

  // function to call API for Edit New Family start

  const callEditFamilyMember = (value) => {
    let serviceParams = {
      api: API.UPDATE_MEMBER,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {
        memberDetail: {
          memberId: memberId,
          firstName: formikStep1.values.firstName,
          middleName: formikStep1.values.middleName,
          lastName: formikStep1.values.lastName,
          baptismName: formikStep1.values.baptismName,
          fatherName: formikStep1.values.fatherName,
          motherName: formikStep1.values.motherName,
          phNumber: formikStep2.values.phone,
          remark: formikStep1.values.remark,
          whatsAppNumber: formikStep2.values.whatsappNumber,
          emailAddress: formikStep2.values.email,
          dateOfBirth: new Date(formikStep1.values.dob),
          familyHead: formikStep1.values.headOfFamily,
          bloodGroup: formikStep1.values.bloodGroup,
          donateBlood: formikStep1.values.willingToDonate,
          occupation: formikStep1.values.occupation,
          gender: formikStep1.values.gender,
          marriageStatus: formikStep1.values?.marriageStatus,
          relationToHead: formikStep1.values.relationToHead,
          active: status,
        },
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire("Success", `Member Updated Successfully.`, "success");
          getFamilyMemberDetails();
          setShow(false);
          setCurrentState(1);
          formikStep1.resetForm();
          formikStep2.resetForm();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Edit New Family end

  useEffect(() => {
    if (show) {
      formikStep1.setFieldValue("firstName", basicDetails?.firstName);
      formikStep1.setFieldValue("middleName", basicDetails?.middleName);
      formikStep1.setFieldValue("lastName", basicDetails?.lastName);
      formikStep1.setFieldValue("baptismName", basicDetails?.baptismName);
      formikStep1.setFieldValue("fatherName", basicDetails?.fatherName);
      formikStep1.setFieldValue("motherName", basicDetails?.motherName);
      formikStep1.setFieldValue("dob", new Date(basicDetails?.dateOfBirth));
      formikStep1.setFieldValue("gender", basicDetails?.gender);
      formikStep1.setFieldValue("relationToHead", basicDetails?.relationToHead);
      formikStep1.setFieldValue("bloodGroup", basicDetails?.bloodGroup);
      formikStep1.setFieldValue("occupation", basicDetails?.occupation);
      formikStep1.setFieldValue("remark", basicDetails?.remark);
      formikStep1.setFieldValue("willingToDonate", basicDetails?.donateBlood);
      formikStep1.setFieldValue("headOfFamily", basicDetails?.familyHead);
      formikStep1.setFieldValue("marriageStatus", basicDetails?.marriageStatus);
      formikStep2.setFieldValue("phone", contactDetails?.phNumber);
      formikStep2.setFieldValue("email", contactDetails?.emailAddress);
      formikStep2.setFieldValue(
        "whatsappNumber",
        contactDetails?.whatsAppNumber
      );
      setStatus(basicDetails?.active);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
        setCurrentState(1);
      }}
      size="xl"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Update Member Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
            <span
              style={{ marginLeft: -54 }}
              className="fs-18 text-primary fw-bold"
            >
              Updating...
            </span>
          </div>
        ) : !updateMemberPermission ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <NotAuthenticated />
          </div>
        ) : (
          <div
            id="wizard1"
            className="wizard-demo wizard clearfix"
            role="application"
          >
            <div className="steps clearfix">
              <ul role="tablist">
                <li
                  role="tab"
                  className={
                    currentState === 1
                      ? "current first"
                      : currentState > 1
                      ? "done first"
                      : "first"
                  }
                  aria-disabled="false"
                  aria-selected="true"
                >
                  <a id="wizard1-t-0" href="#" aria-controls="wizard1-p-0">
                    <span className="number">1</span>
                    <span className="title">Step 1</span>
                  </a>
                </li>
                <li
                  role="tab"
                  className={
                    currentState === 2
                      ? "current second"
                      : currentState > 2
                      ? "done second"
                      : "second"
                  }
                  aria-disabled="true"
                  aria-selected="false"
                >
                  <a id="wizard1-t-1" href="#" aria-controls="wizard1-p-1">
                    <span className="number">2</span>{" "}
                    <span className="title">Step 2</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="content clearfix">
              {currentState === 1 && (
                <div>
                  <h3 id="wizard1-h-0" tabIndex="-1" className="title current">
                    Member Personal Information
                  </h3>

                  <p className="mb-0">Please enter the below details</p>
                  <hr className="hr" />
                  <div
                    sm={12}
                    className="justify-content-end align-items-end d-flex"
                  >
                    <Form.Check
                      type="switch"
                      checked={status}
                      label="Active?"
                      onChange={(e) => {
                        setStatus(e.target.checked);
                      }}
                    />
                  </div>
                  <EditFamilyMemberFormStep1 formik={formikStep1} />
                </div>
              )}

              {currentState === 2 && (
                <div>
                  <h3 id="wizard1-h-0" tabIndex="-1" className="title current">
                    Contact Information
                  </h3>

                  <p className="mb-0">Please enter the below details</p>
                  <hr className="hr" />
                  <AddFamilyFormStep3 formik={formikStep2} />
                </div>
              )}
            </div>
          </div>
        )}
      </Modal.Body>
      {updateMemberPermission && !loading && (
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              if (!(currentState <= 1)) {
                setCurrentState((prevValue) => prevValue - 1);
              }
            }}
          >
            Previous
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (currentState === 1) {
                formikStep1.handleSubmit();
              } else if (currentState === 2) {
                formikStep2.handleSubmit();
              }
            }}
          >
            {currentState === 2 ? "Update" : "Next"}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(EditFamilyMemberModal);
