import ReactDOMServer from "react-dom/server";
import React, { useEffect, useState } from "react";
import { Button, Col, Offcanvas, ProgressBar, Row } from "react-bootstrap";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { ThreeCircles } from "react-loader-spinner";
import EditHolyConfirmationDetailsModal from "../../modals/holy-confirmation/EditHolyConfirmationDetailsModal";
import HolyConfirmationCertificatePdf from "../../components/sacrament-certificates/HolyConfirmationCertificatePdf";
import { useSelector } from "react-redux";
import { BlobProvider } from "@react-pdf/renderer";
import EditExternalHolyConfirmationDetailsModal from "../../modals/holy-confirmation/EditExternalHolyConfirmationDetailsModal";

const ConfirmationInfoOffset = ({
  show,
  setShow,
  confirmationId,
  commonAPIRequest,
  basicDetails,
  memberId,
  holyCommunionDetails,
  getFamilyMemberDetails,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentHolyConfirmation, setCurrentHolyConfirmation] = useState();
  const [editConfirmationModal, setEditConfirmationModal] = useState(false);
  const [editExternalConfirmationModal, setEditExternalConfirmationModal] =
    useState(false);
  const { churchDetails } = useSelector((state) => state?.church);

  const callGetHolyConfirmationDetails = () => {
    let serviceParams = {
      api: `${API.HOLY_CONFIRMATION_DETAILS}?confirmationId=${confirmationId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setCurrentHolyConfirmation(result?.payload);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (show) {
      callGetHolyConfirmationDetails();
    }
  }, [show]);

  return (
    <Offcanvas show={show} onHide={() => setShow(false)} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          Holy Confirmation Details <br />
          <p className="fw-bold m-0 text-primary">
            {currentHolyConfirmation?.referenceNum}
          </p>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <hr className="hr m-0" />
      <Offcanvas.Body>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ThreeCircles
              height="40"
              width="40"
              color="#303033"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        ) : (
          <Row>
            <Col className="my-1 d-flex justify-content-end" sm={12}>
              {!currentHolyConfirmation?.extConfirmation && (
                <BlobProvider
                  document={
                    <HolyConfirmationCertificatePdf
                      details={currentHolyConfirmation}
                      churchDetails={churchDetails}
                    />
                  }
                >
                  {({ blob, url, loading, error }) => {
                    // Handle loading state
                    if (loading) {
                      return <button disabled>Loading...</button>;
                    }
                    // Handle error state
                    if (error) {
                      return <p>Error generating PDF</p>;
                    }
                    // Function to open PDF in a new tab
                    const openPdf = () => {
                      const pdfWindow = window.open();
                      pdfWindow.location.href = url;
                    };
                    return (
                      <Button className="mx-3" onClick={openPdf}>
                        Generate PDF{" "}
                      </Button>
                    );
                  }}
                </BlobProvider>
              )}

              <Button
                onClick={() =>
                  currentHolyConfirmation?.extConfirmation
                    ? setEditExternalConfirmationModal(true)
                    : setEditConfirmationModal(true)
                }
              >
                Edit
              </Button>
            </Col>
            <hr />

            {currentHolyConfirmation?.extConfirmation ? (
              <Row>
                <Col sm={6}>
                  <div className="my-2">
                    <p className="mb-0">
                      <strong>First Name</strong>
                    </p>
                    <p
                      style={{ fontSize: 18, fontWeight: "lighter" }}
                      className="mb-0"
                    >
                      {currentHolyConfirmation?.firstName}
                    </p>
                  </div>
                </Col>
                {currentHolyConfirmation?.middleName && (
                  <Col sm={6}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Middle Name</strong>
                      </p>
                      <p
                        style={{ fontSize: 18, fontWeight: "lighter" }}
                        className="mb-0"
                      >
                        {currentHolyConfirmation?.middleName}
                      </p>
                    </div>
                  </Col>
                )}
                <Col sm={6}>
                  <div className="my-2">
                    <p className="mb-0">
                      <strong>Last Name</strong>
                    </p>
                    <p
                      style={{ fontSize: 18, fontWeight: "lighter" }}
                      className="mb-0"
                    >
                      {currentHolyConfirmation?.lastName}
                    </p>
                  </div>
                </Col>

                <ProgressBar now="100" className="h-1 my-2" />

                <Col sm={6}>
                  <div className="my-2">
                    <p className="mb-0">
                      <strong>Holy Confirmation Date</strong>
                    </p>
                    <p
                      style={{ fontSize: 18, fontWeight: "lighter" }}
                      className="mb-0"
                    >
                      {new Date(
                        currentHolyConfirmation?.confirmationDate
                      ).toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </p>
                  </div>
                </Col>

                <Col sm={6}>
                  <div className="my-2">
                    <p className="mb-0">
                      <strong>Holy Communion Date</strong>
                    </p>
                    <p
                      style={{ fontSize: 18, fontWeight: "lighter" }}
                      className="mb-0"
                    >
                      {new Date(
                        currentHolyConfirmation?.holyCommunionDate
                      ).toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </p>
                  </div>
                </Col>

                <Col sm={6}>
                  <div className="my-2">
                    <p className="mb-0">
                      <strong>Holy Confirmation Church</strong>
                    </p>
                    <p
                      style={{ fontSize: 18, fontWeight: "lighter" }}
                      className="mb-0"
                    >
                      {currentHolyConfirmation?.extConfirmationChurch}
                    </p>
                  </div>
                </Col>

                <Col sm={6}>
                  <div className="my-2">
                    <p className="mb-0">
                      <strong>Holy Confirmation Church City</strong>
                    </p>
                    <p
                      style={{ fontSize: 18, fontWeight: "lighter" }}
                      className="mb-0"
                    >
                      {currentHolyConfirmation?.extConfirmationCity}
                    </p>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col sm={6}>
                  <div className="my-2">
                    <p className="mb-0">
                      <strong>First Name</strong>
                    </p>
                    <p
                      style={{ fontSize: 18, fontWeight: "lighter" }}
                      className="mb-0"
                    >
                      {currentHolyConfirmation?.firstName}
                    </p>
                  </div>
                </Col>
                {currentHolyConfirmation?.middleName && (
                  <Col sm={6}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Middle Name</strong>
                      </p>
                      <p
                        style={{ fontSize: 18, fontWeight: "lighter" }}
                        className="mb-0"
                      >
                        {currentHolyConfirmation?.middleName}
                      </p>
                    </div>
                  </Col>
                )}
                <Col sm={6}>
                  <div className="my-2">
                    <p className="mb-0">
                      <strong>Last Name</strong>
                    </p>
                    <p
                      style={{ fontSize: 18, fontWeight: "lighter" }}
                      className="mb-0"
                    >
                      {currentHolyConfirmation?.lastName}
                    </p>
                  </div>
                </Col>

                <ProgressBar now="100" className="h-1 my-2" />

                <Col sm={6}>
                  <div className="my-2">
                    <p className="mb-0">
                      <strong>Holy Confirmation Date</strong>
                    </p>
                    <p
                      style={{ fontSize: 18, fontWeight: "lighter" }}
                      className="mb-0"
                    >
                      {new Date(
                        currentHolyConfirmation?.confirmationDate
                      ).toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </p>
                  </div>
                </Col>

                <Col sm={6}>
                  <div className="my-2">
                    <p className="mb-0">
                      <strong>Holy Communion Date</strong>
                    </p>
                    <p
                      style={{ fontSize: 18, fontWeight: "lighter" }}
                      className="mb-0"
                    >
                      {new Date(
                        currentHolyConfirmation?.holyCommunionDate
                      ).toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </p>
                  </div>
                </Col>

                <Col sm={6}>
                  <div className="my-2">
                    <p className="mb-0">
                      <strong>Baptism Date</strong>
                    </p>
                    <p
                      style={{ fontSize: 18, fontWeight: "lighter" }}
                      className="mb-0"
                    >
                      {new Date(
                        currentHolyConfirmation?.baptismDate
                      ).toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="my-2">
                    <p className="mb-0">
                      <strong>Date Of Birth</strong>
                    </p>
                    <p
                      style={{ fontSize: 18, fontWeight: "lighter" }}
                      className="mb-0"
                    >
                      {new Date(
                        currentHolyConfirmation?.dob
                      ).toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </p>
                  </div>
                </Col>

                <ProgressBar now="100" className="h-1 my-2" />

                <Col sm={6}>
                  <div className="my-2">
                    <p className="mb-0">
                      <strong>Father's Name</strong>
                    </p>
                    <p
                      style={{ fontSize: 18, fontWeight: "lighter" }}
                      className="mb-0"
                    >
                      {currentHolyConfirmation?.fatherName}
                    </p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="my-2">
                    <p className="mb-0">
                      <strong>Mothers's Name</strong>
                    </p>
                    <p
                      style={{ fontSize: 18, fontWeight: "lighter" }}
                      className="mb-0"
                    >
                      {currentHolyConfirmation?.motherName}
                    </p>
                  </div>
                </Col>

                <ProgressBar now="100" className="h-1 my-2" />

                <Col sm={12}>
                  <div className="my-2">
                    <p className="mb-0">
                      <strong>Mobile Number</strong>
                    </p>
                    <p
                      style={{ fontSize: 18, fontWeight: "lighter" }}
                      className="mb-0"
                    >
                      {currentHolyConfirmation?.mobileNum}
                    </p>
                  </div>
                </Col>

                <Col sm={12}>
                  <div className="my-2">
                    <p className="mb-0">
                      <strong>Address</strong>
                    </p>
                    <p
                      style={{ fontSize: 18, fontWeight: "lighter" }}
                      className="mb-0"
                    >
                      {currentHolyConfirmation?.communicantAddress}
                    </p>
                  </div>
                </Col>
              </Row>
            )}
          </Row>
        )}
      </Offcanvas.Body>
      <EditHolyConfirmationDetailsModal
        show={editConfirmationModal}
        setShow={setEditConfirmationModal}
        basicDetails={basicDetails}
        memberId={memberId}
        currentHolyConfirmation={currentHolyConfirmation}
        holyCommunionDetails={holyCommunionDetails}
        callGetHolyConfirmationDetails={callGetHolyConfirmationDetails}
        closeOffset={() => setShow(false)}
        getFamilyMemberDetails={getFamilyMemberDetails}
      />
      <EditExternalHolyConfirmationDetailsModal
        show={editExternalConfirmationModal}
        setShow={setEditExternalConfirmationModal}
        basicDetails={basicDetails}
        memberId={memberId}
        currentHolyConfirmation={currentHolyConfirmation}
        callGetHolyConfirmationDetails={callGetHolyConfirmationDetails}
        holyCommunionDetails={holyCommunionDetails}
        closeOffset={() => setShow(false)}
        getFamilyMemberDetails={getFamilyMemberDetails}
      />
    </Offcanvas>
  );
};

export default withAPIRequest(ConfirmationInfoOffset);
