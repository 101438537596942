import React from "react";

import Analytics from "../dashboard/Analytics";
import Families from "../dashboard/Families";
import SearchMembers from "../dashboard/SearchMembers";
import Certificates from "../dashboard/Certificates";
import Letters from "../dashboard/Letters";
import Zones from "../dashboard/Zones";
import MemberDetails from "../dashboard/MemberDetails";
import FamilyDetails from "../dashboard/FamilyDetails";
import ZoneFamillies from "../dashboard/zones/ZoneFamillies";
import BaptismList from "../dashboard/certificate/BaptismList";
import HolyCommunionList from "../dashboard/certificate/HolyCommunionList";
import ConfirmationList from "../dashboard/certificate/ConfirmationList";
import MarriageList from "../dashboard/certificate/MarriageList";
import SubZonesFamilies from "../dashboard/zones/SubZonesFamilies";
import AnnulmentList from "../dashboard/certificate/AnnulmentList";
import Feeds from "../dashboard/Feeds";
import CsfList from "../dashboard/financials/CsfList";
import DonationsList from "../dashboard/financials/DonationsList";
import ExpenseList from "../dashboard/financials/ExpenseList";
import DioceseAnalytics from "../diocese-dashboard/DioceseAnalytics";
import DioceseFeeds from "../diocese-dashboard/DioceseFeeds";
import DioceseParishDirectory from "../diocese-dashboard/DioceseParishDirectory";
import DiocesePriestDirectory from "../diocese-dashboard/DiocesePriestDirectory";
import ParishDetails from "../diocese-dashboard/parish/ParishDetails";
import DioceseCollectionList from "../dashboard/financials/DioceseCollectionList";
import NotAuthenticated from "../pages/NotAuthenticated";

const protectedRoutes = [
  { path: "dashboard", element: <Analytics /> },
  { path: "feeds", element: <Feeds /> },
  { path: "families", element: <Families /> },
  { path: "search-members", element: <SearchMembers /> },
  { path: "zones", element: <Zones /> },
  { path: "certificates", element: <Certificates /> },
  { path: "letters", element: <Letters /> },
  { path: "member-details", element: <MemberDetails /> },
  { path: "family-details", element: <FamilyDetails /> },
  { path: "zones-families", element: <ZoneFamillies /> },
  { path: "sub-zones-families", element: <SubZonesFamilies /> },
  { path: "baptism-certificates", element: <BaptismList /> },
  { path: "holy-communion-certificates", element: <HolyCommunionList /> },
  { path: "holy-confirmation-certificates", element: <ConfirmationList /> },
  { path: "marriage-certificates", element: <MarriageList /> },
  { path: "annulment-certificates", element: <AnnulmentList /> },
  { path: "csf", element: <CsfList /> },
  { path: "donations", element: <DonationsList /> },
  { path: "expenses", element: <ExpenseList /> },
  { path: "diocese-donation", element: <DioceseCollectionList /> },
  { path: "no-auth", element: <NotAuthenticated /> },
];

export const dioceseProtectedRoutes = [
  { path: "dashboard", element: <DioceseAnalytics /> },
  { path: "feeds", element: <DioceseFeeds /> },
  { path: "parishes-directory", element: <DioceseParishDirectory /> },
  { path: "priests-directory", element: <DiocesePriestDirectory /> },
  { path: "parish-details", element: <ParishDetails /> },
];

export default protectedRoutes;
