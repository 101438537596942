import React, { useEffect, useState } from "react";
import { Accordion, Badge, Button, Card } from "react-bootstrap";
import { API, AXIOS_METHOD_TYPES } from "../constants/api";
import { withAPIRequest } from "../HOC/withAPIRequest";
import { useDispatch, useSelector } from "react-redux";
import { FamilyAction } from "../store/slices/FamilySlice";
import EditFamilyBasicDetailsModal from "../modals/families/EditFamilyBasicDetailsModal";

const BasicFamilyDetails = ({ familyId, active, commonAPIRequest }) => {
  const [loading, setLoading] = useState(false);
  const [editFamilyModalVisible, setEditFamilyModalVisible] = useState(false);
  const dispatch = useDispatch();
  const { basicDetails } = useSelector((state) => state.family);
  const [zoneName, setZoneName] = useState();
  const [subZoneId, setSubZoneId] = useState();

  // basic family details start
  const callGetFamilyDetails = () => {
    let serviceParams = {
      api: `${API.GET_FAMILY_DETAILS}?familyId=${familyId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          // setZoneName(result?.payload?.zoneName);
          // setSubZoneId(result?.payload?.subZoneId);
          dispatch(
            FamilyAction.setCurrentBasicFamilyDetails({
              zoneName: result?.payload?.zoneName,
              subZoneId: result?.payload?.subZoneId,
              ...result?.payload?.familyDetail,
            })
          );
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (familyId && active) {
      if (!basicDetails) {
        callGetFamilyDetails();
      }
    }
  }, [active, familyId]);

  // basic family details end
  return (
    <div>
      {/* <p>
        We've created a colored variant style based on the primary brand color.
      </p> */}
      <Card className="card-basic-details">
        <Card.Body>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div>
              <h5 id="section5" className="main-subtitle">
                Basic Family Details
              </h5>
              {basicDetails?.active ? (
                <Badge className="p-2 fw-bold" bg="success">
                  Active
                </Badge>
              ) : (
                <Badge className="p-2 fw-bold" bg="danger">
                  Inactive
                </Badge>
              )}
            </div>

            <div>
              <Button onClick={() => setEditFamilyModalVisible(true)}>
                Edit
              </Button>
            </div>
          </div>
          <Accordion defaultActiveKey="0" className="accordion-primary">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Important Dates</Accordion.Header>
              <hr className="hr m-0" />
              <Accordion.Body>
                <div className="my-2">
                  <p className="mb-0">
                    <strong>Date Of Registration</strong>
                  </p>
                  <p className="mb-0 fs-16 text-primary">
                    {new Date(
                      basicDetails?.dateOfRegistration
                    ).toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Parish</Accordion.Header>
              <hr className="hr m-0" />
              <Accordion.Body>
                <div className="my-2">
                  <p className="mb-0">
                    <strong>Home Parish</strong>
                  </p>
                  <p className="mb-0 fs-16 text-primary">
                    {basicDetails?.homeParish ? basicDetails?.homeParish : "-"}
                  </p>
                </div>
                <div className="my-2">
                  <p className="mb-0">
                    <strong>Last Parish</strong>
                  </p>
                  <p className="mb-0 fs-16 text-primary">
                    {basicDetails?.lastParish ? basicDetails?.lastParish : "-"}
                  </p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Other</Accordion.Header>
              <hr className="hr m-0" />
              <Accordion.Body>
                <div className="my-2">
                  <p className="mb-0">
                    <strong>Monthly CSF Contribution</strong>
                  </p>
                  <p className="mb-0 fs-16 text-primary">
                    ₹{" "}
                    {basicDetails?.monthlySupportFund
                      ? basicDetails?.monthlySupportFund
                      : "-"}
                  </p>
                </div>
                <div className="my-2">
                  <p className="mb-0">
                    <strong>Address</strong>
                  </p>
                  <p className="mb-0 fs-16 text-primary">
                    {basicDetails?.address?.addressLine1}
                  </p>
                </div>
                <div className="my-2">
                  <p className="mb-0">
                    <strong>BEC Unit</strong>
                  </p>
                  <p className="mb-0 fs-16 text-primary">
                    {basicDetails?.zoneName}
                  </p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card.Body>
        {/* <Card.Footer className="bg-light text-primary text-end">
          Last Updated by <span className="fw-bold">{basicDetails}</span> on
          <span className="fw-bold">12/09/2023</span>
        </Card.Footer> */}
      </Card>
      <EditFamilyBasicDetailsModal
        show={editFamilyModalVisible}
        setShow={setEditFamilyModalVisible}
        basicDetails={basicDetails}
        familyId={familyId}
        callGetFamilyDetails={callGetFamilyDetails}
      />
    </div>
  );
};

export default withAPIRequest(BasicFamilyDetails);
