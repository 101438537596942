import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { Button, Col, Modal, Row } from "react-bootstrap";
import TextInput from "../../components/form/TextInput";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import DateInput from "../../components/form/DateInput";
import { withAPIRequest } from "../../HOC/withAPIRequest";

const dateFormatter = (date) => {
  return new Date(date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

const PriestValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  designation: Yup.string().required("Required"),
  phNumber: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  dateOfBirth: Yup.date()
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
});

const EditPriestModal = ({
  show,
  setShow,
  commonAPIRequest,
  callGetAllPriests,
  priestId,
}) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      designation: "",
      phNumber: "",
      email: "",
      dateOfBirth: "",
    },
    validationSchema: PriestValidationSchema,
    onSubmit: (values) => {
      callEditPriestApi(values, new Date(values?.dateOfBirth));
    },
  });

  const getPriestDetail = () => {
    let serviceParams = {
      api: `${API.GET_PRIEST_DETAILS}?priestDetailId=${priestId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);

    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          //   setShow(false);
          const detail = result?.payload;
          if (detail) {
            formik.setFieldValue("firstName", detail?.firstName);
            formik.setFieldValue("middleName", detail?.middleName);
            formik.setFieldValue("lastName", detail?.lastName);
            formik.setFieldValue("dateOfBirth", new Date(detail?.dateOfBirth));
            formik.setFieldValue("phNumber", detail?.phNumber);
            formik.setFieldValue("email", detail?.email);
            formik.setFieldValue("designation", detail?.designation);
          }
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  const callEditPriestApi = (values) => {
    let serviceParams = {
      api: API.UPDATE_PRIEST_DETAILS,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {
        priestDetail: {
          priestDetailId: priestId,
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values?.lastName,
          designation: values?.designation,
          phNumber: values?.phNumber,
          email: values?.email,
          dateOfBirth: values?.dateOfBirth,
        },
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setShow(false);
          Swal.fire("Success", `Priest Info. Updated Successfully.`, "success");
          callGetAllPriests();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (show && priestId) {
      getPriestDetail();
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Update Priest Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <div>
            <Row>
              <Col sm="4">
                <TextInput
                  label={"Priest First Name"}
                  placeholder={"e.g. Joseph"}
                  id={"firstName"}
                  setFieldValue={formik.setFieldValue}
                  keyword="firstName"
                  value={formik.values.firstName}
                  error={formik.errors.firstName}
                  touched={formik.touched.firstName}
                />
              </Col>
              <Col sm="4">
                <TextInput
                  label={"Priest Middle Name"}
                  placeholder={"e.g. Alan"}
                  id={"middleName"}
                  setFieldValue={formik.setFieldValue}
                  keyword="middleName"
                  value={formik.values.middleName}
                  error={formik.errors.middleName}
                  touched={formik.touched.middleName}
                />
              </Col>
              <Col sm="4">
                <TextInput
                  label={"Priest Last Name"}
                  placeholder={"e.g. Gomes"}
                  id={"lastName"}
                  setFieldValue={formik.setFieldValue}
                  keyword="lastName"
                  value={formik.values.lastName}
                  error={formik.errors.lastName}
                  touched={formik.touched.lastName}
                />
              </Col>
              <Col sm="12">
                <DateInput
                  label={"Date Of Birth"}
                  id={"dateOfBirth"}
                  setFieldValue={formik.setFieldValue}
                  keyword="dateOfBirth"
                  value={formik.values.dateOfBirth}
                  error={formik.errors.dateOfBirth}
                  touched={formik.touched.dateOfBirth}
                />
              </Col>
              <Col sm="6">
                <TextInput
                  label={"Contact Number"}
                  placeholder={"e.g. 9898989898"}
                  id={"phNumber"}
                  setFieldValue={formik.setFieldValue}
                  keyword="phNumber"
                  value={formik.values.phNumber}
                  error={formik.errors.phNumber}
                  touched={formik.touched.phNumber}
                />
              </Col>
              <Col sm="6">
                <TextInput
                  label={"Email Address"}
                  placeholder={"e.g. joseph@gmail.com"}
                  id={"email"}
                  setFieldValue={formik.setFieldValue}
                  keyword="email"
                  value={formik.values.email}
                  error={formik.errors.email}
                  touched={formik.touched.email}
                  disabled={true}
                />
              </Col>
              <Col sm="12">
                <TextInput
                  label={"Designation"}
                  placeholder={"e.g. Media Incharge"}
                  id={"designation"}
                  setFieldValue={formik.setFieldValue}
                  keyword="designation"
                  value={formik.values.designation}
                  error={formik.errors.designation}
                  touched={formik.touched.designation}
                />
              </Col>
            </Row>
          </div>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button onClick={formik.handleSubmit} variant="primary">
            Update
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(EditPriestModal);
