import * as Yup from "yup";

const alphabetsOnly = /^[-a-zA-Z][-a-zA-Z\.\'\(\) ]*$/;

const dateFormatter = (date) => {
  return new Date(date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

export const ExtMarriageStep1ValidationSchema = Yup.object().shape({
  confirmationDateForValidation: Yup.date()
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  marriageDate: Yup.date()
    .min(
      Yup.ref("confirmationDateForValidation"),
      "Marriage date can't be before Confirmation Date"
    )
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  // extMarriageChurch: Yup.string().required("Required"),
  // extMarriageCity: Yup.string().required("Required"),
});

export const ExtMarriageStep2ValidationSchema = Yup.object().shape({
  groomFirstName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  groomLastName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
});

export const ExtMarriageStep3ValidationSchema = Yup.object().shape({
  brideFirstName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  brideLastName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
});

export const MarriageFormStep1ValidationSchema = Yup.object().shape({
  confirmationDateForValidation: Yup.date()
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  marriageDate: Yup.date()
    .min(
      Yup.ref("confirmationDateForValidation"),
      "Marriage date can't be before Confirmation Date"
    )
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  marriagePlace: Yup.string().required("Required"),

  firstBannesDate: Yup.date()
    .required("Required")
    .max(
      Yup.ref("marriageDate"),
      "First Banns date can't be afetr Marriage Date"
    ),
  secondBannesDate: Yup.date()
    .required("Required")
    .min(
      Yup.ref("firstBannesDate"),
      "Second Banns Date can't be before First Banns Date"
    )
    .max(
      Yup.ref("marriageDate"),
      "Second Banns date can't be afetr Marriage Date"
    ),
  thirdBannesDate: Yup.date()
    .required("Required")
    .min(
      Yup.ref("secondBannesDate"),
      "Third Banns Date can't be before Second Banns Date"
    )
    .max(
      Yup.ref("marriageDate"),
      "Third Banns date can't be afetr Marriage Date"
    ),
  firstWitnessFirstName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  firstWitnessLastName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  firstWitnessDomicile: Yup.string().required("Required"),
  // .matches(alphabetsOnly, "Only alphabets are allowed."),
  secondWitnessFirstName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  secondWitnessLastName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  secondWitnessDomicile: Yup.string().required("Required"),
  // .matches(alphabetsOnly, "Only alphabets are allowed."),
  ministerName: Yup.string().required("Required"),
});

export const OutsiderMarriageFormStep1ValidationSchema = Yup.object().shape({
  marriageDate: Yup.date()
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  marriagePlace: Yup.string().required("Required"),

  firstBannesDate: Yup.date()
    .required("Required")
    .max(
      Yup.ref("marriageDate"),
      "First Banns date can't be afetr Marriage Date"
    ),
  secondBannesDate: Yup.date()
    .required("Required")
    .min(
      Yup.ref("firstBannesDate"),
      "Second Banns Date can't be before First Banns Date"
    )
    .max(
      Yup.ref("marriageDate"),
      "Second Banns date can't be afetr Marriage Date"
    ),
  thirdBannesDate: Yup.date()
    .required("Required")
    .min(
      Yup.ref("secondBannesDate"),
      "Third Banns Date can't be before Second Banns Date"
    )
    .max(
      Yup.ref("marriageDate"),
      "Third Banns date can't be afetr Marriage Date"
    ),
  firstWitnessFirstName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  firstWitnessLastName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  firstWitnessDomicile: Yup.string().required("Required"),
  // .matches(alphabetsOnly, "Only alphabets are allowed."),
  secondWitnessFirstName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  secondWitnessLastName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  secondWitnessDomicile: Yup.string().required("Required"),
  // .matches(alphabetsOnly, "Only alphabets are allowed."),
  ministerName: Yup.string().required("Required"),
});

export const MarriageFormStep2ValidationSchema = Yup.object().shape({
  groomFirstName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  groomLastName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  groomFatherName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  groomMotherName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  groomDob: Yup.date()
    .required("Required")
    .max(
      new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
      `Should be atleast 18 years or older.`
    ),

  groomNationality: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  groomDomicile: Yup.string().required("Required"),
  // .matches(alphabetsOnly, "Only alphabets are allowed."),
  groomProfession: Yup.string().required("Required"),
  groomMarriageStatus: Yup.string().required("Required"),
});

export const MarriageFormStep3ValidationSchema = Yup.object().shape({
  brideFirstName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  brideLastName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  brideFatherName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  brideMotherName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  brideDob: Yup.date()
    .required("Required")
    .max(
      new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
      `Should be atleast 18 years or older.`
    ),
  brideNationality: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  brideDomicile: Yup.string().required("Required"),
  // .matches(alphabetsOnly, "Only alphabets are allowed."),
  brideProfession: Yup.string().required("Required"),
  brideMarriageStatus: Yup.string().required("Required"),
});
