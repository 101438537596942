import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ExtHolyConfirmationValidationSchema } from "../../validations/holy-confirmation";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import DateInput from "../../components/form/DateInput";
import TextInput from "../../components/form/TextInput";
import { withAPIRequest } from "../../HOC/withAPIRequest";

function capitalizeText(text) {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

const EditExternalHolyConfirmationDetailsModal = ({
  show,
  setShow,
  basicDetails,
  memberId,
  commonAPIRequest,
  currentHolyConfirmation,
  holyCommunionDetails,
  getFamilyMemberDetails,
  closeOffset,
}) => {
  const [loading, setLoading] = useState(false);
  const { churchDetails } = useSelector((state) => state.church);

  const formik = useFormik({
    initialValues: {
      baptismDate: "",
      holyCommunionDate: "",
      holyConfirmationDate: "",
      holyConfirmationChurchName: "",
      holyConfirmationChurchCity: "",
      holyCommunionForValidationDate: "",
      holyConfirmationDateNotSure: false,
    },
    validationSchema: ExtHolyConfirmationValidationSchema,
    onSubmit: (values) => {
      Swal.fire({
        title: "<strong>Are you sure?</strong>",
        icon: "warning",
        html: `
        <dl>
          <dt>Date Of Holy Communion</dt>
          <dd>${new Date(values?.holyCommunionDate).toLocaleDateString(
            "en-GB",
            {
              month: "long",
              day: "numeric",
              year: "numeric",
            }
          )}</dd>
          <dt>Date Of Holy Confirmation </dt>
          <dd>${new Date(values?.holyConfirmationDate).toLocaleDateString(
            "en-GB",
            {
              month: "long",
              day: "numeric",
              year: "numeric",
            }
          )}</dd>
          <dt>Holy Confirmation Church Name</dt>
          <dd>${
            values?.holyConfirmationChurchName
              ? values?.holyConfirmationChurchName
              : "Not Sure"
          }</dd>
           <dt>Holy Confirmation Church City</dt>
           <dd>${
             values?.holyConfirmationChurchCity
               ? values?.holyConfirmationChurchCity
               : "Not Sure"
           }</dd>
        </dl>`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: `Yes, Sure!`,
        confirmButtonAriaLabel: "Yes, Sure!",
        cancelButtonText: `No`,
        cancelButtonAriaLabel: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          callUpdateHolyConfirmationDetailsApi(values);
        }
      });
    },
  });

  // function to call API for Add New Holy Confirmation start

  const callUpdateHolyConfirmationDetailsApi = () => {
    let serviceParams = {
      api: API.EXTERNAL_HOLY_CONFIRMATION_API,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {
        confirmationDetail: {
          confirmationId: currentHolyConfirmation?.confirmationId,
          firstName: capitalizeText(basicDetails?.firstName.trim()),
          middleName: capitalizeText(basicDetails?.middleName.trim()),
          lastName: capitalizeText(basicDetails?.lastName.trim()),
          gender: basicDetails?.gender,
          holyCommunionDate: formik.values.holyCommunionDate,
          extConfirmation: true,
          extConfirmationChurch: capitalizeText(
            formik.values.holyConfirmationChurchName.trim()
          ),
          extConfirmationCity: capitalizeText(
            formik.values.holyConfirmationChurchCity.trim()
          ),
          baptismDate: formik.values.baptismDate,
          confirmationDate: formik.values.holyConfirmationDate,
          confirmationDateAccurate: !formik.values.holyConfirmationDateNotSure,
        },
        memberId: memberId,
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire(
            "Success",
            `Holy Confirmation Created Successfully.`,
            "success"
          );
          getFamilyMemberDetails();
          closeOffset();
          setShow(false);
          formik.resetForm();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Add New Holy Confirmation end

  useEffect(() => {
    if (show) {
      formik.setFieldValue(
        "holyCommunionDate",
        new Date(holyCommunionDetails?.holyCommunionDate)
      );

      formik.setFieldValue(
        "holyCommunionForValidationDate",
        new Date(holyCommunionDetails?.holyCommunionDate)
      );

      formik.setFieldValue(
        "holyConfirmationDate",
        new Date(currentHolyConfirmation?.confirmationDate)
      );

      formik.setFieldValue(
        "holyConfirmationChurchName",
        currentHolyConfirmation?.extConfirmationChurch
      );
      formik.setFieldValue(
        "holyConfirmationChurchCity",
        currentHolyConfirmation?.extConfirmationCity
      );
      formik.setFieldValue(
        "holyConfirmationDateNotSure",
        !currentHolyConfirmation?.confirmationDateAccurate
      );
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Edit Holy Confirmation Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <Row>
            <p className="text-muted">
              As{" "}
              <span className="fw-bold text-primary">
                {basicDetails?.firstName} {basicDetails?.middleName}{" "}
                {basicDetails?.lastName}{" "}
              </span>{" "}
              holy confirmation was done
              <span className="fw-bold text-primary text-decoration-underline">
                {" "}
                outside of {churchDetails?.churchName}.
              </span>{" "}
              <br /> Please Enter these basic holy confirmation details for the
              records.
            </p>
            <Col sm={12}>
              <DateInput
                label={"Holy Communion Date"}
                setFieldValue={formik.setFieldValue}
                keyword={"holyCommunionDate"}
                value={formik.values.holyCommunionDate}
                error={formik.errors.holyCommunionDate}
                touched={formik.touched.holyCommunionDate}
                disabled={
                  holyCommunionDetails?.holyCommunionDate ? true : false
                }
              />
            </Col>
            <Col sm={12}>
              <DateInput
                label={"Holy Confirmation Date"}
                setFieldValue={formik.setFieldValue}
                keyword={"holyConfirmationDate"}
                value={formik.values.holyConfirmationDate}
                error={formik.errors.holyConfirmationDate}
                touched={formik.touched.holyConfirmationDate}
              />
            </Col>

            <Col className="mt-2" sm={12}>
              <Form.Check
                type="checkbox"
                label="Not sure if holy confirmation date is correct"
                onChange={(e) => {
                  formik.setFieldValue(
                    "holyConfirmationDateNotSure",
                    e.target.checked
                  );
                }}
                checked={formik.values.holyConfirmationDateNotSure}
              />
            </Col>

            <Col sm={6}>
              <TextInput
                label={"Holy Confirmation Church Name"}
                placeholder={"e.g. St. Mary's Church"}
                setFieldValue={formik.setFieldValue}
                keyword={"holyConfirmationChurchName"}
                value={formik.values.holyConfirmationChurchName}
                error={formik.errors.holyConfirmationChurchName}
                touched={formik.touched.holyConfirmationChurchName}
              />
            </Col>
            <Col sm={6}>
              <TextInput
                label={"Holy Confirmation Church City"}
                placeholder={"e.g. Noida"}
                setFieldValue={formik.setFieldValue}
                keyword={"holyConfirmationChurchCity"}
                value={formik.values.holyConfirmationChurchCity}
                error={formik.errors.holyConfirmationChurchCity}
                touched={formik.touched.holyConfirmationChurchCity}
              />
            </Col>
          </Row>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={formik.handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(EditExternalHolyConfirmationDetailsModal);
