import React, { useEffect, useRef, useState } from "react";
import { Badge, Card, Nav } from "react-bootstrap";
import Avatar from "../../components/Avatar";
import { Link } from "react-router-dom";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import noSearchAnimation from "../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import Lottie from "lottie-react";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import BirthdayFilterModal from "../../modals/members/BirthdayFilterModal";
import { useReactToPrint } from "react-to-print";

function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

function isSameDateAsToday(inputDate) {
  const today = new Date();

  return (
    inputDate.getFullYear() === today.getFullYear() &&
    inputDate.getMonth() === today.getMonth() &&
    inputDate.getDate() === today.getDate()
  );
}

const BirthdaySection = ({ commonAPIRequest, birthdays }) => {
  // const [loading, setLoading] = useState(false);
  // const [birthdays, setBirthdays] = useState([]);
  const [birthdayModal, setBirthdayModal] = useState(false);
  const contentRef = useRef(null);
  const reactToPrintFn = useReactToPrint({
    contentRef: contentRef,
    pageStyle: `
      @page {
        margin: 40px;
      }
      body {
        margin: 40px;
        padding: 50px;
      }
    `,
  });

  // call api for get all birthdays

  // const getAllBirthdaysApi = () => {
  //   let serviceParams = {
  //     api: `${API.GET_BIRTHDAY}?startDate=${
  //       new Date().toISOString().split("T")[0]
  //     }&endDate=${
  //       new Date(new Date().setDate(new Date().getDate() + 7))
  //         .toISOString()
  //         .split("T")[0]
  //     }`,
  //     method: AXIOS_METHOD_TYPES.GET,
  //     data: {},
  //   };
  //   setLoading(true);
  //   commonAPIRequest(serviceParams, async (result) => {
  //     if (result) {
  //       setLoading(false);
  //       try {
  //         setBirthdays(result?.payload?.birthdayDetailList);
  //       } catch (error) {
  //         console.log("error", error);
  //       }
  //     } else {
  //       setLoading(false);
  //     }
  //   });
  // };

  // call api for get all birthdays

  // useEffect(() => {
  //   getAllBirthdaysApi();
  // }, []);

  return (
    <Card className="card-one ">
      <Card.Header>
        <Card.Title as="h6">{birthdays?.length} Birthday's Today</Card.Title>
        <Nav className="nav-icon nav-icon-sm ms-auto">
          <Nav.Link onClick={reactToPrintFn} href="" className="nav-link me-1">
            <i className="ri-printer-line"></i>
          </Nav.Link>
          <Nav.Link
            onClick={() => setBirthdayModal(true)}
            href=""
            className="nav-link"
          >
            <i className="ri-equalizer-line"></i>
          </Nav.Link>
          <Nav.Link href="" className="nav-link">
            <i className="ri-more-2-fill"></i>
          </Nav.Link>
        </Nav>
      </Card.Header>
      <Card.Body ref={contentRef} className="p-0">
        {false ? (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="text-center"
            >
              <Lottie
                style={{ width: 120, height: 120 }}
                animationData={loadingAnimation}
                loop={true}
              />
            </div>

            <p className="text-center">Loading...</p>
          </div>
        ) : (
          <ul className="people-group">
            {birthdays.length === 0 ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="text-center"
                >
                  <Lottie
                    style={{ width: 180, height: 180 }}
                    animationData={noSearchAnimation}
                    loop={true}
                  />
                </div>
                <h4 className="text-center fw-bold">No Birthday's Today </h4>
              </div>
            ) : (
              birthdays.map((user, index) => (
                <li className="people-item" key={user.memberId}>
                  <Avatar
                    initial={`${user?.firstName
                      .charAt(0)
                      .toUpperCase()} ${user?.lastName
                      .charAt(0)
                      .toUpperCase()}`}
                  />
                  <div className="people-body">
                    <h6>
                      {user?.firstName} {user?.lastName}{" "}
                      {isSameDateAsToday(new Date(user?.birthdayDate)) && (
                        <Badge
                          className="d-inline ms-2 text-white fw-bold"
                          bg="primary"
                          pill
                        >
                          {getAge(user?.dateOfBirth)} Years Old Now
                        </Badge>
                      )}
                    </h6>
                    <span>
                      {new Date(user.birthdayDate).toLocaleDateString("en-GB", {
                        year: "numeric",
                        day: "numeric",
                        month: "long",
                      })}
                    </span>
                  </div>
                  <Nav as="nav" className="nav-icon">
                    {/* <Nav.Link href="">
                      <i className="ri-user-star-line"></i>
                    </Nav.Link> */}
                    <Link
                      to={"/admin/member-details"}
                      state={{ memberId: user.memberId, back: true }}
                    >
                      <i className="ri-contacts-line"></i>
                    </Link>
                  </Nav>
                </li>
              ))
            )}
          </ul>
        )}
      </Card.Body>
      <Card.Footer className="d-flex justify-content-center">
        {/* <Link href="" className="fs-sm">
          View more
        </Link> */}
      </Card.Footer>

      <BirthdayFilterModal setShow={setBirthdayModal} show={birthdayModal} />
    </Card>
  );
};

export default withAPIRequest(BirthdaySection);
