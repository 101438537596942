import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import AddFamilyFormStep1 from "../../forms/families/AddFamilyFormStep1";
import AddFamilyFormStep2 from "../../forms/families/AddFamilyFormStep2";
import AddFamilyFormStep3 from "../../forms/families/AddFamilyFormStep3";
import { useFormik } from "formik";
import {
  BasicContactValidationSchema,
  BasicFamilyDetailsValidation,
  FamilyHeadBasciDetailsValidation,
} from "../../validations/families";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import Swal from "sweetalert2";

function capitalizeText(text) {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

const AddFamilyModal = ({
  show,
  setShow,
  commonAPIRequest,
  getAllFamilyDetailsApi,
}) => {
  const [currentState, setCurrentState] = useState(1);
  const [loading, setLoading] = useState(false);

  const formikStep1 = useFormik({
    initialValues: {
      zone: "",
      subZone: "",
      dateOfRegistration: "",
      addressLine1: "",
      homeParish: "",
      lastParish: "",
      monthlyDonation: "",
    },
    validationSchema: BasicFamilyDetailsValidation,
    onSubmit: (values) => {
      if (!(currentState >= 3)) {
        setCurrentState((prevValue) => prevValue + 1);
      }
    },
  });

  const formikStep2 = useFormik({
    initialValues: {
      headOfFamily: true,
      firstName: "",
      lastName: "",
      middleName: "",
      baptismName: "",
      dob: "",
      gender: "",
      fatherName: "",
      motherName: "",
      occupation: "",
      bloodGroup: "",
      willingToDonate: false,
      marriageStatus: "",
      remark: "",
    },
    validationSchema: FamilyHeadBasciDetailsValidation,
    onSubmit: (values) => {
      if (!(currentState >= 3)) {
        setCurrentState((prevValue) => prevValue + 1);
      }
    },
  });

  const formikStep3 = useFormik({
    initialValues: {
      phone: "",
      email: "",
      whatsappNumber: "",
    },
    validationSchema: BasicContactValidationSchema,
    onSubmit: (values) => {
      callAddFamily();
    },
  });

  // function to call API for Add New Family start

  const callAddFamily = (value) => {
    let serviceParams = {
      api: API.CREATE_FAMILY,
      method: AXIOS_METHOD_TYPES.POST,
      data: {
        churchMemberDetails: [
          {
            basicDetail: {
              firstName: capitalizeText(formikStep2.values.firstName.trim()),
              middleName: capitalizeText(formikStep2.values.middleName.trim()),
              lastName: capitalizeText(formikStep2.values.lastName.trim()),
              baptismName: capitalizeText(
                formikStep2.values.baptismName.trim()
              ),
              dateOfBirth: new Date(formikStep2.values.dob),
              fatherName: capitalizeText(formikStep2.values.fatherName.trim()),
              motherName: capitalizeText(formikStep2.values.motherName.trim()),
              bloodGroup: formikStep2.values.bloodGroup,
              donateBlood: formikStep2.values.willingToDonate,
              occupation: capitalizeText(formikStep2.values.occupation.trim()),
              familyHead: true,
              gender: formikStep2.values.gender,
              relationToHead: null,
              marriageStatus: formikStep1.values?.marriageStatus,
              remark: formikStep2.values.remark,
            },
            churchFamilyDetail: {
              subZoneId: formikStep1.values.subZone,
              familyId: null,
              dateOfRegistration: new Date(
                formikStep1.values.dateOfRegistration
              ),
              address: {
                addressLine1: capitalizeText(
                  formikStep1.values.addressLine1.trim()
                ),
                block: null,
                sector: null,
                city: "",
                state: "",
                pinCode: "",
              },
              homeParish: capitalizeText(formikStep1.values.homeParish.trim()),
              lastParish: capitalizeText(formikStep1.values.lastParish.trim()),
              monthlyDonation: formikStep1.values.monthlyDonation,
              active: true,
            },
            contactDetail: {
              phNumber: formikStep3.values.phone.trim(),
              emailAddress: formikStep3.values.email.trim(),
              whatsAppNumber: formikStep3.values.whatsappNumber.trim(),
            },
          },
        ],
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire("Success", `Family Created Successfully.`, "success");
          getAllFamilyDetailsApi();
          setShow(false);
          formikStep1.resetForm();
          formikStep2.resetForm();
          formikStep3.resetForm();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Add New Family end

  // useEffect(() => {
  //   if (show) {
  //     formikStep1.setFieldValue("dateOfRegistration", new Date());
  //   }
  // }, [show]);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        if (currentState === 1) {
          formikStep1.handleSubmit();
        } else if (currentState === 2) {
          formikStep2.handleSubmit();
        } else if (currentState === 3) {
          formikStep3.handleSubmit();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [currentState]);

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
        setCurrentState(1);
        formikStep1.resetForm();
        formikStep2.resetForm();
        formikStep3.resetForm();
      }}
      size="xl"
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Add New Family
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <div
            id="wizard1"
            className="wizard-demo wizard clearfix"
            role="application"
          >
            <div className="steps clearfix">
              <ul role="tablist">
                <li
                  role="tab"
                  className={
                    currentState === 1
                      ? "current first"
                      : currentState > 1
                      ? "done first"
                      : "first"
                  }
                  aria-disabled="false"
                  aria-selected="true"
                >
                  <a id="wizard1-t-0" href="#" aria-controls="wizard1-p-0">
                    <span className="number">1</span>
                    <span className="title">Step 1</span>
                  </a>
                </li>
                <li
                  role="tab"
                  className={
                    currentState === 2
                      ? "current second"
                      : currentState > 2
                      ? "done second"
                      : "second"
                  }
                  aria-disabled="true"
                  aria-selected="false"
                >
                  <a id="wizard1-t-1" href="#" aria-controls="wizard1-p-1">
                    <span className="number">2</span>{" "}
                    <span className="title">Step 2</span>
                  </a>
                </li>
                <li
                  role="tab"
                  className={
                    currentState === 3
                      ? "current last"
                      : currentState > 3
                      ? "done last"
                      : "last"
                  }
                  aria-disabled="true"
                >
                  <a id="wizard1-t-2" href="#" aria-controls="wizard1-p-2">
                    <span className="number">3</span>{" "}
                    <span className="title">Step 3</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="content clearfix">
              {currentState === 1 && (
                <div>
                  <h3 id="wizard1-h-0" tabIndex="-1" className="title current">
                    Family Information
                  </h3>

                  <p className="mb-0">
                    Enter the correct details below to add new family.
                  </p>

                  <hr className="hr" />
                  <AddFamilyFormStep1 formik={formikStep1} />
                </div>
              )}
              {currentState === 2 && (
                <div>
                  <h3 id="wizard1-h-0" tabIndex="-1" className="title current">
                    Head Of Family Personal Information
                  </h3>

                  <p className="mb-0">
                    Enter the correct details below for the head of family.
                  </p>
                  <hr className="hr" />
                  <AddFamilyFormStep2 formik={formikStep2} />
                </div>
              )}

              {currentState === 3 && (
                <div>
                  <h3 id="wizard1-h-0" tabIndex="-1" className="title current">
                    Contact Information
                  </h3>

                  <p className="mb-0">
                    Enter the contact details below for the head of family.
                  </p>
                  <hr className="hr" />
                  <AddFamilyFormStep3 formik={formikStep3} />
                </div>
              )}
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            if (!(currentState <= 1)) {
              setCurrentState((prevValue) => prevValue - 1);
            }
          }}
        >
          Previous
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            if (currentState === 1) {
              formikStep1.handleSubmit();
            } else if (currentState === 2) {
              formikStep2.handleSubmit();
            } else if (currentState === 3) {
              formikStep3.handleSubmit();
            }
          }}
        >
          {currentState === 3 ? "Save" : "Next"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default withAPIRequest(AddFamilyModal);
