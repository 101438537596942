import React, { useState } from "react";
import { Button, Card, Col, Row, Badge, Spinner } from "react-bootstrap";
import Lottie from "lottie-react";
import noSearchAnimation from "../../assets/lottie/../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import ChooseHolyCommunionTypeModal from "../../modals/holy-communion/ChooseHolyCommunionTypeModal";
import AddExternalHolyCommunionDetailsModal from "../../modals/holy-communion/AddExternalHolyCommunionDetailsModal";
import AddHolyCommunionDetailsModal from "../../modals/holy-communion/AddHolyCommunionDetailsModal";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import HolyCommunionInfoOffset from "../../offset-canvas/holy-communion/HolyCommunionInfoOffset";
import GenerateHolyCommunionCertificateModal from "../../modals/holy-communion/GenerateHolyCommunionCertificateModal";
import HolyCommunionLinkCertificateModal from "../../modals/holy-communion/HolyCommunionLinkCertificateModal";
import { useSelector } from "react-redux";
import LinkOutsiderHolyCommunion from "../../modals/holy-communion/LinkOutsiderHolyCommunion";

const HolyCommunionDetails = ({
  holyCommunionDetails,
  basicDetails,
  baptismDetails,
  memberId,
  getFamilyMemberDetails,
  contactDetails,
  familyDetails,
  commonAPIRequest,
  deathDetails,
}) => {
  const [loading, setLoading] = useState(false);
  const [holyCommunionDetailModalVisible, setHolyCommunionDetailModalVisible] =
    useState(false);

  const [extHolyCommunionModalVisible, setExtHolyCommunionModalVisible] =
    useState(false);

  const [hoyCommunionModalVisible, setHoyCommunionModalVisible] =
    useState(false);
  const [certPdf, setCertPdf] = useState();
  const [holyCommunionOffsetModalVisible, setHolyCommunionOffsetModalVisible] =
    useState(false);
  const [loading2, setLoading2] = useState(false);
  const [currentHolyCommunion, setCurrentHolyCommunion] = useState();
  const [generateHolyCommunionModal, setGenerateHolyCommunionModal] =
    useState(false);
  const [holyCommunionCertModal, setHolyCommunionCertModal] = useState(false);
  const { churchDetails } = useSelector((state) => state?.church);
  const [linkCertificateModal, setLinkCertificateModal] = useState(false);

  // call generate pdf certificate api for holy communion start

  const callGetHolyCommunionCertificateApi = () => {
    let serviceParams = {
      api: `${API.GENERATE_HOLY_COMMUNION_CERTIFICATE}?holyCommunionId=${holyCommunionDetails?.holyCommunionId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setCertPdf(result?.payload);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // call generate pdf certificate api for holy communion end

  // call generate pdf certificate api for holy communion end

  const callGetHolyCommunionDetailsForLink = (id) => {
    let serviceParams = {
      api: `${API.HOLY_COMMUNION_DETAILS}?holyCommunionId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading2(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading2(false);
        try {
          // console.log("result", result.payload);
          setCurrentHolyCommunion(result?.payload);
          setHolyCommunionCertModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading2(false);
      }
    });
  };

  const callGetHolyCommunionDetailsForPreview = (id) => {
    let serviceParams = {
      api: `${API.HOLY_COMMUNION_DETAILS}?holyCommunionId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          // console.log("result", result.payload);
          setCurrentHolyCommunion(result?.payload);
          setGenerateHolyCommunionModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <div>
      {holyCommunionDetails ? (
        <Card className="card-post mt-4 ">
          <Card.Header>
            <h3 className="fw-bold text-primary">Holy Communion Information</h3>
            <Button onClick={() => setHolyCommunionOffsetModalVisible(true)}>
              <i className="ri-information-line"></i>
            </Button>
          </Card.Header>
          <hr className="hr m-0" />
          <Card.Body>
            <Row>
              <Col>
                <Row>
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Holy Communion Done?</strong>
                      </p>
                      <p className="mb-0">Yes</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Date Of Holy Communion</strong>
                      </p>
                      <div className="d-flex align-items-center justify-content-start">
                        <p className="mb-0">
                          {" "}
                          {new Date(
                            holyCommunionDetails?.holyCommunionDate
                          ).toLocaleDateString("en-GB", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                        </p>
                        {!holyCommunionDetails?.holyCommunionDateAccurate && (
                          <Badge
                            style={{ padding: 5 }}
                            className="ms-2 fw-bold"
                            bg="warning"
                          >
                            Uncertain
                          </Badge>
                        )}
                      </div>
                    </div>
                  </Col>
                  {!holyCommunionDetails?.extHolyCommunion && (
                    <Col sm={4}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>Holy Communion Certificate ID</strong>
                        </p>
                        <p className="mb-0">
                          {holyCommunionDetails?.referenceNum}
                        </p>
                      </div>
                    </Col>
                  )}
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>External Holy Communion</strong>
                      </p>
                      <p className="mb-0">
                        {holyCommunionDetails?.extHolyCommunion
                          ? holyCommunionDetails?.extHolyCommunionChurch ||
                            holyCommunionDetails?.extHolyCommunionCity
                            ? "Yes"
                            : "Not Sure"
                          : "No"}
                      </p>
                    </div>
                  </Col>
                  {holyCommunionDetails?.extHolyCommunion &&
                    holyCommunionDetails?.extHolyCommunionChurch && (
                      <Col sm={4}>
                        <div className="my-2">
                          <p className="mb-0">
                            <strong>External Holy Communion Church</strong>
                          </p>
                          <p className="mb-0">
                            {holyCommunionDetails?.extHolyCommunionChurch}
                          </p>
                        </div>
                      </Col>
                    )}
                  {holyCommunionDetails?.extHolyCommunion &&
                    holyCommunionDetails?.extHolyCommunionCity && (
                      <Col sm={4}>
                        <div className="my-2">
                          <p className="mb-0">
                            <strong>External Holy Communion City</strong>
                          </p>
                          <p className="mb-0">
                            {holyCommunionDetails?.extHolyCommunionCity}
                          </p>
                        </div>
                      </Col>
                    )}
                </Row>
                {!holyCommunionDetails?.extHolyCommunion && (
                  <div>
                    <hr className="hr" />
                    <div className="text-center m-4">
                      <Card.Title className="text-dark">
                        Generate Holy Communion Certificate for{" "}
                        {basicDetails?.firstName} {basicDetails?.middleName}{" "}
                        {basicDetails?.lastName}
                      </Card.Title>
                      <Card.Text>
                        Please click on Generate for generating holy communion
                        certificate copy for{" "}
                        <span className="fw-bold text-primary">
                          {basicDetails?.firstName} {basicDetails?.middleName}{" "}
                          {basicDetails?.lastName}
                        </span>
                      </Card.Text>
                      {/* {loading ? (
                        <div className="justify-content-center align-items-center d-flex">
                          <Lottie
                            style={{ width: 100, height: 100 }}
                            animationData={loadingAnimation}
                            loop={true}
                          />
                        </div>
                      ) : certPdf?.certName ? (
                        <Button
                          onClick={() => {
                            let pdfWindow = window.open("");
                            pdfWindow.document.write(
                              "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                                encodeURI(certPdf.base64) +
                                "'></iframe>"
                            );
                          }}
                          variant="success"
                        >
                          Print
                        </Button>
                      ) : (
                        <Button
                          onClick={callGetHolyCommunionCertificateApi}
                          variant="primary"
                        >
                          Generate
                        </Button>
                      )} */}

                      <div>
                        <Button
                          disabled={holyCommunionDetails?.extHolyCommunion}
                          variant="primary"
                          onClick={() => {
                            callGetHolyCommunionDetailsForLink(
                              holyCommunionDetails?.holyCommunionId
                            );
                          }}
                        >
                          {loading2 ? (
                            <span className="d-flex align-items-center">
                              <Spinner
                                className="me-2"
                                animation="border"
                                variant="light"
                                size="sm"
                              />
                              Generating...
                            </span>
                          ) : (
                            <div className="d-flex align-items-center justify-content-center">
                              <i className="ri-download-2-fill me-2"></i>
                              Generate Link
                            </div>
                          )}
                        </Button>
                        {/* <Button
                          variant="primary"
                          className="ms-2"
                          onClick={() => {
                            callGetHolyCommunionDetailsForPreview(
                              holyCommunionDetails?.holyCommunionId
                            );
                          }}
                        >
                          {loading ? (
                            <span className="d-flex align-items-center">
                              <Spinner
                                className="me-2"
                                animation="border"
                                variant="light"
                              />
                              Generating...
                            </span>
                          ) : (
                            <i className="ri-eye-line"></i>
                          )}
                        </Button> */}
                      </div>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Card.Body>
          {
            <Card.Footer className="bg-light text-primary text-end">
              Last Updated by{" "}
              <span className="fw-bold">{holyCommunionDetails?.updatedBy}</span>{" "}
              on
              <span className="fw-bold">
                {" "}
                {new Date(
                  holyCommunionDetails?.updateTmStmp
                ).toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </span>
            </Card.Footer>
          }
        </Card>
      ) : (
        <Card.Body className="text-center m-4">
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="text-center"
            >
              <Lottie
                style={{ width: 150, height: 150 }}
                animationData={noSearchAnimation}
                loop={true}
              />
            </div>
          </div>
          <Card.Title className="text-dark">
            No Holy Communion Details Found
          </Card.Title>
          <Card.Text>
            Please add holy communion details for{" "}
            <span className="fw-bold text-primary">
              {basicDetails?.firstName} {basicDetails?.middleName}{" "}
              {basicDetails?.lastName}
            </span>{" "}
            and generate certificate.
            <br />
            <span className="text-danger fw-bold">
              NOTE: *Baptism is required for adding holy communion details.
            </span>
          </Card.Text>
          <Button
            onClick={() => setHolyCommunionDetailModalVisible(true)}
            disabled={baptismDetails?.baptismId && !deathDetails ? false : true}
            variant="primary"
          >
            Add
          </Button>
          <Button
            className="ms-2"
            onClick={() => setLinkCertificateModal(true)}
            disabled={deathDetails ? true : false}
            variant="outline-primary"
          >
            Link
          </Button>
        </Card.Body>
      )}
      <ChooseHolyCommunionTypeModal
        show={holyCommunionDetailModalVisible}
        setShow={setHolyCommunionDetailModalVisible}
        setExtHolyCommunionModalVisible={setExtHolyCommunionModalVisible}
        setHoyCommunionModalVisible={setHoyCommunionModalVisible}
        basicDetails={basicDetails}
      />
      <AddExternalHolyCommunionDetailsModal
        show={extHolyCommunionModalVisible}
        setShow={setExtHolyCommunionModalVisible}
        basicDetails={basicDetails}
        getFamilyMemberDetails={getFamilyMemberDetails}
        memberId={memberId}
        baptismDetails={baptismDetails}
      />
      <AddHolyCommunionDetailsModal
        show={hoyCommunionModalVisible}
        setShow={setHoyCommunionModalVisible}
        basicDetails={basicDetails}
        getFamilyMemberDetails={getFamilyMemberDetails}
        memberId={memberId}
        baptismDetails={baptismDetails}
        contactDetails={contactDetails}
        familyDetails={familyDetails}
      />
      <HolyCommunionInfoOffset
        show={holyCommunionOffsetModalVisible}
        setShow={setHolyCommunionOffsetModalVisible}
        holyCommunionId={holyCommunionDetails?.holyCommunionId}
        basicDetails={basicDetails}
        memberId={memberId}
        baptismDetails={baptismDetails}
        getFamilyMemberDetails={getFamilyMemberDetails}
        contactDetails={contactDetails}
        familyDetails={familyDetails}
      />

      <GenerateHolyCommunionCertificateModal
        setShow={setGenerateHolyCommunionModal}
        show={generateHolyCommunionModal}
        holyCommunionId={holyCommunionDetails?.holyCommunionId}
        churchDetails={churchDetails}
        currentHolyCommunion={currentHolyCommunion}
      />
      <HolyCommunionLinkCertificateModal
        show={holyCommunionCertModal}
        setShow={setHolyCommunionCertModal}
        baptismId={holyCommunionDetails?.holyCommunionId}
        churchDetails={churchDetails}
        currentHolyCommunion={currentHolyCommunion}
      />
      <LinkOutsiderHolyCommunion
        setShow={setLinkCertificateModal}
        show={linkCertificateModal}
        basicDetails={basicDetails}
        memberId={memberId}
        getFamilyMemberDetails={getFamilyMemberDetails}
      />
    </div>
  );
};

export default withAPIRequest(HolyCommunionDetails);
