import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { HolyCommunionValidationSchema } from "../../validations/holy-communion";
import { useFormik } from "formik";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import TextArea from "../../components/form/TextArea";
import { Button, Col, Modal, Row, Form } from "react-bootstrap";
import TextInput from "../../components/form/TextInput";
import DateInput from "../../components/form/DateInput";
import SelectInput from "../../components/form/SelectInput";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import { withAPIRequest } from "../../HOC/withAPIRequest";

function capitalizeText(text) {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

const AddOutsiderHolyCommunionDetailsModal = ({
  show,
  setShow,
  commonAPIRequest,
  callGetAllHolyCommunion,
}) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      fatherName: "",
      motherName: "",
      dob: "",
      gender: "",
      dateOfBaptism: "",
      holyCommunionDate: "",
      address: "",
      mobileNum: "",
      extHolyCommunion: false,
      baptismDateForValidation: "",
      registerRefNumber: null,
      holyCommunionDateNotSure: false,
    },
    validationSchema: HolyCommunionValidationSchema,
    onSubmit: (values) => {
      Swal.fire({
        title: "<strong>Are you sure?</strong>",
        icon: "warning",
        html: `
        <dl>
          <dt>Date Of Baptism</dt>
          <dd>${new Date(formik.values?.dateOfBaptism).toLocaleDateString(
            "en-GB",
            {
              month: "long",
              day: "numeric",
              year: "numeric",
            }
          )}</dd>
          <dt>Date Of Holy Communion </dt>
          <dd>${new Date(values?.holyCommunionDate).toLocaleDateString(
            "en-GB",
            {
              month: "long",
              day: "numeric",
              year: "numeric",
            }
          )}</dd>
        </dl>`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: `Yes, Sure!`,
        confirmButtonAriaLabel: "Yes, Sure!",
        cancelButtonText: `No`,
        cancelButtonAriaLabel: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          callAddHolyCommunionDetailsApi(values);
        }
      });
    },
  });

  // function to call API for Add New Holy Communion start

  const callAddHolyCommunionDetailsApi = (value) => {
    let serviceParams = {
      api: API.EXTERNAL_HOLY_COMMUNION_API,
      method: AXIOS_METHOD_TYPES.POST,
      data: {
        holyCommunionDetail: {
          firstName: capitalizeText(formik.values?.firstName.trim()),
          middleName: capitalizeText(formik.values?.middleName.trim()),
          lastName: capitalizeText(formik.values?.lastName.trim()),
          baptismDate: new Date(formik.values.dateOfBaptism),
          dob: new Date(formik.values.dob),
          gender: formik.values?.gender,
          fatherName: capitalizeText(formik.values?.fatherName.trim()),
          motherName: capitalizeText(formik.values?.motherName.trim()),
          holyCommunionDate: new Date(formik.values.holyCommunionDate),
          address: formik.values?.address.trim(),
          mobileNum: formik.values?.mobileNum.trim(),
          extHolyCommunion: formik.values?.extHolyCommunion,
          regReferenceNum: formik.values?.registerRefNumber
            ? +formik.values?.registerRefNumber
            : null,
          holyCommunionDateAccurate: !formik.values.holyCommunionDateNotSure,
        },
        memberId: null,
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire(
            "Success",
            `Holy Communion Created Successfully.`,
            "success"
          );
          setShow(false);
          formik.resetForm();
          callGetAllHolyCommunion();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Add New Holy Communion end

  useEffect(() => {
    if (formik.values?.dateOfBaptism) {
      formik.setFieldValue(
        "baptismDateForValidation",
        new Date(
          new Date(formik.values?.dateOfBaptism).setDate(
            new Date(formik.values?.dateOfBaptism).getDate()
          )
        )
      );
    }
  }, [formik.values?.dateOfBaptism]);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="xl"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">
          Add Outsider's Holy Communion Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <Row className="g-3">
            <Col sm={4}>
              <TextInput
                label={"First Name"}
                placeholder={"Enter First Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"firstName"}
                value={formik.values.firstName}
                error={formik.errors.firstName}
                touched={formik.touched.firstName}
              />
            </Col>
            <Col sm={4}>
              <TextInput
                label={"Middle Name"}
                placeholder={"Enter Middle Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"middleName"}
                value={formik.values.middleName}
                error={formik.errors.middleName}
                touched={formik.touched.middleName}
              />
            </Col>
            <Col sm={4}>
              <TextInput
                label={"Last Name"}
                placeholder={"Enter Last Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"lastName"}
                value={formik.values.lastName}
                error={formik.errors.lastName}
                touched={formik.touched.lastName}
              />
            </Col>
            <Col sm={6}>
              <TextInput
                label={"Father's Name"}
                placeholder={"Enter Father's Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"fatherName"}
                value={formik.values.fatherName}
                error={formik.errors.fatherName}
                touched={formik.touched.fatherName}
              />
            </Col>
            <Col sm={6}>
              <TextInput
                label={"Mother's Name"}
                placeholder={"Enter Mother's Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"motherName"}
                value={formik.values.motherName}
                error={formik.errors.motherName}
                touched={formik.touched.motherName}
              />
            </Col>
            <Col sm={6}>
              <DateInput
                label={"Date Of Birth"}
                setFieldValue={formik.setFieldValue}
                keyword={"dob"}
                value={formik.values.dob}
                error={formik.errors.dob}
                touched={formik.touched.dob}
              />
            </Col>
            <Col sm={6}>
              <SelectInput
                label={"Gender"}
                selectOptions={[
                  { label: "Male", value: "Male" },
                  { label: "Female", value: "Female" },
                ]}
                setFieldValue={formik.setFieldValue}
                keyword={"gender"}
                value={formik.values.gender}
                error={formik.errors.gender}
                touched={formik.touched.gender}
              />
            </Col>
            <Col sm={12}>
              <DateInput
                label={"Date Of Baptism"}
                setFieldValue={formik.setFieldValue}
                keyword={"dateOfBaptism"}
                value={formik.values.dateOfBaptism}
                error={formik.errors.baptismDateForValidation}
                touched={formik.touched.baptismDateForValidation}
              />
            </Col>
            <Col sm={6}>
              <DateInput
                label={"Date Of Holy Communion"}
                setFieldValue={formik.setFieldValue}
                keyword={"holyCommunionDate"}
                value={formik.values.holyCommunionDate}
                error={formik.errors.holyCommunionDate}
                touched={formik.touched.holyCommunionDate}
              />
            </Col>

            <Col sm={6}>
              <TextInput
                label={"Mobile Number"}
                placeholder={"Enter Mobile Number"}
                setFieldValue={formik.setFieldValue}
                keyword={"mobileNum"}
                value={formik.values.mobileNum}
                error={formik.errors.mobileNum}
                touched={formik.touched.mobileNum}
              />
            </Col>

            <Col className="mt-2" sm={12}>
              <Form.Check
                type="checkbox"
                label="Not sure if holy communion date is correct"
                onChange={(e) => {
                  formik.setFieldValue(
                    "holyCommunionDateNotSure",
                    e.target.checked
                  );
                }}
                checked={formik.values.holyCommunionDateNotSure}
              />
            </Col>

            <Col>
              <TextArea
                label={"Residential Address"}
                placeholder={"Type Address Here..."}
                setFieldValue={formik.setFieldValue}
                keyword={"address"}
                value={formik.values.address}
                error={formik.errors.address}
                touched={formik.touched.address}
              />
            </Col>
            <Col sm={12}>
              <TextInput
                label={"Register Reference Number"}
                setFieldValue={formik.setFieldValue}
                keyword={"registerRefNumber"}
                value={formik.values.registerRefNumber}
                error={formik.errors.registerRefNumber}
                touched={formik.touched.registerRefNumber}
                type={"number"}
              />
            </Col>
          </Row>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={formik.handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(AddOutsiderHolyCommunionDetailsModal);
