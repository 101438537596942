import React, { useContext, useEffect, useState } from "react";
import Header from "../layouts/Header";
import {
  Badge,
  Button,
  Card,
  Col,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import Avatar from "../components/Avatar";

import img1 from "../assets/img/img1.jpg";
import { withAPIRequest } from "../HOC/withAPIRequest";
import { AXIOS_METHOD_TYPES, API } from "../constants/api";
import { useDispatch, useSelector } from "react-redux";
import { FamiliesAction } from "../store/slices/FamiliesSlice";
import CustomTooltip from "../components/CustomTooltip";
import { Link } from "react-router-dom";
import FamilyTablePlaceholder from "../components/placeholders/FamilyTablePlaceholder";
import Lottie from "lottie-react";
import noSearchAnimation from "../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import AddFamilyModal from "../modals/families/AddFamilyModal";
import { FamilyAction } from "../store/slices/FamilySlice";
import SearchFamilyModal from "../modals/families/SearchFamilyModal";
import familyImg from "../assets/img/family_delhi_diocese_theme.png";
import loadingAnimation from "../assets/lottie/loading_animation_delhi_theme.json";
import { AuthContext } from "react-oauth2-code-pkce";
import NotAuthenticated from "../pages/NotAuthenticated";

function getPaginationRange(totalPages, currentPage, rangeSize = 5) {
  let start = Math.max(currentPage - Math.floor(rangeSize / 2), 1);
  let end = start + rangeSize - 1;

  if (end > totalPages) {
    end = totalPages;
    start = Math.max(end - rangeSize + 1, 1);
  }

  const pages = [];
  for (let i = start; i <= end; i++) {
    pages.push(i);
  }

  return pages;
}

const Families = ({ commonAPIRequest }) => {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [loading, setLoading] = useState(false);
  const [addFamilyModalVisible, setAddFamilyModalVisible] = useState(false);
  const [searchFamilyModalVisible, setSearchFamilyModalVisible] =
    useState(false);

  const { churchDetails } = useSelector((state) => state?.church);

  const dispatch = useDispatch();
  const { families, totalCount, present, pageNumber } = useSelector(
    (state) => state.families
  );
  const [currentPageNumber, setCurrentPageNumber] = useState(pageNumber);
  const { tokenData } = useContext(AuthContext);
  const readFamilyPermission =
    tokenData?.permissions?.includes("READ_FAMILY_HEADS");

  // const deleteFamilyPermission = tokenData?.permissions?.includes(
  //   "DELETE_FAMILY_HEADS"
  // );

  const getAllFamilyDetailsApi = () => {
    let serviceParams = {
      api: `${API.GET_ALL_FAMILY}?pgNum=${pageNumber}&pgSize=10`,
      method: AXIOS_METHOD_TYPES.GET,
      data: null,
    };

    setLoading(true);

    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          dispatch(
            FamiliesAction.getFamilies(
              result.payload.headDetails ? result.payload.headDetails : []
            )
          );
          dispatch(FamiliesAction.getTotalCount(result?.payload.totalCount));
          setCurrentPageNumber(pageNumber);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (!present && readFamilyPermission) {
      getAllFamilyDetailsApi();
    }
  }, []);

  useEffect(() => {
    if (currentPageNumber !== pageNumber) {
      getAllFamilyDetailsApi();
    }
    dispatch(FamilyAction.clearFamilies());
  }, [pageNumber]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        {!readFamilyPermission ? (
          <div className="justify-content-center align-items-center d-flex">
            <NotAuthenticated />
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5 id="section3" className="main-subtitle m-0">
                  All Registered Families
                </h5>
                <p>
                  List of all the registered families of{" "}
                  <span className="fw-bold text-primary text-decoration-underline">
                    {churchDetails?.churchName}
                  </span>
                  .
                </p>
              </div>
              <Button
                variant="light"
                onClick={() => {
                  getAllFamilyDetailsApi();
                  // getAllCsfsApi();
                }}
                style={{ height: 40 }}
              >
                <i className="ri-refresh-line"></i>
              </Button>
            </div>

            <div className="d-lg-none">
              <hr />
              {/*  */}
              <Row className="g-2 g-lg-3 mb-5">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <div style={{ width: "80%" }}>
                    <p className="fw-bold text-primary m-0">Families Summary</p>
                    <p style={{ fontSize: 12 }}>
                      Overview of Family Profiles and Participation
                    </p>
                  </div>

                  <Button
                    onClick={() => setSearchFamilyModalVisible(true)}
                    variant="primary"
                    className="me-3"
                  >
                    <i className="ri-search-line"></i>
                  </Button>
                </div>

                {!loading &&
                  families.map((data, i) => (
                    <Col
                      className="mb-4 shadow"
                      key={data.memberId}
                      xs="12"
                      md="6"
                      xl="4"
                    >
                      <Card className="card-folder p-2">
                        <Card.Body className="mt-1">
                          <img
                            style={{ maxWidth: 48 }}
                            src={familyImg}
                            className="img-fluid"
                            alt="..."
                          />
                          <div className="card-content ms-2">
                            <div className="d-flex">
                              <h6 className="fw-bold text-primary">
                                {data.middleName
                                  ? `${data.firstName} ${data.middleName} ${data.lastName}`
                                  : `${data.firstName} ${data.lastName}`}
                              </h6>
                              <span>
                                {data?.active ? (
                                  <Badge
                                    className="ms-1 text-white fw-bold"
                                    bg="success"
                                    pill
                                  >
                                    Active
                                  </Badge>
                                ) : (
                                  <Badge
                                    className="ms-1 text-white fw-bold"
                                    bg="danger"
                                    pill
                                  >
                                    Inactive
                                  </Badge>
                                )}
                              </span>
                            </div>

                            <span>
                              {data.emailAddress ? data.emailAddress : "-"}
                            </span>
                            <span>{data?.phNumber ? data?.phNumber : "-"}</span>
                          </div>
                        </Card.Body>
                        <Link
                          onClick={() => {
                            dispatch(FamilyAction.setFamilyId(data.familyId));
                            localStorage.setItem(
                              "currentFamilyId",
                              data?.familyId
                            );
                            localStorage.setItem(
                              "currentChurchFamilyId",
                              data?.churchFamilyId
                            );
                            localStorage.setItem(
                              "currentFamilyStatus",
                              data?.active
                            );
                          }}
                          state={{
                            familyId: data.familyId,
                            churchFamilyId: data?.churchFamilyId,
                            active: data?.active,
                          }}
                          to={"/admin/family-details"}
                        >
                          <Button
                            style={{
                              position: "absolute",
                              zIndex: 2,
                              right: 20,
                              top: 32,
                            }}
                          >
                            <i className="ri-arrow-right-line"></i>
                          </Button>
                        </Link>
                        <p
                          style={{
                            position: "absolute",
                            right: 20,
                            fontSize: 10,
                          }}
                          className="fw-bold text-primary"
                        >
                          {data?.churchFamilyId}
                        </p>
                        {/* <hr /> */}
                        <Card.Footer className=" pt-2 ps-2 bg-light">
                          <div className="d-flex align-items-center justify-content-between">
                            <div>
                              <span>Registration Date: </span>
                              <p className="text-primary fw-bold">
                                {data.dateOfRegistration
                                  ? new Date(
                                      data.dateOfRegistration
                                    ).toLocaleDateString()
                                  : "N/A"}
                              </p>
                            </div>
                            <div>
                              <span>Zone Name: </span>
                              <p className="text-primary fw-bold">
                                {data.zoneName}
                              </p>
                            </div>
                          </div>
                        </Card.Footer>
                      </Card>
                    </Col>
                  ))}
              </Row>

              {!loading && families.length === 0 && (
                <div className="bg-light">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#fff",
                    }}
                    className="text-center"
                  >
                    <Lottie
                      style={{ width: 180, height: 180 }}
                      animationData={noSearchAnimation}
                      loop={true}
                    />
                  </div>
                  <h3
                    className="text-center py-3 fw-bold"
                    style={{ backgroundColor: "#fff" }}
                  >
                    No Families Found
                  </h3>
                </div>
              )}

              {loading && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className="text-center"
                  >
                    <Lottie
                      style={{ width: 160, height: 160 }}
                      animationData={loadingAnimation}
                      loop={true}
                    />
                    <p
                      style={{ marginLeft: -40 }}
                      className="fw-bold text-center fs-18 mb-0"
                    >
                      Loading...
                    </p>
                  </div>
                </div>
              )}

              {Math.ceil(totalCount / 10) > 1 && (
                <Pagination className="pagination-space pagination-filled mb-0 mt-3 d-flex justify-content-end">
                  <Pagination.Item
                    onClick={() => {
                      dispatch(FamiliesAction.setPageNumber(1));
                    }}
                  >
                    First
                  </Pagination.Item>

                  {getPaginationRange(
                    Math.ceil(totalCount / 10),
                    pageNumber,
                    3
                  ).map((n, i) => {
                    return (
                      <Pagination.Item
                        key={i}
                        onClick={() => {
                          dispatch(FamiliesAction.setPageNumber(n));
                        }}
                        active={pageNumber === n}
                      >
                        {n}
                      </Pagination.Item>
                    );
                  })}

                  <Pagination.Item
                    onClick={() => {
                      dispatch(
                        FamiliesAction.setPageNumber(Math.ceil(totalCount / 10))
                      );
                    }}
                  >
                    Last
                  </Pagination.Item>
                </Pagination>
              )}
            </div>
            {/*  */}
            <Card.Footer className="d-none d-lg-inline">
              <pre>
                <Card className="card-families">
                  <Card.Body>
                    <div
                      className="mb-3"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        onClick={() => setSearchFamilyModalVisible(true)}
                        variant="primary"
                        className="me-3"
                      >
                        Search Family
                      </Button>
                      <Button
                        onClick={() => setAddFamilyModalVisible(true)}
                        variant="primary"
                      >
                        Add Family
                      </Button>
                    </div>
                    <Table hover className="mb-0">
                      <thead>
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">Name</th>
                          <th scope="col">Zone</th>
                          <th scope="col">Phone</th>
                          <th scope="col">Date Of Registration</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      {loading && <FamilyTablePlaceholder />}
                      <tbody>
                        {families.length !== 0 &&
                          !loading &&
                          families.map((data, i) => (
                            <tr key={data.memberId}>
                              <th scope="row">
                                {(pageNumber - 1) * 10 + (i + 1)}
                              </th>
                              <td className="d-flex align-items-center">
                                <img
                                  style={{ maxWidth: 48 }}
                                  src={familyImg}
                                  className="img-fluid"
                                  alt="..."
                                />
                                {/* <Avatar
                              size="lg"
                              img={familyImg}
                              // initial={`${member.firstName
                              //   .charAt(0)
                              //   .toUpperCase()} ${member.lastName
                              //   .charAt(0)
                              //   .toUpperCase()}`}
                            /> */}
                                {/* <Avatar
                              size="lg"
                              initial={`${data.firstName
                                .charAt(0)
                                .toUpperCase()} ${data.lastName
                                .charAt(0)
                                .toUpperCase()}`}
                            /> */}
                                <div className="ms-3">
                                  <div className="fw-bold">
                                    {data.middleName
                                      ? `${data.firstName} ${data.middleName} ${data.lastName}`
                                      : `${data.firstName} ${data.lastName}`}
                                    {data?.active ? (
                                      <Badge className="ms-1" bg="success" pill>
                                        Active
                                      </Badge>
                                    ) : (
                                      <Badge className="ms-1" bg="danger" pill>
                                        Inactive
                                      </Badge>
                                    )}
                                  </div>
                                  <div>
                                    {data.emailAddress
                                      ? data.emailAddress
                                      : "-"}
                                  </div>
                                  <div className="fw-bolder text-primary">
                                    {data?.churchFamilyId}
                                  </div>
                                </div>
                              </td>
                              <td>{data.zoneName}</td>
                              <td>{data?.phNumber}</td>
                              <td>
                                {data.dateOfRegistration
                                  ? new Date(
                                      data.dateOfRegistration
                                    ).toLocaleDateString()
                                  : "N/A"}
                              </td>
                              <td>
                                <Link
                                  onClick={() => {
                                    dispatch(
                                      FamilyAction.setFamilyId(data.familyId)
                                    );
                                    localStorage.setItem(
                                      "currentFamilyId",
                                      data?.familyId
                                    );
                                    localStorage.setItem(
                                      "currentChurchFamilyId",
                                      data?.churchFamilyId
                                    );
                                    localStorage.setItem(
                                      "currentFamilyStatus",
                                      data?.active
                                    );
                                  }}
                                  state={{
                                    familyId: data.familyId,
                                    churchFamilyId: data?.churchFamilyId,
                                    active: data?.active,
                                  }}
                                  to={"/admin/family-details"}
                                >
                                  <CustomTooltip
                                    label={"View Family Details"}
                                    placement={"top"}
                                  >
                                    <Button
                                      variant="primary"
                                      className="rounded-pill"
                                    >
                                      <i className="ri-user-fill"></i>
                                    </Button>
                                  </CustomTooltip>
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    {!loading && families.length === 0 && (
                      <div className="bg-light">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#fff",
                          }}
                          className="text-center"
                        >
                          <Lottie
                            style={{ width: 180, height: 180 }}
                            animationData={noSearchAnimation}
                            loop={true}
                          />
                        </div>
                        <h3
                          className="text-center py-3 fw-bold"
                          style={{ backgroundColor: "#fff" }}
                        >
                          No Families Found
                        </h3>
                      </div>
                    )}
                  </Card.Body>
                  <Card.Footer style={{ width: "100%" }}>
                    <pre>
                      {Math.ceil(totalCount / 10) > 1 && (
                        <Pagination className="pagination-space pagination-filled mb-0 mt-3 d-flex justify-content-end">
                          <Pagination.Item
                            onClick={() => {
                              dispatch(FamiliesAction.setPageNumber(1));
                            }}
                          >
                            First
                          </Pagination.Item>

                          {getPaginationRange(
                            Math.ceil(totalCount / 10),
                            pageNumber,
                            3
                          ).map((n, i) => {
                            return (
                              <Pagination.Item
                                key={i}
                                onClick={() => {
                                  dispatch(FamiliesAction.setPageNumber(n));
                                }}
                                active={pageNumber === n}
                              >
                                {n}
                              </Pagination.Item>
                            );
                          })}

                          <Pagination.Item
                            onClick={() => {
                              dispatch(
                                FamiliesAction.setPageNumber(
                                  Math.ceil(totalCount / 10)
                                )
                              );
                            }}
                          >
                            Last
                          </Pagination.Item>
                        </Pagination>
                      )}
                    </pre>
                  </Card.Footer>
                </Card>
              </pre>
            </Card.Footer>
          </>
        )}
      </div>
      <AddFamilyModal
        show={addFamilyModalVisible}
        setShow={setAddFamilyModalVisible}
        getAllFamilyDetailsApi={getAllFamilyDetailsApi}
      />
      <SearchFamilyModal
        show={searchFamilyModalVisible}
        setShow={setSearchFamilyModalVisible}
      />
    </React.Fragment>
  );
};

export default withAPIRequest(Families);
