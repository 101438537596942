import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import churchLogo from "../assets/img/logo-1.png";
import waterMark from "../assets/img/christ.png";
import delhidioceselogo from "../assets/img/delhi-diocese-logo-1.png";
import SelectLetterHeadModal from "../modals/letter/SelectLetterHeadModal";
// import letterheadTop from "../assets/img/letterhead-top-image.png";
// import letterheadBottom from "../assets/img/letterhead-bottom-image.png";

const MyTextEditor = ({ currentTemplate, churchDetails }) => {
  const [editorContent, setEditorContent] = useState(currentTemplate);
  const [openLetterheadModal, setOpenLetterheadModal] = useState(false);
  const [currentLetterHead, setCurrentLetterHead] = useState(`
        <div style='display:flex; align-items: center; justify-content: center;'>
          <img style='height: 120px; width: 120px;' src='${churchDetails?.logoUrl}' />

          <div style='width: 80%; margin-left: 8px;'>
            <div style='font-size: 24px; text-transform: uppercase; text-align: center; font-family: "Rozha One", serif; color: #344fae;'>Delhi Catholic  Archdiocese</div>
            <div style='font-size: 32px; text-transform: uppercase; text-align: center; font-family: "Rozha One", serif; color: #912E2D;line-height: 1'>${churchDetails?.churchName}</div>
             <div style='font-size: 14px; text-align: center;  font-family: "Rozha One", serif;'>${churchDetails?.churchAddress?.addressLine1}</div>
          </div>
          <img style='height: 120px; width: 120px;' src='${delhidioceselogo}' />
        </div>
          <hr />
        <br /> <br /> <br />`);

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  useEffect(() => {
    setEditorContent(currentTemplate);
    // base64FromUrl();
  }, [currentTemplate]);

  // const base64FromUrl = async () => {
  //   const base64 = await fetch(churchDetails?.logoUrl)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(blob);
  //       return new Promise((res) => {
  //         reader.onloadend = () => {
  //           res(reader.result);
  //         };
  //       });
  //     });
  //     setCurrentLogo(base64);
  // };

  const handlePrint = () => {
    // Get the content from the editor
    const editorContentToPrint = editorContent;

    // Custom letterhead content

    // Combine letterhead and editor content
    const combinedContent = currentLetterHead + editorContentToPrint;

    // Create a new window for printing
    const printWindow = window.open("", "_blank");

    // Write the combined content to the new window
    printWindow.document.write(`
      <html>
   
        <head>
          <title>Official Letter</title>
          <link rel="preconnect" href="https://fonts.googleapis.com">
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
          <link href="https://fonts.googleapis.com/css2?family=Elsie:wght@400;900&family=Rozha+One&display=swap" rel="stylesheet">
          <style>
            p {
              margin: 0px;
              padding: 0px;
            }
            .box {
              height: 14px;
              width: 14px;
              background-color: red;
            }
            @media print {
              body {
                margin: 80px; /* Adjust the margin as needed */
              }
            }
          </style>
        </head>
        <body>
          ${combinedContent}
        </body>
        
      </html>
    `);

    //    <img style="width: 100%; position: absolute; top: -50px; right: 0" src='${letterheadTop}' />
    //  <img style="width: 100%; position: absolute; bottom: 8px; right: 0" src='${letterheadBottom}' />

    // <img style="height: 500px; width: 500px; position: absolute; top: 50%; left: 50%;margin-top: -250px; margin-left: -250px; opacity:0.2;" src='${churchDetails?.watermarkUrl}' />

    // Close the document for printing
    // printWindow.document.close();

    // Trigger the print dialog

    setTimeout(() => {
      printWindow.print();
      // printWindow.close();
    }, 2);

    // Close the document for printing
    // printWindow.close();
  };

  return (
    <div>
      <Button onClick={() => setOpenLetterheadModal(true)}>
        Select Letterhead
      </Button>
      {/* <Button
        className="d-none d-lg-inline"
        variant="primary w-100  my-4"
        onClick={handlePrint}
      >
        Letter Head
      </Button> */}
      <ReactQuill
        style={{ width: "100%", height: "100%" }}
        value={editorContent}
        theme="snow"
        onChange={handleEditorChange}
        modules={{
          // toolbar: [
          //   [{ header: [1, 2, false] }],
          //   ["bold", "italic", "underline", "strike", "blockquote"],
          //   [{ list: "ordered" }, { list: "bullet" }],
          //   ["link", "image", "video"],
          //   ["clean"],
          // ],

          toolbar: [
            [{ font: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "sub" }, { script: "super" }],
            ["blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
            ["link", "image"],
            ["clean"],
          ],
        }}
        formats={[
          "header",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          "link",
          "image",
        ]}
      />
      <Button
        className="d-none d-lg-inline"
        variant="primary w-100  my-4"
        onClick={handlePrint}
      >
        Print
      </Button>

      <SelectLetterHeadModal
        setShow={setOpenLetterheadModal}
        show={openLetterheadModal}
        churchDetails={churchDetails}
        selectLetterhead={setCurrentLetterHead}
      />
    </div>
  );
};

export default MyTextEditor;
