import React from "react";
import { Col, Row } from "react-bootstrap";
import DateInput from "../../components/form/DateInput";
import TextInput from "../../components/form/TextInput";

const BaptismFormStep4 = ({ formik }) => {
  return (
    <Row className="g-3">
      <Col sm={12}>
        <DateInput
          label={"Date Of Baptism"}
          setFieldValue={formik.setFieldValue}
          keyword={"dateOfBaptismForValidation"}
          value={formik.values.dateOfBaptismForValidation}
          error={formik.errors.dateOfBaptismForValidation}
          touched={formik.touched.dateOfBaptismForValidation}
          disabled={true}
        />
      </Col>
      <Col sm={12}>
        <TextInput
          label={"Holy Communion"}
          setFieldValue={formik.setFieldValue}
          keyword={"holyCommunionRemark"}
          value={formik.values.holyCommunionRemark}
          error={formik.errors.holyCommunionRemark}
          touched={formik.touched.holyCommunionRemark}
        />
      </Col>

      <Col sm={12}>
        <TextInput
          label={"Holy Confirmation"}
          setFieldValue={formik.setFieldValue}
          keyword={"confirmationRemark"}
          value={formik.values.confirmationRemark}
          error={formik.errors.confirmationRemark}
          touched={formik.touched.confirmationRemark}
        />
      </Col>

      <Col sm={12}>
        <TextInput
          label={"Marriage"}
          setFieldValue={formik.setFieldValue}
          keyword={"marriageRemark"}
          value={formik.values.marriageRemark}
          error={formik.errors.marriageRemark}
          touched={formik.touched.marriageRemark}
        />
      </Col>
    </Row>
  );
};

export default BaptismFormStep4;
