import axios from "axios";
import React, { useContext } from "react";
import Swal from "sweetalert2";
import { AuthContext } from "react-oauth2-code-pkce";

export function withAPIRequest(Component) {
  const WithAPIRequest = (props) => {
    const { token, tokenData, logOut } = useContext(AuthContext);

    const commonAPIRequest = (
      { api, method, params, data, customHeaders, noToken },
      callback,
      hideErrorNotification
    ) => {
      // let user = JSON.parse(sessionStorage.getItem('user'))
      // let token = token ? token : null;

      let headers = {
        Authorization: token ? (noToken ? null : `Bearer ${token}`) : null,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        charset: "utf-8",
        "User-Id": sessionStorage.getItem("sub"),
        ...customHeaders,
      };

      let config = {
        method: method,
        url: api,
        headers,
        params,
        data,
      };

      params && (config.data = params);

      axios(config)
        .then((response) => {
          if (response.data.code == "200") {
            callback(response.data);
          } else if (response.data.code == "480") {
            Swal.fire("Error", response.data.message, "warning").then(() => {
              logOut();
            });
          } else if (response.data.code == "401") {
            Swal.fire("Error", response.data.message, "warning").then(() => {
              logOut();
            });
          } else if (response.data.code == "403") {
            Swal.fire({
              title: "Warning!",
              text: response.data.message,
              imageUrl: require("../assets/img/no-auth-delhi-alert.png"),
              imageWidth: 300,
              imageHeight: 250,
              imageAlt: "Custom image",
            }).then(() => {
              callback(null);
            });
          } else {
            Swal.fire("Error", response.data.message, "error");
            callback(null);
          }
        })
        .catch((error) => {
          Swal.fire(
            "Error",
            `Something went wrong. Please try again later.`,
            "error"
          );

          callback(null);
        });
    };

    return <Component {...props} commonAPIRequest={commonAPIRequest} />;
  };

  return WithAPIRequest;
}
