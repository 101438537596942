import React, { Component, useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import userAvatar from "../assets/img/img1.jpg";
import { AuthContext } from "react-oauth2-code-pkce";
import { dioceseDashboardMenu, dioceseMainMenu } from "../data/DioceseMenu";
import dioceselogo from "../assets/img/delhi-diocese-logo-1.png";
class DioceseSidebar extends Component {
  // toggleFooterMenu = (e) => {
  //   e.preventDefault();

  //   let parent = e.target.closest(".sidebar");
  //   parent.classList.toggle("footer-menu-show");
  // };

  render() {
    return (
      <div
        style={{ background: "#912E2D", height: "100%" }}
        className="sidebar"
      >
        <div className="b-4 sidebar-header">
          <img style={{ width: 100 }} src={dioceselogo} />
          <span
            style={{ fontSize: 24, lineHeight: 1 }}
            className="fw-bold text-white text-center"
          >
            Delhi Catholic Archdiocese
            <p className="mt-1" style={{ fontSize: 14 }}>
              Diocese App
            </p>
          </span>
        </div>
        <PerfectScrollbar
          className="sidebar-body"
          ref={(ref) => (this._scrollBarRef = ref)}
        >
          <SidebarMenu onUpdateSize={() => this._scrollBarRef.updateScroll()} />
        </PerfectScrollbar>
        {/* <div className="sidebar-footer">
          <div className="sidebar-footer-top">
            <div className="sidebar-footer-thumb">
              <img src={userAvatar} alt="" />
            </div>
            <div className="sidebar-footer-body">
              <h6>
                <Link to="../pages/profile.html">Shaira Diaz</Link>
              </h6>
              <p>Premium Member</p>
            </div>
            <Link
              onClick={this.toggleFooterMenu}
              to=""
              className="dropdown-link"
            >
              <i className="ri-arrow-down-s-line"></i>
            </Link>
          </div>
          <div className="sidebar-footer-menu">
            <nav className="nav">
              <Link to="">
                <i className="ri-edit-2-line"></i> Edit Profile
              </Link>
              <Link to="">
                <i className="ri-profile-line"></i> View Profile
              </Link>
            </nav>
            <hr />
            <nav className="nav">
              <Link to="">
                <i className="ri-question-line"></i> Help Center
              </Link>
              <Link to="">
                <i className="ri-lock-line"></i> Reset Password
              </Link>
              <Link to="">
                <i className="ri-user-settings-line"></i> Account Settings
              </Link>
              <Link to="">
                <i className="ri-logout-box-r-line"></i> Log Out
              </Link>
            </nav>
          </div>
        </div> */}
      </div>
    );
  }
}

const SidebarMenu = () => {
  const { logOut } = useContext(AuthContext);
  const [currentNav, setCurrentNav] = useState("Dashboard");

  function getLabelByLink(link) {
    switch (link) {
      case "/diocese/dashboard":
        return setCurrentNav("Dashboard");
      case "/diocese/feeds":
        return setCurrentNav("Feeds");
      case "/diocese/parishes-directory":
        return setCurrentNav("Parish Directory");
      case "/diocese/priests-directory":
        return setCurrentNav("Priest Directory");
      default:
        return setCurrentNav("");
    }
  }

  useEffect(() => {
    getLabelByLink(window.location.pathname);
  }, [window.location.pathname]);

  const populateMenu = (m) => {
    const menu = m.map((m, key) => {
      let sm;
      if (m.submenu) {
        sm = m.submenu.map((sm, key) => {
          return (
            <NavLink to={sm.link} className="nav-sub-link" key={key}>
              {sm.label}
            </NavLink>
          );
        });
      }

      return (
        <li
          style={
            currentNav === m.label
              ? { backgroundColor: "#fff", margin: 10, borderRadius: 5 }
              : {}
          }
          key={key}
          className="nav-item"
        >
          {!sm ? (
            <NavLink
              replace={m.replace}
              onClick={() => {
                if (m.clickType === "logout") {
                  sessionStorage.clear();
                  localStorage.clear();
                  logOut();
                }
              }}
              to={m.link}
              className="nav-link"
            >
              <i className={m.icon}></i> <span>{m.label}</span>
            </NavLink>
          ) : (
            <div onClick={toggleSubMenu} className="nav-link has-sub">
              <i className={m.icon}></i> <span>{m.label}</span>
            </div>
          )}
          {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
        </li>
      );
    });

    return <ul className="nav nav-sidebar">{menu}</ul>;
  };

  // Toggle menu group
  const toggleMenu = (e) => {
    e.preventDefault();

    let parent = e.target.closest(".nav-group");
    parent.classList.toggle("show");

    this?.props?.onUpdateSize();
  };

  // Toggle submenu while closing siblings' submenu
  const toggleSubMenu = (e) => {
    e.preventDefault();

    let parent = e.target.closest(".nav-item");
    let node = parent.parentNode.firstChild;

    while (node) {
      if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("show");
      node = node.nextElementSibling || node.nextSibling;
    }

    parent.classList.toggle("show");

    this?.props?.onUpdateSize();
  };

  return (
    <React.Fragment>
      <div className="nav-group show">
        <div className="nav-label" onClick={toggleMenu}>
          Dashboard
        </div>
        {populateMenu(dioceseDashboardMenu)}
      </div>
      <div className="nav-group show">
        <div className="nav-label" onClick={toggleMenu}>
          Main
        </div>
        {populateMenu(dioceseMainMenu)}
      </div>
      {/* <div className="nav-group show">
        <div className="nav-label" onClick={toggleMenu}>
          Financials
        </div>
        {populateMenu(financialsMenu)}
      </div>
      <div className="nav-group show">
        <div className="nav-label" onClick={toggleMenu}>
          Certificates and Letters
        </div>
        {populateMenu(certificatesMenu)}
      </div> */}
      {/* <div className="nav-group show">
        <div className="nav-label" onClick={toggleMenu}>
          Settings
        </div>
        {populateMenu(settingsMenu)}
      </div> */}
    </React.Fragment>
  );
};

window.addEventListener("click", function (e) {
  // Close sidebar footer menu when clicked outside of it
  let tar = e.target;
  let sidebar = document.querySelector(".sidebar");
  if (!tar.closest(".sidebar-footer") && sidebar) {
    sidebar.classList.remove("footer-menu-show");
  }

  // Hide sidebar offset when clicked outside of sidebar
  if (!tar.closest(".sidebar") && !tar.closest(".menu-link")) {
    document.querySelector("body").classList.remove("sidebar-show");
  }
});

window.addEventListener("load", function () {
  // let skinMode = localStorage.getItem("sidebar-skin");
  let skinMode = "prime";

  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-sidebar", skinMode);
  }
});

const mapStateToProps = (state) => ({
  counter: state.counter,
});

export default DioceseSidebar;
