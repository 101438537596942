import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import TextInput from "../../components/form/TextInput";
import SelectInput from "../../components/form/SelectInput";
import { useFormik } from "formik";
import { CSFValidationSchema } from "../../validations/families";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import Swal from "sweetalert2";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import DateInput from "../../components/form/DateInput";
import TextArea from "../../components/form/TextArea";
import { AuthContext } from "react-oauth2-code-pkce";
import NotAuthenticated from "../../pages/NotAuthenticated";

function totalMonths(startMonth, startYear, endMonth, endYear) {
  if (startYear > endYear || (startYear === endYear && startMonth > endMonth)) {
    throw new Error("Start date must be before end date");
  }

  return (endYear - startYear) * 12 + (endMonth - startMonth + 1);
}

function generateArrayOfYears() {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = currentYear - 10; year <= currentYear + 10; year++) {
    years.push({ label: year.toString(), value: year });
  }

  return years;
}

const monthArray = [
  { label: "JAN", value: 1 },
  { label: "FEB", value: 2 },
  { label: "MAR", value: 3 },
  { label: "APR", value: 4 },
  { label: "MAY", value: 5 },
  { label: "JUN", value: 6 },
  { label: "JUL", value: 7 },
  { label: "AUG", value: 8 },
  { label: "SEP", value: 9 },
  { label: "OCT", value: 10 },
  { label: "NOV", value: 11 },
  { label: "DEC", value: 12 },
];

const paymentModeArray = [
  { label: "UPI", value: "UPI" },
  { label: "CASH", value: "CASH" },
  { label: "CHEQUE", value: "CHEQUE" },
];

const AddCsfDetailsModal = ({
  show,
  setShow,
  commonAPIRequest,
  getAllDonationsApi,
  familyId,
  lastMonthDonated,
  lastYearDonated,
  getLastDonatedYearMonthApi,
}) => {
  const [loading, setLoading] = useState(false);

  const { tokenData } = useContext(AuthContext);

  const addCsfPermission = tokenData?.permissions?.includes("WRITE_CSF");

  const formik = useFormik({
    initialValues: {
      totalDonationAmount: "",
      startYear: "",
      startMonth: "",
      endYear: "",
      endMonth: "",
      familyId: "",
      paymentMode: "",
      paidOn: "",
    },
    validationSchema: CSFValidationSchema,
    onSubmit: (values) => {
      Swal.fire({
        title: "<strong>Are you sure?</strong>",
        icon: "info",
        html: `
          Amount Deposited: <b>₹ ${values?.totalDonationAmount}</b> <br />
          Date Deposited On: <b>${new Date(values?.paidOn).toLocaleDateString(
            "en-GB",
            { month: "long", day: "numeric", year: "numeric" }
          )}</b> <br />
          Mode Of Payment: <b>${values?.paymentMode}</b> <br />
          Duration: <b>${values?.startMonth}/${values?.startYear} - ${
            values?.endMonth
          }/${values?.endYear}</b> <br />
           Per Month Contribution:₹ <b>${(
             values?.totalDonationAmount /
             totalMonths(
               values?.startMonth,
               values?.startYear,
               values?.endMonth,
               values?.endYear
             )
           ).toFixed(2)}</b> <br />`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: `Yes, Sure!`,
        confirmButtonAriaLabel: "Yes, Sure!",
        cancelButtonText: `No`,
        cancelButtonAriaLabel: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          addDonationApi(values);
        }
      });
    },
  });

  // add donation api start

  const addDonationApi = (values) => {
    let serviceParams = {
      api: API.ADD_DONATION_CSF,
      method: AXIOS_METHOD_TYPES.POST,
      data: {
        totalFundAmount: +values.totalDonationAmount,
        startYear: +values.startYear,
        startMonth: +values.startMonth,
        endYear: +values.endYear,
        endMonth: +values.endMonth,
        familyId: familyId,
        paymentMode: values.paymentMode,
        status: values.paymentMode === "CHEQUE" ? "PENDING" : "PAID",
        collectionDate: values.paidOn,
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          getAllDonationsApi();
          getLastDonatedYearMonthApi();
          setShow(false);
          // getLastDonatedYearMonthApi();
          formik.resetForm();
          Swal.fire("Success", `CSF Added Successfully.`, "success");
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // add donation api start

  useEffect(() => {
    if (show) {
      formik.setFieldValue(
        "startMonth",
        lastMonthDonated
          ? monthArray.find((month) => month.label === lastMonthDonated)
              ?.value + 1
          : 1
      );
      formik.setFieldValue(
        "startYear",
        lastYearDonated ? lastYearDonated : new Date().getFullYear()
      );
    }
  }, [lastMonthDonated, lastYearDonated, show]);

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      backdrop="static"
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Add CSF Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : !addCsfPermission ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <NotAuthenticated />
          </div>
        ) : (
          <Row>
            <Col className="mt-2" sm={12}>
              <TextInput
                label={"Amount"}
                placeholder={"e.g. 5000"}
                setFieldValue={formik.setFieldValue}
                keyword={"totalDonationAmount"}
                value={formik.values.totalDonationAmount}
                error={formik.errors.totalDonationAmount}
                touched={formik.touched.totalDonationAmount}
              />
            </Col>
            <Col className="mt-2" sm={6}>
              <SelectInput
                label={"Starting Month"}
                selectOptions={monthArray}
                setFieldValue={formik.setFieldValue}
                keyword={"startMonth"}
                value={formik.values.startMonth}
                error={formik.errors.startMonth}
                touched={formik.touched.startMonth}
                // disabled={true}
              />
            </Col>
            <Col className="mt-2" sm={6}>
              <SelectInput
                label={"Starting Year"}
                selectOptions={generateArrayOfYears()}
                setFieldValue={formik.setFieldValue}
                keyword={"startYear"}
                value={formik.values.startYear}
                error={formik.errors.startYear}
                touched={formik.touched.startYear}
                // disabled={true}
              />
            </Col>
            <Col className="mt-2" sm={6}>
              <SelectInput
                label={"Ending Month"}
                selectOptions={monthArray}
                setFieldValue={formik.setFieldValue}
                keyword={"endMonth"}
                value={formik.values.endMonth}
                error={formik.errors.endMonth}
                touched={formik.touched.endMonth}
              />
            </Col>
            <Col className="mt-2" sm={6}>
              <SelectInput
                label={"Ending Year"}
                selectOptions={generateArrayOfYears()}
                setFieldValue={formik.setFieldValue}
                keyword={"endYear"}
                value={formik.values.endYear}
                error={formik.errors.endYear}
                touched={formik.touched.endYear}
              />
            </Col>
            <Col className="mt-2" sm={12}>
              <SelectInput
                label={"Mode Of Payment"}
                selectOptions={paymentModeArray}
                setFieldValue={formik.setFieldValue}
                keyword={"paymentMode"}
                value={formik.values.paymentMode}
                error={formik.errors.paymentMode}
                touched={formik.touched.paymentMode}
              />
            </Col>
            <Col className="mt-2" sm={12}>
              <DateInput
                label={"Paying On"}
                setFieldValue={formik.setFieldValue}
                keyword={"paidOn"}
                value={formik.values.paidOn}
                error={formik.errors.paidOn}
                touched={formik.touched.paidOn}
              />
            </Col>
          </Row>
        )}
      </Modal.Body>
      {addCsfPermission && !loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(AddCsfDetailsModal);
