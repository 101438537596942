import React, { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import EditFamilyMemberModal from "../../modals/families/EditFamilyMemberModal";
import whatsappIcon from "../../assets/img/whatsapp.png";

function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

function titleCase(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}

const BasicInformation = ({
  basicDetails,
  contactDetails,
  memberId,
  getFamilyMemberDetails,
  deathDetails,
}) => {
  const [editMemberModalVisible, setEditMemberModalVisible] = useState(false);
  return (
    <Card className="card-post my-4">
      <Card.Body>
        <Row>
          <Col>
            <hr className="hr" />

            <div className="d-flex justify-content-between align-items-center">
              <h3 className="fw-bold text-primary">Basic Information</h3>
              {!deathDetails && (
                <Button onClick={() => setEditMemberModalVisible(true)}>
                  Edit
                </Button>
              )}
            </div>

            <Row>
              <Col sm={4}>
                <div className="my-2">
                  <p className="mb-0">
                    <strong>Full Name</strong>
                  </p>
                  <p className="mb-0">
                    {basicDetails?.firstName} {basicDetails?.middleName}{" "}
                    {basicDetails?.lastName}
                  </p>
                </div>
              </Col>
              {basicDetails?.baptismName && (
                <Col sm={4}>
                  <div className="my-2">
                    <p className="mb-0">
                      <strong>Baptism Name</strong>
                    </p>
                    <p className="mb-0">{basicDetails?.baptismName}</p>
                  </div>
                </Col>
              )}
              <Col sm={4}>
                <div className="my-2">
                  <p className="mb-0">
                    <strong>Date Of Birth</strong>
                  </p>
                  <p className="mb-0">
                    {new Date(basicDetails?.dateOfBirth).toLocaleDateString(
                      "en-GB",
                      { day: "numeric", month: "long", year: "numeric" }
                    )}{" "}
                    <span className="fw-bold text-primary">
                      ({getAge(basicDetails?.dateOfBirth)} Years Old)
                    </span>
                  </p>
                </div>
              </Col>
              <Col sm={4}>
                <div className="my-2">
                  <p className="mb-0">
                    <strong>Gender</strong>
                  </p>
                  <p className="mb-0">{basicDetails?.gender}</p>
                </div>
              </Col>

              <Col sm={4}>
                <div className="my-2">
                  <p className="mb-0">
                    <strong>Father's Name</strong>
                  </p>
                  <p className="mb-0">
                    {basicDetails?.fatherName ? basicDetails?.fatherName : "-"}
                  </p>
                </div>
              </Col>
              <Col sm={4}>
                <div className="my-2">
                  <p className="mb-0">
                    <strong>Mother's Name</strong>
                  </p>
                  <p className="mb-0">
                    {basicDetails?.motherName ? basicDetails?.motherName : "-"}
                  </p>
                </div>
              </Col>

              <Col sm={4}>
                <div className="my-2">
                  <p className="mb-0">
                    <strong>Occupation</strong>
                  </p>
                  <p className="mb-0">
                    {basicDetails?.occupation ? basicDetails?.occupation : "-"}
                  </p>
                </div>
              </Col>
              <Col sm={4}>
                <div className="my-2">
                  <p className="mb-0">
                    <strong>Blood Group</strong>
                  </p>
                  <p className="mb-0">
                    {basicDetails?.bloodGroup}{" "}
                    {basicDetails?.donateBlood && (
                      <span className="text-primary fw-bold">
                        (Wiling to donate)
                      </span>
                    )}
                  </p>
                </div>
              </Col>
              <Col sm={4}>
                <div className="my-2">
                  <p className="mb-0">
                    <strong>Relation To Head</strong>
                  </p>
                  <p className="mb-0">
                    {basicDetails?.familyHead
                      ? "Head Of Family"
                      : basicDetails?.relationToHead &&
                        titleCase(basicDetails?.relationToHead)}
                  </p>
                </div>
              </Col>
              <Col sm={4}>
                <div className="my-2">
                  <p className="mb-0">
                    <strong>Marital Status</strong>
                  </p>
                  <p className="mb-0">
                    {basicDetails?.marriageStatus &&
                      titleCase(basicDetails?.marriageStatus)}
                  </p>
                </div>
              </Col>
              <Col sm={12}>
                <div className="my-2">
                  <p className="mb-0">
                    <strong>Remark</strong>
                  </p>
                  <p className="mb-0">
                    {basicDetails?.remark ? basicDetails?.remark : "-"}
                  </p>
                </div>
              </Col>
              <hr className="hr mt-4" />
              <h4 className="fw-bold text-primary">Contact Information</h4>
              <div className="d-none d-lg-inline">
                <Row>
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Email Address</strong>
                      </p>
                      <p className="mb-0">
                        {contactDetails?.emailAddress
                          ? contactDetails?.emailAddress
                          : "-"}
                      </p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Mobile Number</strong>
                      </p>
                      <p className="mb-0">
                        {contactDetails?.phNumber
                          ? contactDetails?.phNumber
                          : "-"}
                      </p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Whatsapp Number</strong>
                      </p>
                      <p className="mb-0">
                        {contactDetails?.whatsAppNumber
                          ? contactDetails?.whatsAppNumber
                          : "-"}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="d-lg-none pt-0 ">
                <Button disabled={!contactDetails?.phNumber}>
                  <a href={`tel:+91${contactDetails?.phNumber}`}>
                    <i className=" text-white ri-phone-fill"></i>
                  </a>
                </Button>

                <Button
                  className="mx-3"
                  disabled={!contactDetails?.emailAddress}
                >
                  <a href={"mailto:" + contactDetails?.emailAddress}>
                    <i className="text-white ri-mail-fill"></i>
                  </a>
                </Button>

                <Button
                  variant="light"
                  className="p-0"
                  disabled={!contactDetails?.whatsAppNumber}
                >
                  <a
                    href={`whatsapp://send?phone=91${contactDetails?.whatsAppNumber}`}
                  >
                    <img
                      style={{ width: 36, height: 36 }}
                      src={whatsappIcon}
                      alt="whatsapp"
                    />
                  </a>
                </Button>
              </div>
            </Row>
          </Col>
        </Row>
      </Card.Body>
      {
        <Card.Footer className="bg-light text-primary text-end">
          Last Updated by{" "}
          <span className="fw-bold">{basicDetails?.updatedBy}</span> on
          <span className="fw-bold">
            {" "}
            {new Date(basicDetails?.updateTmStmp).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </span>
        </Card.Footer>
      }
      <EditFamilyMemberModal
        setShow={setEditMemberModalVisible}
        show={editMemberModalVisible}
        memberId={memberId}
        basicDetails={basicDetails}
        contactDetails={contactDetails}
        getFamilyMemberDetails={getFamilyMemberDetails}
      />
    </Card>
  );
};

export default BasicInformation;
