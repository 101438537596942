import React, { Component, useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import userAvatar from "../assets/img/img1.jpg";
import {
  dashboardMenu,
  applicationsMenu,
  certificatesMenu,
  settingsMenu,
  financialsMenu,
} from "../data/Menu";
import { AuthContext } from "react-oauth2-code-pkce";
import delhidioceselogo from "../assets/img/Archdiocese_Of_Delhi.png";
import churchLogo from "../assets/img/church.png";

class Sidebar extends Component {
  // toggleFooterMenu = (e) => {
  //   e.preventDefault();

  //   let parent = e.target.closest(".sidebar");
  //   parent.classList.toggle("footer-menu-show");
  // };
  state = {
    churchName: "",
  };

  handleCheckClick = () => {
    this.setState((prevState) => ({
      churchName: localStorage.getItem("churchName"),
    }));
  };

  componentDidMount() {
    setTimeout(() => {
      this.handleCheckClick();
    }, 1000);
  }

  componentWillUnmount() {
    setTimeout(() => {
      this.handleCheckClick();
    }, 1000);
  }

  render() {
    return (
      <div
        style={{ background: "#912E2D", height: "100%" }}
        className="sidebar"
      >
        <div className="sidebar-header">
          {/* <img
            style={{ height: 50, position: "absolute", right: 10, bottom: 10 }}
            src={delhidioceselogo}
          /> */}
          <img style={{ height: 50, marginTop: 24 }} src={churchLogo} />
          <p
            style={{
              fontFamily: "sans-serif",
              color: "#fff",
              lineHeight: "1.2em",
            }}
            className="fw-bold fs-16 mt-2 text-center"
          >
            {this.state.churchName}
            {/* Masihgarh Church Our Lady of Health */}
          </p>
          {/* <Link to="/" className="sidebar-logo">
            One in Trinity
          </Link> */}
        </div>
        <PerfectScrollbar
          className="sidebar-body"
          ref={(ref) => (this._scrollBarRef = ref)}
        >
          <SidebarMenu onUpdateSize={() => this._scrollBarRef.updateScroll()} />
        </PerfectScrollbar>
        {/* <div className="sidebar-footer">
          <div className="sidebar-footer-top">
            <div className="sidebar-footer-thumb">
              <img src={userAvatar} alt="" />
            </div>
            <div className="sidebar-footer-body">
              <h6>
                <Link to="../pages/profile.html">Shaira Diaz</Link>
              </h6>
              <p>Premium Member</p>
            </div>
            <Link
              onClick={this.toggleFooterMenu}
              to=""
              className="dropdown-link"
            >
              <i className="ri-arrow-down-s-line"></i>
            </Link>
          </div>
          <div className="sidebar-footer-menu">
            <nav className="nav">
              <Link to="">
                <i className="ri-edit-2-line"></i> Edit Profile
              </Link>
              <Link to="">
                <i className="ri-profile-line"></i> View Profile
              </Link>
            </nav>
            <hr />
            <nav className="nav">
              <Link to="">
                <i className="ri-question-line"></i> Help Center
              </Link>
              <Link to="">
                <i className="ri-lock-line"></i> Reset Password
              </Link>
              <Link to="">
                <i className="ri-user-settings-line"></i> Account Settings
              </Link>
              <Link to="">
                <i className="ri-logout-box-r-line"></i> Log Out
              </Link>
            </nav>
          </div>
        </div> */}
      </div>
    );
  }
}

const SidebarMenu = () => {
  const { logOut } = useContext(AuthContext);
  const [currentNav, setCurrentNav] = useState("Dashboard");
  const [currentCertNav, setCurrentCertNav] = useState();
  console.log(window.location.pathname);

  function getLabelByLink(link) {
    switch (link) {
      case "/admin/dashboard":
        return setCurrentNav("Dashboard");
      case "/admin/feeds":
        return setCurrentNav("Feeds");
      case "/admin/zones":
        return setCurrentNav("BEC Units");
      case "/admin/families":
        return setCurrentNav("Families");
      case "/admin/search-members":
        return setCurrentNav("Search Members");
      case "/admin/csf":
        return setCurrentNav("CSF's");
      case "/admin/donations":
        return setCurrentNav("Contributions");
      case "/admin/diocese-donation":
        return setCurrentNav("2nd Collection");
      case "/admin/expenses":
        return setCurrentNav("Expenses");
      case "/admin/letters":
        return setCurrentNav("Letters");
      default:
        return setCurrentNav("");
    }
  }

  function getLabelByLinkCert(link) {
    switch (link) {
      case "/admin/baptism-certificates":
        return setCurrentCertNav("Baptism");
      case "/admin/holy-communion-certificates":
        return setCurrentCertNav("Holy Communion");
      case "/admin/holy-confirmation-certificates":
        return setCurrentCertNav("Holy Confirmation");
      case "/admin/marriage-certificates":
        return setCurrentCertNav("Marriage");
      case "/admin/annulment-certificates":
        return setCurrentCertNav("Annulment");
      default:
        return setCurrentCertNav("");
    }
  }

  useEffect(() => {
    getLabelByLink(window.location.pathname);
    getLabelByLinkCert(window.location.pathname);
  }, [window.location.pathname]);

  const populateMenu = (m) => {
    const menu = m.map((m, key) => {
      let sm;
      if (m.submenu) {
        sm = m.submenu.map((sm, key) => {
          return (
            <NavLink
              style={
                currentCertNav === sm.label
                  ? { backgroundColor: "#fff", margin: 10, borderRadius: 5 }
                  : {}
              }
              onClick={() => {
                setCurrentCertNav(sm.label);
                setCurrentNav("");
              }}
              to={sm.link}
              className="nav-sub-link"
              key={key}
            >
              {sm.label}
            </NavLink>
          );
        });
      }

      return (
        <li
          key={key}
          style={
            currentNav === m.label
              ? { backgroundColor: "#fff", margin: 10, borderRadius: 5 }
              : {}
          }
          className="nav-item"
        >
          {!sm ? (
            <NavLink
              replace={m.replace}
              onClick={() => {
                if (m.clickType === "logout") {
                  sessionStorage.clear();
                  localStorage.clear();
                  logOut();
                }
                setCurrentNav(m.label);
                setCurrentCertNav("");
                console.log("dashboard", m.label);
              }}
              to={m.link}
              className="nav-link"
            >
              <i className={m.icon}></i> <span>{m.label}</span>
            </NavLink>
          ) : (
            <div onClick={toggleSubMenu} className="nav-link has-sub">
              <i className={m.icon}></i> <span>{m.label}</span>
            </div>
          )}
          {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
        </li>
      );
    });

    return <ul className="nav nav-sidebar">{menu}</ul>;
  };

  // Toggle menu group
  const toggleMenu = (e) => {
    e.preventDefault();

    let parent = e.target.closest(".nav-group");
    parent.classList.toggle("show");

    this?.props?.onUpdateSize();
  };

  // Toggle submenu while closing siblings' submenu
  const toggleSubMenu = (e) => {
    e.preventDefault();

    let parent = e.target.closest(".nav-item");
    let node = parent.parentNode.firstChild;

    while (node) {
      if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("show");
      node = node.nextElementSibling || node.nextSibling;
    }

    parent.classList.toggle("show");

    this?.props?.onUpdateSize();
  };

  return (
    <React.Fragment>
      <div className="nav-group show">
        <div className="nav-label" onClick={toggleMenu}>
          Dashboard
        </div>
        {populateMenu(dashboardMenu)}
      </div>
      <div className="nav-group show">
        <div className="nav-label" onClick={toggleMenu}>
          Main
        </div>
        {populateMenu(applicationsMenu)}
      </div>
      <div className="nav-group show">
        <div className="nav-label" onClick={toggleMenu}>
          Financials
        </div>
        {populateMenu(financialsMenu)}
      </div>
      <div className="nav-group show">
        <div className="nav-label" onClick={toggleMenu}>
          Certificates and Letters
        </div>
        {populateMenu(certificatesMenu)}
      </div>
      {/* <div className="nav-group show">
        <div className="nav-label" onClick={toggleMenu}>
          Settings
        </div>
        {populateMenu(settingsMenu)}
      </div> */}
    </React.Fragment>
  );
};

window.addEventListener("click", function (e) {
  // Close sidebar footer menu when clicked outside of it
  let tar = e.target;
  let sidebar = document.querySelector(".sidebar");
  if (!tar.closest(".sidebar-footer") && sidebar) {
    sidebar.classList.remove("footer-menu-show");
  }

  // Hide sidebar offset when clicked outside of sidebar
  if (!tar.closest(".sidebar") && !tar.closest(".menu-link")) {
    document.querySelector("body").classList.remove("sidebar-show");
  }
});

window.addEventListener("load", function () {
  // let skinMode = localStorage.getItem("sidebar-skin");
  let skinMode = "prime";

  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-sidebar", skinMode);
  }
});

const mapStateToProps = (state) => ({
  counter: state.counter,
});

export default Sidebar;
