import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Lottie from "lottie-react";
import commingSoonAnimation from "./../assets/lottie/animation_lna9lsvn.json";
import { Link } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import img6 from "../assets/img/img1.jpg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { withAPIRequest } from "../HOC/withAPIRequest";
import { API, AXIOS_METHOD_TYPES } from "../constants/api";
import noSearchAnimation from "./../assets/lottie/animation_lmtf2aaw.json";
import loadingAnimation from "./../assets/lottie/loading_animation_delhi_theme.json";
import AddPriestModal from "../modals/priests/AddPriestModal";
import UpdatePriestImagesModal from "../modals/priests/UpdatePriestImagesModal";
import EditPriestModal from "../modals/priests/EditPriestModal";
import Swal from "sweetalert2";

const MemberValidationSchema = Yup.object().shape({
  name: Yup.string().required("Required").min(3),
});

const DiocesePriestDirectory = ({ commonAPIRequest }) => {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [loading, setLoading] = useState(false);
  const [priests, setPriests] = useState([]);
  const [currentPriestId, setCurrentPriestId] = useState();
  const [showAddPriestModal, setShowAddPriestModal] = useState(false);
  const [showUpdatePriestImageModal, setShowUpdatePriestImageModal] =
    useState(false);
  const [showEditPriestModal, setShowEditPriestModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: MemberValidationSchema,
    onSubmit: (values) => {
      const fullNameArray = values.name.split(/(\s+)/).filter(function (e) {
        return e.trim().length > 0;
      });
      fullNameArray.length === 1
        ? callSearchUsersApi(fullNameArray[0], "")
        : callSearchUsersApi(
            fullNameArray[0],
            fullNameArray[fullNameArray.length - 1]
          );
    },
  });

  const callSearchUsersApi = (firstName, lastName) => {
    let serviceParams = {
      api: `${API.SEARCH_PRIEST_SUMMARY}?firstName=${firstName.trim()}${
        lastName && `&lastName=${lastName.trim()}`
      }&filterBy=All`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setPriests(result?.payload?.searchSuggestion);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  const callGetAllPriests = () => {
    let serviceParams = {
      api: `${API.GET_PRIEST_SUMMARY}?pgNum=1&pgSize=100`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setPriests(result?.payload?.basicPriestDetailList);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    callGetAllPriests();
  }, []);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4 ">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h2 className="main-title mb-1">Priests Directory</h2>
            <p className="text-secondary mb-4">
              A Global Search for all the Priest in Archdiocese of Delhi{" "}
            </p>
          </div>
        </div>
        <Row className="g-5">
          <Col xl>
            <div className="form-search py-2  ">
              <i className="ri-search-line"></i>
              <Form.Control
                onChange={(e) => formik.setFieldValue("name", e.target.value)}
                type="text"
                placeholder="e.g. Joseph George"
              />
              <Button onClick={formik.handleSubmit} variant="primary">
                Search
              </Button>
            </div>
            <div>
              {formik.errors.name && formik.touched.name && (
                <p className="text-danger">*{formik.errors.name}</p>
              )}
            </div>
          </Col>
        </Row>
        <hr className="hr" />
        <div className="justify-content-between align-items-center">
          <div className="main-label-group mb-3">
            <label>Priests Directory</label>
            <div className="d-flex ">
              <Button
                className="mx-3"
                onClick={() => setShowAddPriestModal(true)}
                variant="light"
              >
                Add
              </Button>
              <Button onClick={() => callGetAllPriests(true)} variant="light">
                Reset
              </Button>
            </div>
          </div>
          <div className="row-wrapper">
            <Row className="d-flex">
              {priests.map((priest, index) => (
                <Col sm={6} lg={3} md={4} key={index} className="mb-3">
                  <div className="music-item p-3 rounded-3 shadow">
                    <Link to="" className="music-thumb mb-3">
                      <img
                        style={{ width: "100%" }}
                        src={priest.imageUrl ? priest.imageUrl : img6}
                        alt=""
                        className="img-fluid"
                      />
                    </Link>
                    <h6 className="music-title text-center">
                      <Link to="">
                        Fr. {priest?.firstName} {priest?.lastName}
                      </Link>
                    </h6>
                    {/* <span>+91-{priest.phNumber}</span> */}
                    <Button
                      onClick={() => {
                        setShowUpdatePriestImageModal(true);
                        setCurrentPriestId(priest?.priestDetailId);
                      }}
                      style={{ position: "absolute", top: 30, right: 30 }}
                      variant="light"
                      className="mx-1"
                    >
                      <i className="ri-image-line"></i>
                    </Button>

                    <Button
                      onClick={() => {
                        setShowEditPriestModal(true);
                        setCurrentPriestId(priest?.priestDetailId);
                      }}
                      style={{ position: "absolute", top: 30, right: 80 }}
                      variant="light"
                      className="mx-1"
                    >
                      <i className="ri-edit-line"></i>
                    </Button>

                    <Button
                      onClick={() => {
                        Swal.fire({
                          title: "Are you sure?",
                          text: "You won't be able to revert this!",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#7e7e7e",
                          confirmButtonText: "Yes, delete it!",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            Swal.fire({
                              title: "Deleted!",
                              text: "Your file has been deleted.",
                              icon: "success",
                            });
                          }
                        });
                        // setShowEditPriestModal(true);
                        // setCurrentPriestId(priest?.priestDetailId);
                      }}
                      style={{ position: "absolute", top: 30, right: 130 }}
                      variant="light"
                      className="mx-1"
                    >
                      <i className="ri-delete-bin-6-line"></i>
                    </Button>
                  </div>
                </Col>
              ))}
            </Row>

            {loading && (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="text-center"
                >
                  <Lottie
                    style={{ width: 120, height: 120 }}
                    animationData={loadingAnimation}
                    loop={true}
                  />
                </div>

                <p className="text-center">Searching...</p>
              </div>
            )}

            {!loading && priests.length === 0 && (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="text-center"
                >
                  <Lottie
                    style={{ width: 180, height: 180 }}
                    animationData={noSearchAnimation}
                    loop={true}
                  />
                </div>
                <h3 className="text-center fw-bold">No Priest Found</h3>
                <h5 className="text-center fw-400">
                  Please Try With Another Name.
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>
      <AddPriestModal
        setShow={setShowAddPriestModal}
        show={showAddPriestModal}
        callGetAllPriests={callGetAllPriests}
      />
      <UpdatePriestImagesModal
        show={showUpdatePriestImageModal}
        setShow={setShowUpdatePriestImageModal}
        priestId={currentPriestId}
        callGetAllPriests={callGetAllPriests}
      />
      <EditPriestModal
        setShow={setShowEditPriestModal}
        show={showEditPriestModal}
        priestId={currentPriestId}
        callGetAllPriests={callGetAllPriests}
      />
    </React.Fragment>
  );
};

export default withAPIRequest(DiocesePriestDirectory);
