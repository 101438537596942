import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import * as Yup from "yup";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import Lottie from "lottie-react";
import noSearchAnimation from "../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import ZonePlaceholder from "../../components/placeholders/ZonePlaceholder";
import Avatar from "../../components/Avatar";
import { Link } from "react-router-dom";
import maleImg from "../../assets/img/man_delhi_diocese_theme.png";
import femaleImg from "../../assets/img/women_delhi_diocese_theme.png";
import CustomTooltip from "../../components/CustomTooltip";
import warningimg from "../../assets/img/warning.png";
import Swal from "sweetalert2";
import { AuthContext } from "react-oauth2-code-pkce";
import NotAuthenticated from "../../pages/NotAuthenticated";

function compareStrings(str1, str2) {
  // Convert both strings to lowercase to make the comparison case-insensitive
  const lowerStr1 = str1.toLowerCase();
  const lowerStr2 = str2.toLowerCase();

  // Check for exact match
  if (lowerStr1 === lowerStr2) {
    return "Exact Match";
  }

  // Check for partial match (if one string is contained in the other)
  if (lowerStr1.includes(lowerStr2) || lowerStr2.includes(lowerStr1)) {
    return "Partial Match";
  }

  // If neither exact nor partial match, return no match
  return "No Match";
}

const alphabetsOnly = /^[a-zA-Z. ,]*$/;

const MemberValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed.")
    .min(3),
});

const LinkOutsiderBaptism = ({
  show,
  setShow,
  commonAPIRequest,
  basicDetails,
  memberId,
  getFamilyMemberDetails,
}) => {
  const [loading, setLoading] = useState(false);
  const [baptisms, setBaptisms] = useState([]);
  const [curretStep, setCurretStep] = useState(1);
  const [currentBaptism, setCurrentBaptism] = useState();

  const { tokenData } = useContext(AuthContext);
  const updateBaptismPermission =
    tokenData?.permissions?.includes("UPDATE_BAPTISM");

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: MemberValidationSchema,
    onSubmit: (values) => {
      const fullNameArray = values.name.split(/(\s+)/).filter(function (e) {
        return e.trim().length > 0;
      });
      fullNameArray.length === 1
        ? callSearchBaptismApi(fullNameArray[0], "")
        : callSearchBaptismApi(
            fullNameArray[0],
            fullNameArray[fullNameArray.length - 1]
          );
    },
  });

  const callGetBaptismDetails = (baptismId) => {
    let serviceParams = {
      api: `${API.BAPTISM_DETAILS}?baptismId=${baptismId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          // console.log("result", result.payload);
          setCurrentBaptism(result?.payload);
          setCurretStep(2);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // api to search baptism details by name start

  const callSearchBaptismApi = (firstName, lastName) => {
    let serviceParams = {
      api: `${
        API.SEARCH_BAPTISM
      }?nonMember=${true}&firstName=${firstName.trim()}${
        lastName && `&lastName=${lastName.trim()}`
      }`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          console.log("result", result);
          setBaptisms(result?.payload);
          // dispatch(
          //   BaptismsAction.getBaptisms(result?.payload ? result?.payload : [])
          // );
          // dispatch(BaptismsAction.getTotalCount(0));
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // api to search baptism details by name end

  // function to call API for Update New Baptism start

  const callUpdatedBaptismDetailsApi = (value) => {
    let serviceParams = {
      api: API.EXTERNAL_BAPTISM_API,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {
        baptismDetail: {
          ...currentBaptism,
        },
        memberId: memberId,
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire("Success", `Baptism Linked Successfully.`, "success");
          setShow(false);
          getFamilyMemberDetails();
          formik.resetForm();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Update New Baptism end

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        formik.handleSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-primary fw-bold">
          Link Baptism Certificate
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!updateBaptismPermission ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <NotAuthenticated />
          </div>
        ) : (
          <>
            {curretStep === 1 && (
              <div>
                <Row className="g-5">
                  <Col xl>
                    <div className="form-search py-2  ">
                      <i className="ri-search-line"></i>
                      <Form.Control
                        onChange={(e) =>
                          formik.setFieldValue("name", e.target.value)
                        }
                        type="text"
                        placeholder="e.g. Joseph George"
                        value={formik.values.name}
                      />
                      <Button onClick={formik.handleSubmit} variant="primary">
                        Search
                      </Button>
                    </div>
                    <hr className="hr" />
                    <div className="mb-2">
                      {formik.errors.name && formik.touched.name && (
                        <p className="text-danger">*{formik.errors.name}</p>
                      )}
                    </div>
                  </Col>
                  {/* <p className="m-0 text-primary fw-bold" style={{ fontSize: 12 }}>
            Search the certificate for whom you want to link
          </p> */}
                </Row>

                {/*  */}
                <Row className="g-2 g-xxl-3 mb-5">
                  {loading && <ZonePlaceholder />}

                  <Card className="card-baptism">
                    <Card.Body>
                      <Table hover className="mb-0">
                        <thead>
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Name</th>
                            <th scope="col">Baptism Date</th>
                            <th scope="col">Reg. Ref. Num</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        {/* {loading && <ZoneFamilyTablePlaceholder />} */}
                        {!loading && (
                          <tbody>
                            {baptisms?.map((baptism, i) => (
                              <tr key={baptism?.baptismId}>
                                <th scope="row">{i + 1}</th>
                                <td className="fw-bold">
                                  {baptism.memberId ? (
                                    <Link
                                      to={"/admin/member-details"}
                                      state={{
                                        memberId: baptism.memberId,
                                        back: true,
                                      }}
                                    >
                                      {baptism.middleName
                                        ? `${baptism.firstName} ${baptism.middleName} ${baptism.lastName}`
                                        : `${baptism.firstName} ${baptism.lastName}`}
                                    </Link>
                                  ) : baptism.middleName ? (
                                    `${baptism.firstName} ${baptism.middleName} ${baptism.lastName}`
                                  ) : (
                                    `${baptism.firstName} ${baptism.lastName}`
                                  )}
                                  {!baptism?.memberId && (
                                    <Badge className="ms-2" bg="secondary" pill>
                                      Non Member
                                    </Badge>
                                  )}
                                </td>
                                <td
                                  style={{ height: 55 }}
                                  className="d-flex align-items-center justify-content-start"
                                >
                                  <span style={{ width: 90 }}>
                                    {new Date(
                                      baptism?.baptismDate
                                    ).toLocaleDateString("en-GB", {
                                      day: "numeric",
                                      month: "short",
                                      year: "numeric",
                                    })}
                                  </span>

                                  {!baptism?.baptismDateAccurate && (
                                    <CustomTooltip
                                      label={"Might be Incorrect"}
                                      placement={"top"}
                                    >
                                      <Badge
                                        style={{ padding: 5 }}
                                        className="ms-1 fw-bold"
                                        bg="light"
                                      >
                                        <img
                                          height={18}
                                          src={warningimg}
                                          alt="warning"
                                        />
                                      </Badge>
                                    </CustomTooltip>
                                  )}
                                </td>
                                <td>{baptism?.referenceNum}</td>
                                <td>
                                  <Button
                                    onClick={() => {
                                      callGetBaptismDetails(baptism?.baptismId);
                                    }}
                                    disabled={baptism?.memberId}
                                  >
                                    <i className="ri-links-line"></i> Link
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </Table>
                    </Card.Body>
                  </Card>

                  {!loading && baptisms.length === 0 && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="text-center mt-5"
                      >
                        <Lottie
                          style={{ width: 180, height: 180 }}
                          animationData={noSearchAnimation}
                          loop={true}
                        />
                      </div>
                      <h3 className="text-center fw-bold">
                        No Certificate Found
                      </h3>
                      <h5 className="text-center fw-400">
                        Please Try With Another Name.
                      </h5>
                    </div>
                  )}
                </Row>
              </div>
            )}

            {curretStep === 2 && (
              <div>
                <Row>
                  <Col style={{ borderRight: "1px solid #000" }} sm={4}>
                    <h4 className="text-center text-primary fw-bold">
                      Personal Info.
                    </h4>

                    <div className="my-2 ">
                      <p className="mb-0 text-center">
                        <strong>Full Name</strong>
                      </p>
                      <p
                        style={{ fontSize: 18, fontWeight: "lighter" }}
                        className="mb-0 text-center"
                      >
                        {basicDetails?.firstName}
                      </p>
                    </div>

                    <div className="my-2 ">
                      <p className="mb-0 text-center">
                        <strong>D.O.B</strong>
                      </p>
                      <p
                        style={{ fontSize: 18, fontWeight: "lighter" }}
                        className="mb-0 text-center"
                      >
                        {new Date(basicDetails?.dateOfBirth).toLocaleDateString(
                          "en-GB",
                          { day: "numeric", month: "short", year: "numeric" }
                        )}
                      </p>
                    </div>

                    <div className="my-2 ">
                      <p className="mb-0 text-center">
                        <strong>Father Name</strong>
                      </p>
                      <p
                        style={{ fontSize: 18, fontWeight: "lighter" }}
                        className="mb-0 text-center"
                      >
                        {basicDetails?.fatherName}
                      </p>
                    </div>

                    <div className="my-2 ">
                      <p className="mb-0 text-center">
                        <strong>Mother Name</strong>
                      </p>
                      <p
                        style={{ fontSize: 18, fontWeight: "lighter" }}
                        className="mb-0 text-center"
                      >
                        {basicDetails?.motherName}
                      </p>
                    </div>

                    <div className="my-2 ">
                      <p className="mb-0 text-center">
                        <strong>Gender</strong>
                      </p>
                      <p
                        style={{ fontSize: 18, fontWeight: "lighter" }}
                        className="mb-0 text-center"
                      >
                        {basicDetails?.gender}
                      </p>
                    </div>
                  </Col>
                  <Col style={{ borderRight: "1px solid #000" }} sm={4}>
                    <h4 className="text-center text-primary fw-bold">
                      Baptism Info.
                    </h4>
                    <div className="my-2 ">
                      <p className="mb-0 text-center">
                        <strong>Full Name</strong>
                      </p>
                      <p
                        style={{ fontSize: 18, fontWeight: "lighter" }}
                        className="mb-0 text-center"
                      >
                        {currentBaptism?.firstName}
                      </p>
                    </div>
                    {/*  */}
                    <div className="my-2 ">
                      <p className="mb-0 text-center">
                        <strong>D.O.B</strong>
                      </p>
                      <p
                        style={{ fontSize: 18, fontWeight: "lighter" }}
                        className="mb-0 text-center"
                      >
                        {new Date(currentBaptism?.dob).toLocaleDateString(
                          "en-GB",
                          {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          }
                        )}
                      </p>
                    </div>
                    {/*  */}
                    <div className="my-2 ">
                      <p className="mb-0 text-center">
                        <strong>Father Name</strong>
                      </p>
                      <p
                        style={{ fontSize: 18, fontWeight: "lighter" }}
                        className="mb-0 text-center"
                      >
                        {currentBaptism?.fatherName}
                      </p>
                    </div>
                    {/*  */}
                    <div className="my-2 ">
                      <p className="mb-0 text-center">
                        <strong>Mother Name</strong>
                      </p>
                      <p
                        style={{ fontSize: 18, fontWeight: "lighter" }}
                        className="mb-0 text-center"
                      >
                        {currentBaptism?.motherName}
                      </p>
                    </div>
                    {/*  */}
                    <div className="my-2 ">
                      <p className="mb-0 text-center">
                        <strong>Gender</strong>
                      </p>
                      <p
                        style={{ fontSize: 18, fontWeight: "lighter" }}
                        className="mb-0 text-center"
                      >
                        {currentBaptism?.gender}
                      </p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <h4 className="text-center text-primary fw-bold">Status</h4>
                    <div className="my-2 ">
                      <p className="mb-0 text-center">
                        <strong>Match</strong>
                      </p>
                      <p className="text-center m-0" style={{ fontSize: 18 }}>
                        <Badge
                          className="text-white p-1 px-3 align-self-center fw-bold"
                          bg={
                            compareStrings(
                              basicDetails?.firstName,
                              currentBaptism?.firstName
                            ) === "Exact Match"
                              ? "success"
                              : compareStrings(
                                  basicDetails?.firstName,
                                  currentBaptism?.firstName
                                ) === "Partial Match"
                              ? "warning"
                              : "danger"
                          }
                          pill
                        >
                          <span
                            style={{ fontSize: 14 }}
                            className="mb-0 fw-bold text-center"
                          >
                            <i className="ri-close-line"></i>
                            {compareStrings(
                              basicDetails?.firstName,
                              currentBaptism?.firstName
                            )}
                          </span>
                        </Badge>
                      </p>
                    </div>

                    {/*  */}

                    <div className="my-2 ">
                      <p className="mb-0 text-center">
                        <strong>Match</strong>
                      </p>
                      <p className="text-center m-0" style={{ fontSize: 18 }}>
                        <Badge
                          className="text-white p-1 px-3 align-self-center fw-bold"
                          bg={
                            compareStrings(
                              basicDetails?.dateOfBirth,
                              currentBaptism?.dob
                            ) === "Exact Match"
                              ? "success"
                              : compareStrings(
                                  basicDetails?.dateOfBirth,
                                  currentBaptism?.dob
                                ) === "Partial Match"
                              ? "warning"
                              : "danger"
                          }
                          pill
                        >
                          <span
                            style={{ fontSize: 14 }}
                            className="mb-0 fw-bold text-center"
                          >
                            <i
                              className={
                                compareStrings(
                                  basicDetails?.dateOfBirth,
                                  currentBaptism?.dob
                                ) === "Exact Match"
                                  ? "ri-check-line"
                                  : "ri-close-line"
                              }
                            ></i>
                            {compareStrings(
                              basicDetails?.dateOfBirth,
                              currentBaptism?.dob
                            )}
                          </span>
                        </Badge>
                      </p>
                    </div>

                    {/*  */}

                    <div className="my-2 ">
                      <p className="mb-0 text-center">
                        <strong>Match</strong>
                      </p>
                      <p className="text-center m-0" style={{ fontSize: 18 }}>
                        <Badge
                          className="text-white p-1 px-3 align-self-center fw-bold"
                          bg={
                            compareStrings(
                              basicDetails?.fatherName,
                              currentBaptism?.fatherName
                            ) === "Exact Match"
                              ? "success"
                              : compareStrings(
                                  basicDetails?.fatherName,
                                  currentBaptism?.fatherName
                                ) === "Partial Match"
                              ? "warning"
                              : "danger"
                          }
                          pill
                        >
                          <span
                            style={{ fontSize: 14 }}
                            className="mb-0 fw-bold text-center"
                          >
                            <i
                              className={
                                compareStrings(
                                  basicDetails?.fatherName,
                                  currentBaptism?.fatherName
                                ) === "Exact Match"
                                  ? "ri-check-line"
                                  : "ri-close-line"
                              }
                            ></i>
                            {compareStrings(
                              basicDetails?.fatherName,
                              currentBaptism?.fatherName
                            )}
                          </span>
                        </Badge>
                      </p>
                    </div>

                    {/*  */}

                    <div className="my-2 ">
                      <p className="mb-0 text-center">
                        <strong>Match</strong>
                      </p>
                      <p className="text-center m-0" style={{ fontSize: 18 }}>
                        <Badge
                          className="text-white p-1 px-3 align-self-center fw-bold"
                          bg={
                            compareStrings(
                              basicDetails?.motherName,
                              currentBaptism?.motherName
                            ) === "Exact Match"
                              ? "success"
                              : compareStrings(
                                  basicDetails?.motherName,
                                  currentBaptism?.motherName
                                ) === "Partial Match"
                              ? "warning"
                              : "danger"
                          }
                          pill
                        >
                          <span
                            style={{ fontSize: 14 }}
                            className="mb-0 fw-bold text-center"
                          >
                            <i
                              className={
                                compareStrings(
                                  basicDetails?.motherName,
                                  currentBaptism?.motherName
                                ) === "Exact Match"
                                  ? "ri-check-line"
                                  : "ri-close-line"
                              }
                            ></i>
                            {compareStrings(
                              basicDetails?.motherName,
                              currentBaptism?.motherName
                            )}
                          </span>
                        </Badge>
                      </p>
                    </div>

                    {/*  */}

                    <div className="my-2 ">
                      <p className="mb-0 text-center">
                        <strong>Match</strong>
                      </p>
                      <p className="text-center m-0" style={{ fontSize: 18 }}>
                        <Badge
                          className="text-white p-1 px-3 align-self-center fw-bold"
                          bg={
                            compareStrings(
                              basicDetails?.gender,
                              currentBaptism?.gender
                            ) === "Exact Match"
                              ? "success"
                              : compareStrings(
                                  basicDetails?.gender,
                                  currentBaptism?.gender
                                ) === "Partial Match"
                              ? "warning"
                              : "danger"
                          }
                          pill
                        >
                          <span
                            style={{ fontSize: 14 }}
                            className="mb-0 fw-bold text-center"
                          >
                            <i
                              className={
                                compareStrings(
                                  basicDetails?.gender,
                                  currentBaptism?.gender
                                ) === "Exact Match"
                                  ? "ri-check-line"
                                  : "ri-close-line"
                              }
                            ></i>
                            {compareStrings(
                              basicDetails?.gender,
                              currentBaptism?.gender
                            )}
                          </span>
                        </Badge>
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </>
        )}
        {/*  */}
      </Modal.Body>
      {curretStep === 2 && (
        <Modal.Footer>
          <Button
            onClick={() => {
              setCurrentBaptism(null);
              setCurretStep(1);
            }}
          >
            Back
          </Button>
          <Button
            onClick={() => {
              if (
                compareStrings(basicDetails?.gender, currentBaptism?.gender) ===
                  "Exact Match" &&
                compareStrings(
                  basicDetails?.dateOfBirth,
                  currentBaptism?.dob
                ) === "Exact Match"
              ) {
                callUpdatedBaptismDetailsApi();
              } else {
                Swal.fire(
                  "Warning",
                  "D.O.B and Gender should be exact match to link the certificate",
                  "warning"
                );
              }
            }}
          >
            Link
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(LinkOutsiderBaptism);
