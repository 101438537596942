import * as Yup from "yup";

const alphabetsOnly = /^[-a-zA-Z][-a-zA-Z\.\'\(\) ]*$/;

const dateFormatter = (date) => {
  return new Date(date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

export const OutsidersBaptismValidationStep1Schema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  // lastName: Yup.string().required("Required"),
  dob: Yup.date()
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  dateOfBaptism: Yup.date()
    .min(Yup.ref("dob"), "Baptism date can't be on or before Date Of Birth")
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  fatherName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  motherName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  gender: Yup.string().required("Required"),
});

export const BaptismValidationStep1Schema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  // lastName: Yup.string().required("Required"),
  dobForValidation: Yup.date()
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  dateOfBaptism: Yup.date()
    .min(
      Yup.ref("dobForValidation"),
      "Baptism date can't be on or before Date Of Birth"
    )
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  fatherName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  motherName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  gender: Yup.string().required("Required"),
});

export const BaptismValidationStep2Schema = Yup.object().shape({
  fatherNationality: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  fatherDomicile: Yup.string().required("Required"),
  // .matches(alphabetsOnly, "Only alphabets are allowed."),
  godFatherFirstName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  godFatherLastName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  godFatherDomicile: Yup.string().required("Required"),
  // .matches(alphabetsOnly, "Only alphabets are allowed."),
  godMotherFirstName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  godMotherLastName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  godMotherDomicile: Yup.string().required("Required"),
  // .matches(alphabetsOnly, "Only alphabets are allowed."),
});

export const BaptismValidationStep3Schema = Yup.object().shape({
  baptismPlace: Yup.string().required("Required"),
  ministerName: Yup.string().required("Required"),
});

export const ExtBaptismValidationSchema = Yup.object().shape({
  dobForValidation: Yup.date()
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  baptismDate: Yup.date()
    .min(
      Yup.ref("dobForValidation"),
      "Baptism date can't be before Date Of Birth"
    )
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  // baptismChurchName: Yup.string().required("Required"),
  // baptismChurchCity: Yup.string().required("Required"),
});

// export const BaptismValidationStep4Schema = Yup.object().shape({
//   dobForValidation: Yup.date()
//     .max(
//       new Date(),
//       `Date needs to be on or before ${dateFormatter(new Date())}`
//     ),

// });

export const EditOutsidersBaptismValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  // lastName: Yup.string().required("Required"),
  dob: Yup.date()
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  dateOfBaptism: Yup.date()
    .min(Yup.ref("dob"), "Baptism date can't be on or before Date Of Birth")
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  gender: Yup.string().required("Required"),
  fatherName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  motherName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  dateOfholyCommunion: Yup.date()
    .min(Yup.ref("dateOfBaptism"), "Can't be on or before Date Of Baptism")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    )
    .notRequired(),
  dateOfholyConfirmation: Yup.date()
    .min(
      Yup.ref("dateOfholyCommunion"),
      "Can't be on or before Date Of Holy Communion"
    )
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    )
    .notRequired(),
  dateOfMarriage: Yup.date()
    .min(
      Yup.ref("dateOfholyConfirmation"),
      "Can't be on or before Date Of Holy Confirmation"
    )
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    )
    .notRequired(),
});
