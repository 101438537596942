import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Form, Placeholder, Row } from "react-bootstrap";
import Header from "../layouts/Header";
import { API, AXIOS_METHOD_TYPES } from "../constants/api";
import { withAPIRequest } from "../HOC/withAPIRequest";
import { useDispatch, useSelector } from "react-redux";
import { ZonesAction } from "../store/slices/ZonesSlice";
import AddZoneModal from "../modals/zones/AddZoneModal";
import ViewSubZonesModal from "../modals/zones/ViewSubZonesModal";
import EditZoneModal from "../modals/zones/EditZoneModal";
import CustomTooltip from "../components/CustomTooltip";
import Lottie from "lottie-react";
import noSearchAnimation from "../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import { useNavigate } from "react-router-dom";
import loadingAnimation from "../assets/lottie/loading_animation_delhi_theme.json";
import { AuthContext } from "react-oauth2-code-pkce";
import NotAuthenticated from "../pages/NotAuthenticated";

const Zones = ({ commonAPIRequest }) => {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [currentZones, setCurrentZones] = useState([]);

  const { zones, present } = useSelector((state) => state.zones);
  const [addZoneModalVisible, setAddZoneModalVisible] = useState(false);
  const [viewSubZoneModalVisible, setViewSubZoneModalVisible] = useState(false);
  const [currentZoneSelected, setCurrentZoneSelected] = useState();
  const [editZoneModalVisible, setEditZoneModalVisible] = useState(false);
  const { churchDetails } = useSelector((state) => state?.church);

  const { tokenData } = useContext(AuthContext);
  const readZonePermission = tokenData?.permissions?.includes("READ_ZONE");

  const callGetAllZones = () => {
    let serviceParams = {
      api: API.GET_ALL_ZONES,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          dispatch(
            ZonesAction.getZones(
              result?.payload.map((zone) => {
                return {
                  label: zone.zoneName,
                  value: zone.zoneId,
                  zoneDetails: zone,
                };
              })
            )
          );
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (!present && readZonePermission) {
      callGetAllZones();
    }
  }, []);

  useEffect(() => {
    if (zones) {
      setCurrentZones(zones);
    }
  }, [zones]);

  useEffect(() => {
    if (searchText) {
      setCurrentZones(
        zones.filter((zone) =>
          zone.label.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else {
      setCurrentZones(zones);
    }
  }, [searchText]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        {!readZonePermission ? (
          <div className="justify-content-center align-items-center d-flex">
            <NotAuthenticated />
          </div>
        ) : (
          <>
            <div
              style={{ borderRadius: 10, backgroundColor: "#e7e7e7" }}
              className="d-flex mx-4 my-2 p-2 align-items-center justify-content-between"
            >
              <marquee
                className="fw-bold mt-1 me-3"
                behavior="scroll"
                direction="left"
                scrollamount="5"
              >
                For God so loved the world that he gave his one and only Son,
                that whoever believes in him shall not perish but have eternal
                life. <span className="fw-light">- John [3:16]</span>
              </marquee>

              <Button
                className="ms-3"
                onClick={() => callGetAllZones()}
                variant="light"
                style={{ height: 40 }}
              >
                <i className="ri-refresh-line"></i>
              </Button>
            </div>

            <Card className="card-zone">
              <Card.Body>
                <Row className="d-flex mb-2 align-items-center">
                  <Col className="form-search py-2 ">
                    <i className="ri-search-line"></i>
                    <Form.Control
                      onChange={(e) => setSearchText(e.target.value)}
                      type="text"
                      placeholder="e.g. St. James"
                    />
                    {/* <Button onClick={() => {}} variant="primary">
                  Search
                </Button> */}
                  </Col>
                  {/* <Col
                className="d-flex align-items-center justify-content-end"
                lg={"3"}
              ></Col> */}
                </Row>
                <hr className="hr" />
                <div
                  style={{ borderRadius: 10, backgroundColor: "#eee" }}
                  className="d-flex px-4 py-2 justify-content-between align-items-center my-3 "
                >
                  <div>
                    <h5
                      style={{ width: "75%" }}
                      className="m-0 d-none d-lg-inline"
                    >
                      List of all the {zones?.length} BEC Units of{" "}
                      <span className="fw-bold text-primary text-decoration-underline">
                        {churchDetails?.churchName}
                      </span>
                      .
                    </h5>

                    <h6 style={{ width: "75%" }} className="m-0 d-lg-none pt-0">
                      List of all the {zones?.length} BEC Units of{" "}
                      <span className="fw-bold text-primary text-decoration-underline">
                        {churchDetails?.churchName}
                      </span>
                      .
                    </h6>
                  </div>

                  <Button
                    onClick={() => setAddZoneModalVisible(true)}
                    variant="dark"
                    style={{ height: 40, width: "54%" }}
                  >
                    Add Zone
                  </Button>
                </div>

                {/* <hr className="hr" /> */}

                {loading && (
                  <div
                    className=" d-flex align-items-center justify-content-center "
                    style={{ height: "90vh" }}
                  >
                    <Lottie
                      style={{ width: 100, height: 100 }}
                      animationData={loadingAnimation}
                      loop={true}
                    />
                    loading zones...
                  </div>
                )}

                <Row className="g-3">
                  {currentZones.length !== 0
                    ? currentZones.map((zone) => (
                        <Col key={zone?.zoneDetails?.zoneId} md="4">
                          <Card className="card-light">
                            <Card.Body>
                              <div className="d-flex mb-2 justify-content-between align-items-center">
                                <Card.Title
                                  style={{ cursor: "pointer", width: "60%" }}
                                  onClick={() => {
                                    setViewSubZoneModalVisible(true);
                                    setCurrentZoneSelected(zone);
                                  }}
                                  className="fw-bold d-flex align-items-center"
                                >
                                  <span>{zone.label}</span>
                                  <i className="ri-information-line ms-2"></i>
                                </Card.Title>

                                <div>
                                  <CustomTooltip
                                    label={"Edit Zone"}
                                    placement={"top"}
                                  >
                                    <Button
                                      className="mx-1"
                                      onClick={() => {
                                        setEditZoneModalVisible(true);
                                        setCurrentZoneSelected(zone);
                                      }}
                                    >
                                      <i className="ri-edit-box-line"></i>
                                    </Button>
                                  </CustomTooltip>
                                  <CustomTooltip
                                    label={"View Families"}
                                    placement={"top"}
                                  >
                                    <Button
                                      className="mx-1"
                                      onClick={() => {
                                        navigate("/admin/zones-families", {
                                          state: {
                                            zoneId: zone.value,
                                            zoneName: zone.label,
                                          },
                                        });
                                      }}
                                    >
                                      <i className="ri-group-fill"></i>
                                    </Button>
                                  </CustomTooltip>
                                </div>
                              </div>
                              <hr className="hr" />

                              <Card.Text>
                                Incharge :
                                {zone?.zoneDetails?.zoneRepName ? (
                                  <span>
                                    <span className="fw-bold">
                                      {" "}
                                      {zone?.zoneDetails?.zoneRepName}{" "}
                                    </span>
                                    (
                                    <a
                                      className="fw-bold"
                                      href={`tel:+91${zone?.zoneDetails?.zoneRepPhNum}`}
                                    >
                                      {zone?.zoneDetails?.zoneRepPhNum}
                                    </a>
                                    )
                                  </span>
                                ) : (
                                  <span className="fw-bold">
                                    {" "}
                                    Not Available
                                  </span>
                                )}
                                <br />
                                Secretary :{" "}
                                {zone?.zoneDetails?.zoneSecyName ? (
                                  <span>
                                    <span className="fw-bold">
                                      {" "}
                                      {zone?.zoneDetails?.zoneSecyName}{" "}
                                    </span>
                                    (
                                    <a
                                      className="fw-bold"
                                      href={`tel:+91${zone?.zoneDetails?.zoneSecyPhNum}`}
                                    >
                                      {zone?.zoneDetails?.zoneSecyPhNum}
                                    </a>
                                    )
                                  </span>
                                ) : (
                                  <span className="fw-bold">
                                    {" "}
                                    Not Available
                                  </span>
                                )}
                                <br />
                                Coordinator :
                                {zone?.zoneDetails?.zoneCoordName ? (
                                  <span>
                                    <span className="fw-bold">
                                      {" "}
                                      {zone?.zoneDetails?.zoneCoordName}{" "}
                                    </span>
                                    (
                                    <a
                                      className="fw-bold"
                                      href={`tel:+91${zone?.zoneDetails?.zoneCoordPhNum}`}
                                    >
                                      {zone?.zoneDetails?.zoneCoordPhNum}
                                    </a>
                                    )
                                  </span>
                                ) : (
                                  <span className="fw-bold">
                                    {" "}
                                    Not Available
                                  </span>
                                )}
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </Col>
                      ))
                    : !loading && (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            className="text-center"
                          >
                            <Lottie
                              style={{ width: 180, height: 180 }}
                              animationData={noSearchAnimation}
                              loop={true}
                            />
                          </div>
                          <h3 className="text-center fw-bold">
                            No Zones Found
                          </h3>
                        </div>
                      )}
                </Row>
              </Card.Body>
            </Card>
          </>
        )}
      </div>
      <AddZoneModal
        show={addZoneModalVisible}
        setShow={setAddZoneModalVisible}
        callGetAllZones={callGetAllZones}
      />
      <EditZoneModal
        setShow={setEditZoneModalVisible}
        show={editZoneModalVisible}
        zoneDetails={currentZoneSelected?.zoneDetails}
        callGetAllZones={callGetAllZones}
      />
      <ViewSubZonesModal
        show={viewSubZoneModalVisible}
        setShow={setViewSubZoneModalVisible}
        zoneDetails={currentZoneSelected}
      />
    </React.Fragment>
  );
};

export default withAPIRequest(Zones);
